import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { DatePicker, InputNumber } from 'antd'
import CustomButton from '../../../../common/CustomButton'


const CustomBookingGroupForm = ({group, maxGroupSize, onChange}) => {
	let { adults, kids } = group

	const onChangeHandler = (key, value, action) => {
		if ((adults + kids >= maxGroupSize) && action === 'INCREASE') {
			return;
		} 

		if (key === "adults" && value + kids > maxGroupSize) {
			onChange(key, maxGroupSize - kids)
			return;
		}

		if (key === "kids" && value + adults > maxGroupSize) {
			onChange(key, maxGroupSize - adults)
			return;
		}

		onChange(key, value)		
	}

	return (
		<div className="custom-booking-group-form-component">
			<Container>
				<Row>
					<Col>
						<p><b>Maximum number of People : </b>{maxGroupSize}</p>
						<p>Adults</p>

						<div className='instant-booking-group-input-container'>
							<CustomButton
								text="-"
								onClick={() => onChangeHandler("adults", adults ? adults - 1 : 0, 'REDUCE')}
							/>  
							<InputNumber 
								placeholder="Adults" 
								value={adults}
								maxLength={4}
								min={0} 
								onChange={(val) => onChangeHandler("adults", val)}
							/>
							<CustomButton
								text="+"
								onClick={() => onChangeHandler("adults", adults ? adults + 1 : 1, 'INCREASE')}
							/>  
						</div>
						<p>Kids</p>
						<div className='instant-booking-group-input-container'>
							<CustomButton
								text="-"
								onClick={() => onChangeHandler("kids", kids ? kids - 1 : 0, 'REDUCE')}
							/>  
								<InputNumber
									placeholder="Kids" 
									value={kids}
									maxLength={4}
									min={0} 
									onChange={(val) => onChangeHandler("kids", val)}
								/>
								<CustomButton
									text="+"
									onClick={() => onChangeHandler("kids", kids ? kids + 1 : 1, 'INCREASE')}
								/>  
							</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default CustomBookingGroupForm;
