import React from 'react'
import './style.sass'
import NavBar from '../../common/NavBar'
import PrivacyPolicyBar from '../../common/PrivacyPolicyBar'

const MainLayout = ({ children }) => {
  
  return (
    <div>
      <NavBar />
      <div>
        {children} 
      </div>
      <PrivacyPolicyBar />
    </div>
  )
}

export default MainLayout