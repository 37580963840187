import React, { Component } from 'react'
import Fade from 'react-reveal/Fade';
import './style.sass'

class HomeHeader extends Component {
    render() {
        return (
            <Fade delay={0} duration={1500}>
                <div className="home-header-component">
                    <div className="header">
                        <div className="text-block">
                            <h1>Book <span className="blue-text">Boating Experiences</span> for</h1>
                            <h1>any Budget & Location!</h1>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }
}

export default HomeHeader;
