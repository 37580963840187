import React from 'react'
import './style.sass'
import placeholder from '../../../assets/images/placeholder.png'
import { Button } from 'antd';
import { PUBLIC_S3_BUCKET_URL } from '../../../constants/AwsConstants'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

const ProfileBoatCard = ({ boat, onViewClicked, onEditClicked }) => {
  const { images, name, brand, model, yearOfManufacture, category, maxCapacity, verified } = boat
  return ( 
    <div className="profile-cards-horizontal-component">
      <img src={(images && images.length > 0) ? PUBLIC_S3_BUCKET_URL + images[0] : placeholder} alt="boat"/>
      <div className="right-container">
        <div className="content-container"> 
          <h3>{name.slice(0, 80).trim()}{name.length < 80 || '...'}</h3>      
          <h4>{brand} - {model} | {category}</h4>
          <h5><b>Year:</b> {yearOfManufacture} |  <b>Max Capacity:</b> {maxCapacity} | {verified ? <span className="boat-verified"><CheckCircleOutlined /> Verified</span> : <span className="boat-not-verified"><CloseCircleOutlined /> Not Verified</span>}</h5>      
        </div>
        <div className="button-container"> 
          <Button type="primary" onClick={onViewClicked} style={{ marginBottom: 10, marginRight: 5 }}>
            VIEW
          </Button>
          <Button onClick={onEditClicked} style={{ marginRight: 5 }}>
            EDIT
          </Button> 
        </div>
      </div>
    </div>
  );
}

export default ProfileBoatCard