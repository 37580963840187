import React from 'react'
import moment from 'moment'
import { Slider, Button, Popover, DatePicker } from 'antd'
import { PayCircleOutlined, FieldTimeOutlined, TeamOutlined, CalendarOutlined } from '@ant-design/icons'

const SearchFilters = ({filterValues, onChange}) => {
  const { duration, price, groupSize, depatureDate } = filterValues

	return (
		<div>
			{ /* DURATION FILTER */}
			<Popover 
				content={<Filter valueKey="duration" value={duration} unit={duration === 1 ? "Hour" : "Hours"} onChange={onChange} initalValue={"100+"} max={100}/>} 
				title="Duration Filter" 
				trigger="click"
			>
				<Button type={!duration || 'primary'} icon={<FieldTimeOutlined />} className="filterBtn">DURATION</Button>
			</Popover>
			{ /* PRICE FILTER */}
			<Popover 
				content={<Filter valueKey="price"  value={price} unit={price === 1 ? "Dollor" : "Dollors"} onChange={onChange} initalValue={"2000+"} max={2000}/>} 
				title="Price Filter" 
				trigger="click"
			>
				<Button type={!price || 'primary'} icon={<PayCircleOutlined/>} className="filterBtn">PRICE</Button>
			</Popover>
			{ /* GROUP SIZE FILTER */}
			<Popover 
				content={<Filter valueKey="groupSize" value={groupSize} unit={groupSize === 1 ? "Person" : "People"} onChange={onChange} initalValue={"100+"} max={100}/>} 
				title="Group Size Filter" 
				trigger="click"
			>
				<Button type={!groupSize || 'primary'} icon={<TeamOutlined />} className="filterBtn">GROUP SIZE</Button>
			</Popover>

			{ /* DATE FILTER */}
			<Popover 
				content={<DateFilter valueKey="depatureDate" value={depatureDate} onChange={onChange}/>} 
				title="Date Filter" 
				trigger="click"
			>
				<Button type={!depatureDate || 'primary'} icon={<CalendarOutlined />} className="filterBtn">DATE</Button>
			</Popover>
			
		</div>
	)
}

const Filter = ({ valueKey, value, unit, onChange, initalValue, max }) => (
	<div>
		<p>Up to <b>{value ? value : initalValue}</b> {unit}</p>
		<Slider
			min={1}
			max={max}
			onChange={(val) => onChange(valueKey, val)}
			value={value ? value : max}
		/>
		{!value || (
			<Button 
				style={{ width: '100%', marginTop: 10 }}
				type='primary'
				onClick={() => onChange(valueKey, null)}
			>
				Clear
			</Button>
		)}
	</div>
);


const DateFilter = ({ valueKey, value, onChange }) => (
	<div>
		<p style={{ width: '100%', marginTop: 3 }}>Select departure date to filter results</p>
		<DatePicker
			placeholder="Departure Date" 
			onChange={(date) => onChange(valueKey, date)}
			value={value}
			showToday={false}
			disabledDate={disabledDate}
			style={{ width: '100%' }}
		/>
		{!value || (
			<Button 
				style={{ width: '100%', marginTop: 10 }}
				type='primary'
				onClick={() => onChange(valueKey, null)}
			>
				Clear
			</Button>
		)}
	</div>
);

const disabledDate = (current) => {
	if (current.isBefore(moment()) || current.isSame(moment())) {
		return true;
	}
	return false;
}

export default SearchFilters;