import ApiBuilder from './ApiBuilder';

/**
 *
 * POST : addSharingBooking
 *
 */
const addSharingBooking = (payload) => {
  return ApiBuilder.API.post('/sharing/add', {
    ...payload,
  });
};

/**
 *
 * GET : getSharingRequestById
 *
 */
const getSharingRequestById = (sharingId) => {
  return ApiBuilder.API.get(`/sharing/one/${sharingId}`);
};

/**
 *
 * GET : getCreatedSharingsOfOwner
 *
 */
const getCreatedSharingsOfOwner = () => {
  return ApiBuilder.API.get('/sharing/owner/created');
};

/**
 *
 * POST : shareWithFriendViaSMS
 *
 */
const shareWithFriendViaSMS = (phoneNumber, sharingId) => {
  return ApiBuilder.API.post('/sharing/share/sms', {
    phoneNumber,
    sharingId
  });
};

/**
 *
 * GET : getSharingRequestsSearchResult
 *
 */
const getSharingRequestsSearchResult = (address, lat, lng) => {
  return ApiBuilder.API.get(`/sharing/search/${address}/${lat}/${lng}`);
};

/**
 *
 * POST : cancel
 *
 */
const cancel = (sharingId) => {
  return ApiBuilder.API.post('/sharing/cancel', {
    sharingId
  });
};

export default {
  shareWithFriendViaSMS,
  addSharingBooking,
  getSharingRequestById,
  getCreatedSharingsOfOwner,
  getSharingRequestsSearchResult,
  cancel
};
