import {
  SET_EXPERIENCE,
  ADD_EXPERIENCE,
  EDIT_EXPERIENCE
} from './ActionTypes';

export const setExperiences = (payload) => ({  
  type: SET_EXPERIENCE,
  payload
});

export const addExperience = (payload) => ({  
  type: ADD_EXPERIENCE,
  payload
});

export const editExperience = (payload) => ({  
  type: EDIT_EXPERIENCE,
  payload
});

export default {
  setExperiences,
  addExperience,
  editExperience
};