import React, { useState } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import { Rate, Modal, Input } from 'antd';
import './style.sass'
import ReviewService from '../../../services/ReviewService';

const AddReviewModal = ({experienceId, visible, setVisible, onReviewAdded = () => {}}) => {
  const [processing, setProcessing] = useState(false)
  const initialRatingsState = {
    valueForMoney: 5,
    boatQuality: 5,
    handling: 5,
    punctuality: 5,
    friendliness: 5
  }
  const [ratings, setRatings] = useState(initialRatingsState)
  const [review, setReview] = useState(null)

  const onSubmit = () => {  
    setProcessing(true)

    const payload = { ratings, review: review.trim() }
    ReviewService.addReview(experienceId, payload).then((res) => {
      const fetchedReview = res.data.result
      onReviewAdded(fetchedReview)
      setRatings(initialRatingsState)
      setReview(null)
      setVisible(false)
      setProcessing(false)
    }).catch((err) => {
      console.log(err)
    });    
  }

  const onCancel = () => { 
    setVisible(false) 
    setRatings(initialRatingsState)
    setReview(null)
  }

  const onStarRatingChange = (key, value) => {
		setRatings(prevRatings => ({
			...prevRatings,
			[key]: value
		}))
  }

  const style = {
    h6: {
      marginBottom: 10,
    },
    p: {
      marginBottom: 0,
      fontWeight: 400,
    },
    rating: {
      color: '#fcc603',
      marginBottom: 10
    }
  }

  return ( 
    <div className="add-review-modal-component">
      <Modal
        title="Add Review"
        centered
        visible={visible}
        onOk={onSubmit}
        onCancel={onCancel}
        okText="Submit Review"
        confirmLoading={processing}
        closable={false}
        okButtonProps={{
          disabled: !review || (review && review.trim() === '')
        }}
        cancelButtonProps={{
          disabled: processing
        }}
      >
        <Container fluid={true}>
          <Row>
            <Col style={{ padding: 0 }}>
              <h6 style={style.h6}>Rate this experience!</h6>
            </Col>
          </Row>
          <Row>
            <Col sm={6} style={{ padding: 0 }}>
              <div>
                <p style={style.p}>Value For Money :</p>
                <Rate 
                  style={style.rating}
                  defaultValue={5}
                  onChange={val => onStarRatingChange('valueForMoney', val)}
                  value={ratings.valueForMoney}
                />
              </div>
              <div>
                <p style={style.p}>Boat Quality :</p>
                <Rate 
                  style={style.rating}
                  defaultValue={5}
                  onChange={val => onStarRatingChange('boatQuality', val)}
                  value={ratings.boatQuality}
                />
              </div>
              <div>
                <p style={style.p}>Friendliness :</p>
                <Rate 
                  style={style.rating}  
                  defaultValue={5}
                  onChange={val => onStarRatingChange('friendliness', val)}
                  value={ratings.friendliness}
                />
              </div>
            </Col>
            <Col sm={6} style={{ padding: 0 }}>
            <div>
                <p style={style.p}>Handling :</p>
                <Rate 
                  style={style.rating}
                  defaultValue={5}
                  onChange={val => onStarRatingChange('handling', val)}
                  value={ratings.handling}
                />
              </div>
              <div>
                <p style={style.p}>Punctuality :</p>
                <Rate 
                  style={style.rating}
                  defaultValue={5}
                  onChange={val => onStarRatingChange('punctuality', val)}
                  value={ratings.punctuality}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col style={{ padding: 0 }}>
              <h6 style={style.h6}>Add your thoughts on this experience!</h6>
            </Col>
          </Row>

          <Row>
            <Col style={{ padding: 0 }}>
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 5 }}
                placeholder="Review"
                onChange={(e) => { setReview(e.target.value) }} 
                value={review}
              />
            </Col>
          </Row>
        </Container>
      </Modal>
    </div>
  );
}

export default AddReviewModal