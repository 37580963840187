import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { Input } from 'antd'

const SharingRequestMessageForm = ({details, onChange}) => {

	const { customMessage } = details
	
	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	return (
		<div className="sharing-request-custom-message-form-component">
			<Container>
				<Row>
					<Col>
						<p>Enter a custom message to be displayed to the renters!</p>
						<Input.TextArea
							autoSize={{ minRows: 3, maxRows: 4 }}
							placeholder="Custom Message"
							onChange={(e) => onChangeHandler("customMessage", e.target.value)} 
							value={customMessage}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default SharingRequestMessageForm;
