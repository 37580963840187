import React from 'react'
import './style.sass'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Container, Row, Col } from 'react-bootstrap'
import { Input, Checkbox, Row as ARow, Col as ACol, Popover } from 'antd'
import { BOAT_FEATURES } from '../../../../../constants/BoatConstants'

const BoatFeaturesForm = ({features, otherFeatures, onChange}) => {

	const onTextfieldChangeHandler = (otherFeatures) => {
		onChange("otherFeatures", otherFeatures)
	}

	const onCheckboxChangeHandler = (checkedValues) => {
		const selectedFeatures = Array.isArray(features) ? [...checkedValues] : checkedValues
		onChange("features", selectedFeatures)
	}

	const RequiredFeatures = () => (
		<div>
		<h5 style={{ fontWeight: '400', marginLeft: 25, marginTop: 15 }}>Note on some of the required features!</h5>
		<ul>
			<li>Medical kit for cuts, scrapes, seasickness or small emergencies</li>
			<li>Anchor with line to hold your boat in place while you wait for help to arrive</li>
			<li>Bailing device or bucket to dewater and stay afloat</li>
			<li>Oars or paddles if the engine quits</li>
			<li>Cellphone to call for help</li>
			<li>VHF radio to call for help</li>
			<li>Knife to cut a line around a fouled propeller</li>
			<li>Snorkel mask to inspect what’s going on under the boat</li>
			<li>Heavy duty flashlight</li>
			<li>Skier or diver down flag</li>
			<li>Working running lights if your boat is equipped with them</li>
			<li>A way to get weather updates because things can change quickly even on a lake</li>
		</ul>
		</div>
	)

	return (
		<div className="boat-features-form-component">
			<Container>
				<Row>
					<Col>
						<Checkbox.Group style={{ width: '100%' }} onChange={onCheckboxChangeHandler} value={features}>
							<ARow>
								{BOAT_FEATURES.map((feature, index) => (
									<ACol md={24} sm={24} xs={24} key={feature.name + index}>
										<Checkbox value={feature.name}>{feature.name}</Checkbox>
									</ACol>
								))}
							</ARow>
						</Checkbox.Group>
						<Input 
							placeholder="Other Features (Enter with comma in between)" 
							onChange={(e) => onTextfieldChangeHandler(e.target.value)}
							value={otherFeatures}
						/>
					</Col>
				</Row>
				<Row className="required-features-info-text-row">
					<Col>
						<Popover content={<RequiredFeatures />} trigger="hover">
							<span><InfoCircleOutlined/> Note on some of the required features!</span>
						</Popover>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default BoatFeaturesForm;
