import React, { Component } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import logo from '../../../../assets/logos/logoWhite.png'
import './style.sass'
import { Link } from 'react-router-dom';

class HomeFooter extends Component {
    render() {
        return (
            <div className="home-footer-component">
                <Container>
                    <Row>
                        <Col>
                            <div className="logo-container">
                                <img className="logo" src={logo} alt="logo"/>
                            </div>
                            <div className="text-container">
                                <p style={{ width: 200 }}>PO Box 7053, Cranbourne VIC 3977, Australia</p>
                                <p>info@theboatersclub.com </p>
                                <p>Copyright <b>2020</b> | <Link to='/privacy' style={{ color: '#20b7da' }}>Privacy Policy</Link></p>
                            </div>
                        </Col>
                        <Col>
                            <span/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default HomeFooter;