import React, { Component, useState, useEffect } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Fade from 'react-reveal/Fade';
import ExperienceCardSmall from '../../../common/ExperienceCardSmall'
import ItemsCarousel from 'react-items-carousel';
import './style.sass'
import ExperienceService from '../../../../services/ExperienceService';
import useWindowDimensions from '../../../../hooks/useWindowDimensions'

const HomeSection1 = ({history}) => {
  const [loading, setLoading] = useState(true)
  const [promotedExperiences, setPromotedExperiences] = useState(null)
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const { width } = useWindowDimensions();

  useEffect(() => {
    loadPromotedExperiences()
  }, [])

  const loadPromotedExperiences = () => {
    setLoading(true)
    ExperienceService.getPromotedExperiences().then((res) => {
      const fetchedPromotedExperiences = res.data.result
      setPromotedExperiences(fetchedPromotedExperiences)
      setLoading(false)
    }).catch((err) => {
      console.log(err)
    });
  }

  const getNumberOfCards = () => {
    if (width < 500) return 1;
    if (width < 800) return 2;
    if (width >= 800) return 3;
  }

  const renderPromotedExperiences = () => {
    return (
      <Fade delay={100} duration={1000}>
        <div>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={getNumberOfCards()}
            gutter={12}
            chevronWidth={35}
            outsideChevron
            leftChevron={<button className='arrow-button'>{'<'}</button>}
            rightChevron={<button className='arrow-button'>{'>'}</button>}
          >
            {promotedExperiences.map(experience => (
                <ExperienceCardSmall 
                  key={experience._id}
                  experience={experience}
                  onClick={() => { history.push(`/experience/detail/${experience._id}`)}}
                />
            ))}
          </ItemsCarousel>
        </div>
      </Fade>
    )
  }

  return (
      <div className="home-section-1-component" id="about">
        <Container>
            <Row>
                <Col className="left-col" lg={4}>
                  <Fade delay={100} duration={1000}>
                    <label className="main-text-home-section-1">Australia's #1 Boat Sharing Platform now available in Sri-Lanka</label>
                    <label className="sub-text">Find any experiences to suit any budget, size or location!</label>
                  </Fade>
                </Col>
                <Col className="right-col" lg={8}>
                {loading ? <div></div> : renderPromotedExperiences()}
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default HomeSection1;
