import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Containers
import PageNotFound from './PageNotFound';
import Home from './Home';
import SignIn from './SignIn';
import Splash from './Splash';
import Search from './Search';
import ExperienceDetail from './ExperienceDetail';
import SharingRequestDetail from './SharingRequestDetail';
import InstantBookingWizard from './InstantBookingWizard';
import CustomBookingWizard from './CustomBookingWizard';
import AddBoatWizard from './AddBoatWizard';
import AddExperienceWizard from './AddExperienceWizard';
import SharingRequestWizard from './SharingRequestWizard';
import OwnerAccountSetupWizard from './OwnerAccountSetupWizard';
import SharingInstantBookingWizard from './SharingInstantBookingWizard';
import Profile from './Profile';
import FullScreenMap from './FullScreenMap';
import PrivacyPolicy from './PrivacyPolicy';
import AppPolicy from './AppPolicy';
import TermsAndConditions from './TermsAndConditions';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' component={Home} exact={true} />
        <Route path='/search/:address/:lat/:lng' component={Search} exact={true} />
        <Route path='/experience/detail/:experienceId' component={ExperienceDetail} exact={true} />
        <Route path='/sharing/detail/:sharingId' component={SharingRequestDetail} exact={true} />
        <Route path='/full/map/:title/:lat/:lng' component={FullScreenMap} exact={true} />
        <Route path='/privacy' component={PrivacyPolicy} exact={true} />
        <Route path='/policy' component={AppPolicy} exact={true} />
        <Route path='/terms' component={TermsAndConditions} exact={true} />

        <PublicOnlyRoute path='/signin' component={SignIn} exact={true} />
        
        <PrivateRoute path='/experience/booking' component={InstantBookingWizard} exact={true} />
        <PrivateRoute path='/experience/booking/custom' component={CustomBookingWizard} exact={true} />
        <PrivateRoute path='/sharing/booking' component={SharingInstantBookingWizard} exact={true} />
        <PrivateRoute path='/profile' component={Profile} exact={false} />
        <PrivateRoute path='/boat/add' component={AddBoatWizard} exact={true} />
        <PrivateRoute path='/experience/add' component={AddExperienceWizard} exact={true} />
        <PrivateRoute path='/owner/setup' component={OwnerAccountSetupWizard} exact={true} />
        <PrivateRoute path='/sharing/booking/add' component={SharingRequestWizard} exact={true} />
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.user.user);
  const loading = useSelector((state) => state.user.loading);
  const isAuthenticated = !!user

  return (
    <Route 
      {...rest} 
      render={props =>
        loading ? (
          <Splash />
        ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/signin',
            state: { from: props.location }
          }} />
        )
      }
    />
  )
}

const PublicOnlyRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.user.user);
  const isAuthenticated = !!user

  return (
    <Route 
      {...rest} 
      render={(props) => (
        (!isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/',
            state: { from: props.location }
          }} />
        )
      )}
    />
  )
}

export default AppRouter;
