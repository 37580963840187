import React from 'react'
import './style.sass'

const BoatOwnerDetailsCard = ({name, image, description }) => {
  return ( 
    <div className="boat-owner-details-card-component">
      <h4>Boat Owner Details</h4>
      <hr/>
      <div className="flex-container">
        <div className="right">
          <img src={image} alt="owner"/>
        </div>
        <div className="left">
          <h5>{name}</h5>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

export default BoatOwnerDetailsCard