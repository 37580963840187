import React from 'react'
import './style.sass'
import { Modal } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import placeholder from '../../../assets/images/placeholder.png'
import { PUBLIC_S3_BUCKET_URL } from '../../../constants/AwsConstants'
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { DownloadOutlined } from '@ant-design/icons';

const BoatDetailsModal = ({ boat, setBoat}) => {
  const { width } = useWindowDimensions();
  
  const getModalWidth = () => {
    if (width < 700) return '100%';
    if (width < 1000) return '90%';;
    if (width < 1350) return '80%';;
    if (width >= 1350) return '70%';;
  }

  const style = {
    p: {
      fontSize: width < 400 ? 14 : 16,
      marginBottom: 10,
    },
    h3: {
      textAlign: 'center'
    },
  }
  
  return (
    <div className="boat-details-modal-component" >
      {!boat || (
        <Modal
          visible={!!boat}
          footer={null}
          onCancel={() => setBoat(null)}
          width={getModalWidth()}
        >
          {!boat || (
            <div>
              <h3 style={style.h3}><b>Boat Name:</b> {boat.name}</h3>
              <hr/>
              <Container fluid={true}>
                <Row>
                  <Col md={6}>
                    <img 
                      src={(boat.images && boat.images.length > 0) ? PUBLIC_S3_BUCKET_URL + boat.images[0] : placeholder} 
                      alt="boat"
                      style={{ width: '100%', borderRadius: 5, marginBottom: 10 }}
                    />
                  </Col>
                  <Col md={6}>
                    <p style={style.p}><b>Name:</b> {boat.name}</p>
                    <p style={style.p}><b>Registration Number:</b> {boat.registrationNumber ? boat.registrationNumber : 'N/A'}</p>
                    <p style={style.p}><b>Brand:</b> {boat.brand}</p>
                    <p style={style.p}><b>Model:</b> {boat.model}</p>
                    <p style={style.p}><b>Year:</b> {boat.yearOfManufacture}</p>
                    <p style={style.p}><b>Category:</b> {boat.category}</p>
                    <p style={style.p}><b>Max Capacity:</b> {boat.maxCapacity} People</p>
                    <p style={style.p}><b>Length:</b> {boat.length}m</p>
                    <p style={style.p}><b>Features:</b> {boat.features.join(", ")}</p>
                    <p style={style.p}><b>Boat Insurance: </b>
                      <span>
                        <DownloadOutlined style={{ color: '#20b7da' }}/>
                        <a style={{ color: '#20b7da' }} href={PUBLIC_S3_BUCKET_URL + boat.boatInsurance[0]}> Download</a>
                      </span>
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </Modal>
      )}
    </div>
  )
}

export default BoatDetailsModal