import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { DatePicker } from 'antd'
import moment from 'moment'


const InstantBookingDatesForm = ({departureDate, experience, experienceBookedDates, onChange}) => {
	const { departureTime, numberOfHours, sailingDays } = experience;

	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	const disabledDate = (current) => {

		// 1. Block Dates before current date and current date 
		if (current.isBefore(moment()) || current.isSame(moment())) {
			return true;
		}

		// 2. Block Dates which are not sailing days
		if (!sailingDays.includes(current.format("dddd"))) {
			return true;
		}

		// 3. Block Dates which are already booked & Status === CONFIRMED AND Block NEXT Date(s) IF booked date + duration is after depatureTime
		const confirmedBookedDays = []

		experienceBookedDates.forEach(item => {
			if (item.status === "CONFIRMED") {
				const startDateTime = moment(item.departureDate) 
				const endDateTime = moment(startDateTime).add(24, 'h')

				while (startDateTime.isSameOrBefore(endDateTime)) {
					confirmedBookedDays.push(startDateTime.format('YYYY MM DD'))
					startDateTime.add(1, 'days');
				}
			}
		})	
		
		if (confirmedBookedDays.includes(current.format('YYYY MM DD'))) {
			return true;
		}

		// 4. Block Dates which are already booked & Status !== CONFIRMED & createdDate diff mins === 30mins
		const pendingBookedDays = experienceBookedDates.map(item => {
			return (item.status !== "CONFIRMED" && moment().diff(moment(item.createdDate), "minutes") < 30) ? (moment(item.departureDate).format('YYYY MM DD')) : null 
		})
	
		if (pendingBookedDays.includes(current.format('YYYY MM DD'))) {
			return true;
		}

		// Return FLASE if any of the above conditions are not true
    return false;
	}

	return (
		<div className="instant-booking-dates-form-component">
			<Container>
				<Row>
					<Col>
						<p>Select your Departure Date</p>
						<DatePicker
							placeholder="Departure Date" 
							onChange={(date) => onChangeHandler("departureDate", date)}
							value={departureDate}
							showToday={false}
							disabledDate={disabledDate}
						/>
						{/*<p>Your Arrival Date: {moment(departureDate).add({ h: numberOfHours}).format('YYYY MM DD')}</p>*/}
						<p><b>Duration :</b> {numberOfHours} Hours</p>
						<p><b>Departure Time :</b> {moment(departureTime).format('h:mm a')}</p>
						<p><b>Arrival Time :</b> {moment(departureTime).add({ h: numberOfHours}).format('h:mm a')}</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default InstantBookingDatesForm;
