import React, { useEffect, useState } from 'react'
import './style.sass'
import { useSelector, useDispatch } from 'react-redux';
import BoatService from '../../../../../services/BoatService'
import BoatActions from '../../../../../redux/actions/BoatActions'
import ProfileBoatCard from '../../../../common/ProfileCardsHorizontal/ProfileBoatCard';
import { Spin } from 'antd';
import CustomLinkButton from '../../../../common/CustomLinkButton';
import BoatDetailsModal from '../../../../common/BoatDetailsModal';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';

const Boats = () => {
  const [loading, setLoading] = useState(true)
  const boats = useSelector((state) => state.boat.boats);
  const [viewedBoat, setViewedBoat] = useState(null)
  const dispatch = useDispatch();
  const history= useHistory();

  useEffect(() => {
    loadBoats()
  }, [])

  const loadBoats = () => {
    setLoading(true)
    BoatService.getBoats().then((res) => {
      const userBoats = res.data.result
      dispatch(BoatActions.setBoats(userBoats))
      setLoading(false)  
    }).catch((err) => {
      console.log(err)
    });
  }

  return (
    <Fade delay={0} duration={1000}>
      <div className="profile-boats-component">
        <div className="profile-boats-header-container">
          <h3>My Boats</h3>
          <CustomLinkButton
            text="Add New Boat"
            to='/boat/add'
          /> 
        </div>
        <hr />
        {loading ? <Spin style={{padding: 10}} size='large' /> : (
          (boats && boats.length > 0) ? (
            <div>
              {boats.map(boat => (
                <ProfileBoatCard 
                  key={boat._id}
                  boat={boat}
                  onViewClicked={() => setViewedBoat(boat)}
                  onEditClicked={()=>{ history.push('/boat/add', { boat })}}
                />
              ))}
            </div>
          ) : (
            <div className="empty-message-container">
              <h4>You have no boats created yet!</h4>
            </div>
          )
        )}
        <BoatDetailsModal 
          boat={viewedBoat}
          setBoat={setViewedBoat}
        />
      </div>
    </Fade>
  )
}

export default Boats;
