import React, { useEffect, useState } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import { Pagination } from 'antd';
import MainLayout from '../../layouts/MainLayout'
import ExperienceCardSmall from '../../common/ExperienceCardSmall'
import MapContainer from '../../common/MapContainer'
import './style.sass'
import ExperienceService from '../../../services/ExperienceService'
import SharingService from '../../../services/SharingService'
import Splash from '../Splash';
import Fade from 'react-reveal/Fade';
import LocationSearchBar from '../../common/LocationSearchBar';
import { useRouteMatch } from 'react-router-dom';
import TextReviews from '../../common/TextReviews';
import SearchFilters from './SearchFilters'
import { InfoCircleOutlined } from '@ant-design/icons'
import SharingRequestCard from '../../common/SharingRequestCard';
import moment from 'moment';

const Search = ({ history }) => {
	const match = useRouteMatch();
	const address = match.params ? match.params.address : null
	const lat = match.params ? match.params.lat : null
	const lng = match.params ? match.params.lng : null

	const [experiences, setExperiences] = useState(null)
	const [loadingExperiences, setLoadingExperiences] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [filterValues, setFilterValues] = useState({
		duration: null, 
		price: null, 
		groupSize: null,
		depatureDate: null,
	})
	const [sharingRequests, setSharingRequests] = useState(null)
	const [loadingSharingRequests, setLoadingSharingRequests] = useState(false)
	const [sharingRequestsCurrentPage, setSharingRequestsCurrentPage] = useState(1)
	
	
	useEffect(() => {
		loadSearchResults();
	}, [address])

	const loadSearchResults = () => {
		setLoadingExperiences(true)
		setLoadingSharingRequests(true)

		ExperienceService.getExperiencesSearchResult(address, lat, lng).then((res) => {
			const fetchedExperiences = res.data.result
			setExperiences(fetchedExperiences)			
			setLoadingExperiences(false)
		}).catch((err) => {
			console.log(err)
		});

		SharingService.getSharingRequestsSearchResult(address, lat, lng).then((res) => {
			const fetchedSharingRequests = res.data.result
			setSharingRequests(fetchedSharingRequests)		
			setLoadingSharingRequests(false)
		}).catch((err) => {
			console.log(err)
		});
	}
	
	const onFilterValueChange = (key, value) => {	
		setFilterValues(prevFilterValues => ({
			...prevFilterValues,
			[key]: value
		}))
	}
	
	const getMapMarkers = () => {
		let experiencesMarkers = []
		let sharingRequestMarkers = []

		const filteredExperiences = getFilteredExperiences()

		if (filteredExperiences) {
			experiencesMarkers = filteredExperiences.map(e => (
				{ name: e.name, lat: e.location.lat, lng: e.location.lng }
			))
		}  
		if (sharingRequests) {
			sharingRequestMarkers = sharingRequests.map(sr => (
				{ name: sr.userId.firstName, lat: sr.location.lat, lng: sr.location.lng }
			))
		}
		return [...experiencesMarkers, ...sharingRequestMarkers]
	}

	const getLocationReviews = () => {
		if (!experiences) return [];
		return experiences.reduce((locationReviews, e) => {
			return [...locationReviews, ...e.reviews]
		}, [])
	}

	const getFilteredExperiences = () => {
		const { duration, price, groupSize, depatureDate } = filterValues
		let filteredExperiences = experiences

		if(duration){
			filteredExperiences = filteredExperiences.filter(exp => exp.numberOfHours <= duration)
		}
		if(price){
			filteredExperiences = filteredExperiences.filter(exp => exp.price <= price)
		}
		if(groupSize){
			filteredExperiences = filteredExperiences.filter(exp => exp.groupSize <= groupSize)
		}
		if(depatureDate){
			filteredExperiences = filteredExperiences.filter(exp => {
				const confirmedBookedDays = []
				exp.bookings.forEach(item => {
					if (item.status === "CONFIRMED") {
						const startDateTime = moment(item.departureDate) 
						const endDateTime = moment(startDateTime).add(24, 'h')
	
						while (startDateTime.isSameOrBefore(endDateTime)) {
							confirmedBookedDays.push(startDateTime.format('YYYY MM DD'))
							startDateTime.add(1, 'days');
						}
					}
				})	
				return !confirmedBookedDays.includes(depatureDate.format('YYYY MM DD'))
			})
		}

		return filteredExperiences
	}

	const renderExperienceCards = () => {
		const filteredExperiences = getFilteredExperiences()
		
		const paginatedExperiences = filteredExperiences.slice(
			currentPage === 1 ? 0 : (currentPage - 1) * 9, 
			(currentPage * 9)
		)

		return (paginatedExperiences.map(experience => (
			<Col sm={12} md={4} lg={4} key={experience._id}>
				<Fade delay={0} duration={800}>
					<ExperienceCardSmall 
						experience={experience}
						onClick={() => { history.push(`/experience/detail/${experience._id}`)}}
					/>
				</Fade>
			</Col>
		)))
	}

	const renderSharingRequestCards = () => {		
		const paginatedSharingRequests = sharingRequests.slice(
			sharingRequestsCurrentPage === 1 ? 0 : (sharingRequestsCurrentPage - 1) * 6, 
			(sharingRequestsCurrentPage * 6)
		)

		return (paginatedSharingRequests.map(sharingRequest => (
			<Col sm={12} md={6} lg={6} xl={4} key={sharingRequest._id}>
				<Fade delay={0} duration={800}>
					<SharingRequestCard
						request={sharingRequest}
						onClick={() => { history.push(`/sharing/detail/${sharingRequest._id}`)}}
					/>
				</Fade>
			</Col>
		)))
	}

	return (
		<MainLayout>
			{loadingExperiences || loadingSharingRequests || !experiences || !sharingRequests ? (
				<Splash />
			) : (
				<div className="search-component">
					{/* ============= Floating Search BAR ============= */}
					{<LocationSearchBar history={history}/>}
				

					{/* ============= SECTIONS ============= */}
					<Container fluid={true}>
						<Row className="row">
							<Col className="left-col" lg={8}>

								{/* SEARCH FILTERS */}
								<SearchFilters 
									filterValues={filterValues} 
									onChange={onFilterValueChange}
								/>

								{/* HEADING SECTION */}
								<Fade delay={0} duration={800}>
									<h1>Showing Amazing Boating Experiences in <b>{address}</b></h1>
									<p>{getFilteredExperiences().length} Results (Showing  {currentPage} - {Math.ceil(getFilteredExperiences().length / 9)})</p> 
									<hr/>
								</Fade>

								{/* EXPERIENCE CARDS SECTION */}
								<div className="cardsContainer">
									<Row className="row">
										{getFilteredExperiences().length > 0 ? renderExperienceCards() : (
											<Col>
												<h4 className="text-with-icon"><InfoCircleOutlined /> Oops, No Results Found!</h4>
											</Col>
										)}
									</Row>

									<Row className="row">
										<Pagination 
											style={{ margin: 'auto', marginTop: 15,  marginBottom: 15 }} 
											defaultCurrent={1}
											pageSize={9} 
											total={getFilteredExperiences().length} 
											onChange={(page, pageSize) => { 
												window.scrollTo(0, 0) 
												setCurrentPage(page)
											}}
											hideOnSinglePage
										/>
									</Row>
								</div>
								
								{/* SHARING REQUEST CARDS SECTION */}
								{!sharingRequests.length > 0 || (
									<div>
										<h3>You Can <b>Share A Ride</b> With These Boaters</h3>
										<div className="cardsContainer">
											<Row className="row">
												{sharingRequests.length > 0 ? renderSharingRequestCards() : (
													<Col>
														<h4 className="text-with-icon"><InfoCircleOutlined /> Oops, No Results Found!</h4>
													</Col>
												)}
											</Row>
											<Row className="row">
												<Pagination 
													style={{ margin: 'auto',  marginBottom: 15 }} 
													defaultCurrent={1}
													pageSize={6} 
													total={sharingRequests.length} 
													onChange={(page, pageSize) => { 
														setSharingRequestsCurrentPage(page)
													}}
													hideOnSinglePage
												/>
											</Row>
										</div>
									</div>
								)}

								{/* INFO SECTION */}
								<Fade delay={0} duration={800}>
									<h3>Book Amazing Boating Experiences Around the Globe!</h3>
									<p className="static-description-text">
										Enjoying the water has never been more easier thanks to TheBoatersClub! We bring to you the most creative & fun experiences where you can enjoy with your friends! Using our website you can easily search for experiences, compare prices, make instant booking, request customized booking & even share your experience with friends! You can trust TheBoatersClub since we have the best experiences to suit any budget! For boat owners, we give access to millions of renters all around the globe!
									</p>
								</Fade>

								{/* REVIEW SECTION */}
								<Fade delay={0} duration={800}>
									<h3>What others say about {address}</h3>
									{getLocationReviews().length > 0 ? (
										<TextReviews reviews={getLocationReviews()}/>
									) : (
										<p className="text-with-icon"><InfoCircleOutlined /> No reviews available for this location yet!</p>
									)}
								</Fade>
								<br />
								<br />
								<br />
							</Col>
							<Col className="right-col" lg={4}>
								{/* MAP SECTION */}
								<MapContainer 
									style={{width: "100%", height: "95vh", margin: 0}}
									markers={getMapMarkers()}
									initialCenter={{lat, lng}}
									zoom={10}
								/>
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</MainLayout>
	);
}

export default Search;
