import React, { useEffect, useState } from 'react'
import './style.sass'
import { useSelector, useDispatch } from 'react-redux';
import ExperienceService from '../../../../../services/ExperienceService'
import ExperienceActions from '../../../../../redux/actions/ExperienceActions'
import ProfileExperienceCard from '../../../../common/ProfileCardsHorizontal/ProfileExperienceCard';
import { Spin } from 'antd';
import CustomLinkButton from '../../../../common/CustomLinkButton';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';

const Experiences = () => {
  const [loading, setLoading] = useState(true)
  const experiences = useSelector((state) => state.experience.experiences)
  const history = useHistory()
	const dispatch = useDispatch();

  useEffect(() => {
    loadExperiences()
  }, [])

  const loadExperiences = () => {
    setLoading(true)
  
    ExperienceService.getCreatedExperiences().then((res) => {
      const userExperiences = res.data.result
      dispatch(ExperienceActions.setExperiences(userExperiences))
      setLoading(false)  
    }).catch((err) => {
      console.log(err)
    });
  }

  return (
    <Fade delay={0} duration={1000}>
      <div className="profile-experiences-component">
        <div className="profile-experiences-header-container">
          <h3>My Experiences</h3>
          <CustomLinkButton
            text="Add New Experience"
            to='/experience/add'
          /> 
        </div>
        <hr />
        {loading ? <Spin style={{padding: 10}} size='large' /> : (
          (experiences && experiences.length > 0) ? (
            <div>
              {experiences.map(experience => (
                <ProfileExperienceCard 
                  key={experience._id}
                  experience={experience}
                  onViewClicked={() => { history.push(`/experience/detail/${experience._id}`)}}
                  onEditClicked={()=>{ history.push('/experience/add', { experience })}}
                />
              ))}
            </div>
          ) : (
            <div className="empty-message-container">
              <h4>You have no experiences created yet!</h4>
            </div>
          )
        )}
      </div>
    </Fade>
  )
}

export default Experiences;
