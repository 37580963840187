import React, {Component} from 'react'
import './style.sass'
import {Map, Marker, Circle} from 'google-maps-react'
import { GOOGLE_MAPS_API_KEY } from '../../../constants/ApiConstants'
import icon from '../../../assets/icons/map-marker.png'
import { loadScript } from '../../../helpers/common'

/**
 * 
 * @prop markers : [{name, lat, lng}]
 * @prop circles : [{radius, center : { lat, lng}}]
 * @prop onMapPositionClicked : () => {}
 * @prop initialCenter : {lat, lng}
 * @prop zoom : Number
 * 
 */
export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScriptLoaded: false
    };
  }

  componentDidMount() {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, 'googleMapsScript', () => {
      const googleChecker = setInterval(() => {
        if(window.google){
          this.setState({ isScriptLoaded: true })
          clearInterval(googleChecker)
        }
      }, 500);
    })
  }

  renderMarkers = () => {
    const {markers = []} = this.props
    const { google } = window

    return markers.map((marker, index) => {
      const {name, lat, lng} = marker
      return (
        <CustomMarker
          key={`${index}`}
          name={name}
          position={{lat, lng}}
          google={google}
        />
      )
    })
  }

  renderCircles = () => {
    const {circles = []} = this.props

    return circles.map((circle, index) => {
      return (
        <Circle
          key={`${index}`}
          radius={circle.radius || 1200}
          center={circle.center}
          strokeColor='transparent'
          strokeOpacity={0}
          strokeWeight={5}
          fillColor='#20b7da'
          fillOpacity={0.3}
        />
      )
    })
  }

  render() {
    if (this.state.isScriptLoaded && window.google) {
      const { zoom, initialCenter, onMapPositionClicked, style } = this.props
      const { google } = window
      
      const defaultStyle = {
        width: "100%",
        height: "100%",
        margin: 0
      }

      const mapProps = {
        google: google,
        zoom: zoom || 14,
        initialCenter: initialCenter,
        center: initialCenter,
        style: style ? style : defaultStyle,
        onClick: onMapPositionClicked,
        streetViewControl: false,
      }

      return (
        <div className="google-map-container-component">
          <Map {...mapProps}>
            {this.renderMarkers()}
            {this.renderCircles()}
          </Map>
        </div>
      );
    }
    return <div></div>
  }
}

const CustomMarker = (props) => (
  <Marker
    {...props}
    icon={{
      url: icon,
      anchor: new props.google.maps.Point(32,32),
      scaledSize: new props.google.maps.Size(64,64)
    }} 
  />
)

export default MapContainer