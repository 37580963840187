import {
  SET_USER_LOADING,
  SET_USER
} from './ActionTypes';

export const setUserLoading = (payload) => ({  
  type: SET_USER_LOADING,
  payload
});

export const setUser = (payload) => ({  
  type: SET_USER,
  payload
});

export default {
  setUserLoading,
  setUser
};