import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { InputNumber } from 'antd'

const ExperiencePriceForm = ({price, onChange}) => {

	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	return (
		<div className="experience-price-form-component">
			<Container>
				<Row>
					<Col>
						<p>List Price of Experience</p>
						<InputNumber 
							onChange={(val) => onChangeHandler("price", val)} 
							value={price}
							maxLength={10}
							placeholder={"Price (LKR)"}
						/>
						<p>Note that "TheBoatersClub" will charge <b>10%</b> of your total experience price as the transaction + payment processing fee.</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default ExperiencePriceForm;
