import React, { useState } from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'

// Icons
import canoe from '../../../../../assets/icons/boatCategoryIcons/canoe.png'
import catamaran from '../../../../../assets/icons/boatCategoryIcons/catamaran.png'
import ferry from '../../../../../assets/icons/boatCategoryIcons/ferry.png'
import fishing from '../../../../../assets/icons/boatCategoryIcons/fishing.png'
import jetski from '../../../../../assets/icons/boatCategoryIcons/jetski.png'
import sail from '../../../../../assets/icons/boatCategoryIcons//sail.png'
import speed from '../../../../../assets/icons/boatCategoryIcons/speed.png'
import yacht from '../../../../../assets/icons/boatCategoryIcons/yacht.png'
import houseboat from '../../../../../assets/icons/boatCategoryIcons/houseboat.png'
// import dinghy from '../../../../../assets/icons/boatCategoryIcons/dinghy.png'


const BoatCategoryForm = ({category, onChange}) => {

	const onClickHandler = (selectedCategory) => {
		onChange("category", selectedCategory)
	}

	return (
		<div className="boat-category-form-component">
			<Container>
				<Row>
					<Col xs={4}>
						<BoatCategoryCard 
							title="Canoe"
							image={canoe}
							selected={category === "Canoe"}
							onClick={() => onClickHandler("Canoe")}
						/>
					</Col>
					<Col xs={4}>
						<BoatCategoryCard 
							title="Catamaran"
							image={catamaran}
							selected={category === "Catamaran"}
							onClick={() => onClickHandler("Catamaran")}
						/>
					</Col>
					<Col xs={4}>
						<BoatCategoryCard 
							title="Ferry"
							image={ferry}
							selected={category === "Ferry"}
							onClick={() => onClickHandler("Ferry")}
						/>
					</Col>
					<Col xs={4}>
						<BoatCategoryCard 
							title="Fishing"
							image={fishing}
							selected={category === "Fishing"}
							onClick={() => onClickHandler("Fishing")}
						/>
					</Col>
					<Col xs={4}>
						<BoatCategoryCard 
							title="House Boat"
							image={houseboat}
							selected={category === "House"}
							onClick={() => onClickHandler("House")}
						/>
					</Col>
					<Col xs={4}>
						<BoatCategoryCard 
							title="Jetski"
							image={jetski}
							selected={category === "Jetski"}
							onClick={() => onClickHandler("Jetski")}
						/>
					</Col>
					<Col xs={4}>
						<BoatCategoryCard 
							title="Sail Boat"
							image={sail}
							selected={category === "Sail"}
							onClick={() => onClickHandler("Sail")}
						/>
					</Col>
					<Col xs={4}>
						<BoatCategoryCard 
							title="Speed Boat"
							image={speed}
							selected={category === "Speed"}
							onClick={() => onClickHandler("Speed")}
						/>
					</Col>
					<Col xs={4}>
						<BoatCategoryCard 
							title="Yacht"
							image={yacht}
							selected={category === "Yacht"}
							onClick={() => onClickHandler("Yacht")}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

const BoatCategoryCard = ({title, image, selected, onClick}) => (
	<div 
		className={selected ? "boat-category-card-component boat-selected" : "boat-category-card-component" } 
		onClick={onClick}
	>
		<p>{title}</p>
		<img src={image} alt={title} />
	</div>
)

export default BoatCategoryForm;
