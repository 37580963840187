import React, { Component } from 'react'
import Fade from 'react-reveal/Fade';
import { Container, Row, Col} from 'react-bootstrap'
import search from '../../../../assets/icons/search.png'
import book from '../../../../assets/icons/book.png'
import enjoy from '../../../../assets/icons/enjoy.png'
import './style.sass'

class HomeSection3 extends Component {
    render() {
        return (
            <div className="home-section-3-component">
                <Container>
                    <Fade delay={100} duration={1000}>
                        <Row className="row">
                            <Col>
                                <h1>Easy & Fast to Book!</h1>
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col>
                                <img className="icon" src={search} alt="logo"/>
                                <p className="label">SEARCH</p>
                                <p className="sub-label">Filter Based on Location, Experience, Budget!</p>
                            </Col>
                            <Col>
                                <img className="icon" src={book} alt="logo"/>
                                <p className="label">BOOK</p>
                                <p className="sub-label">Book your experience instantly or get a custom quote!</p>
                            </Col>
                            <Col>
                                <img className="icon" src={enjoy} alt="logo"/>
                                <p className="label">ENJOY</p>
                                <p className="sub-label">Sit back & enjoy your BoatersClub Experience!</p>
                            </Col>
                        </Row>
                    </Fade>
                </Container>
            </div>
        );
    }
}

export default HomeSection3;
