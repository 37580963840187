import {
  SET_OWNER_SHARING_REQUESTS,
  UPDATE_OWNER_SHARING_REQUESTS
} from '../actions/ActionTypes';

const defaultState = {
  ownerSharingRequests: null
};

const SharingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_OWNER_SHARING_REQUESTS:
      return {
        ...state,
        ownerSharingRequests: action.payload
      };
    case UPDATE_OWNER_SHARING_REQUESTS:
      return {
        ...state,
        ownerSharingRequests: state.ownerSharingRequests ? [action.payload, ...state.ownerSharingRequests.filter(item => item._id !== action.payload._id)] : [action.payload]
      };
    default:
      return state;
  }
};

export default SharingReducer;