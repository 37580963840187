import React from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import FooterSmall from '../../common/FooterSmall'
import { Container } from 'react-bootstrap'
import { Row, Col } from 'antd'

const AppPolicy = () => {
	return (
		<MainLayout>
			<div className="app-policy-component">
				<Container>
					<Row>
						<Col>
							<div className="header-container">
								<h1><b>App Privacy</b> Policy</h1>
								<hr/>
							</div>
							
							<div className="content-container">

							<p>We understand that protecting your personal information is important. This Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us when providing our website and mobile application Services to you. We may collect personal information offline or online, including through our website, our mobile application, when you create an account with us, our use of cookies, and any other time you use our Services. In this Privacy Policy we, us or our means THE BOATERS CLUB PTY LTD ABN 32 641 564 918. </p>

							<h3>Personal information</h3>
							<p>The types of personal information we may collect about you include:</p>
							<ul>
								<li>your name;</li>
								<li>images of you;</li>
								<li>your contact details, including email address, mailing address, street address and/or telephone number; </li>
								<li>your credit card or payment details (through our third party payment processor);</li>
								<li>your preferences and/or opinions;</li>
								<li>information you provide to us through customer surveys;</li>
								<li>your sensitive information as set out below;</li>
								<li>details of services we have provided to you or users of our marketplace provide to you;</li>
								<li>your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing behaviour;</li>
								<li>information about your access and use of our Services, including through the use of Internet cookies, your communications with our online Services, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider;</li>
								<li>additional personal information that you provide to us, directly or indirectly, through your use of our Services, associated applications, associated social media platforms and/or accounts from which you permit us to collect information;</li>
								<li>any other personal information requested by us and/or provided by you or a third party.</li>
							</ul>
							<p>We may collect these types of personal information directly from you or from third parties.</p>

							<h3>Collection and use of personal information</h3>
							<p>We may collect, hold, use and disclose personal information for the following purposes:</p>
							<ul>
								<li>to enable you to access and use our Services, including to provide you with a login; </li>
								<li>if you are a Customer wanting to hire a vessel or purchase an experience or services from a Boat Owner, to provide to the Boat Owner where you have made a booking request;</li>
								<li>where you have requested it, to enable you to communicate with other users of our Services;</li>
								<li>to enable you to access and use associated applications and associated social media platforms;</li>
								<li>to contact and communicate with you about our Services;</li>
								<li>for internal record keeping, administrative, invoicing and billing purposes;</li>
								<li>for analytics, market research and business development, including to operate and improve our Services, associated applications and associated social media platforms;</li>
								<li>to run promotions, competitions and/or offer additional benefits to you;</li>
								<li>for advertising and marketing, including to send you promotional information about our products and services and information that we consider may be of interest to you;</li>
								<li>to comply with our legal obligations and resolve any disputes that we may have; </li>
								<li>if you have applied for employment with us; to consider your employment application.</li>
							</ul>

							<h3>Disclosure of personal information to third parties</h3>
							<p>We may disclose personal information to:</p>
							<ul>
								<li>third party service providers for the purpose of enabling them to provide their services, to us, including (without limitation) IT service providers, data storage, web-hosting and server providers, debt collectors, couriers, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;</li>
								<li>other users of our Services where you have made or accepted a booking request;</li>
								<li>our employees, contractors and/or related entities;</li>
								<li>our existing or potential agents or business partners;</li>
								<li>anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;</li>
								<li>credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;</li>
								<li>courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights; </li>
								<li>third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you; </li>
								<li>third parties to collect and process data, such as Google Analytics, Facebook Pixel or other relevant businesses;</li>
								<li>entities, persons or organisations where we are authorised by law.</li>
							</ul>

							<h3>Overseas disclosure:</h3>
							<p>Where we disclose your personal information to third parties listed above, these third parties may store, transfer or access personal information outside of Australia. 
							We will only disclose your personal information to countries with laws which protect your personal information in a way which is substantially similar to the Australian Privacy Principles or we will take such steps as are reasonable in the circumstances to protect your personal information in accordance with the Australian Privacy Principles.
							</p>

							<h3>Your rights and controlling your personal information</h3>
							<p>Your choice: Please read this Privacy Policy carefully. If you provide personal information to us, you understand we will collect, hold, use and disclose your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of our Services.
							Information from third parties: If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us. 
							Anonymity: Where practicable we will give you the option of not identifying yourself or using a pseudonym in your dealings with us.
							Restrict and unsubscribe: To object to processing for direct marketing/unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.
							Access: You may request details of the personal information that we hold about you.  An administrative fee may be payable for the provision of such information. Please note, in some situations, we may be legally permitted to withhold access to your personal information.
							Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to promptly correct any information found to be inaccurate, incomplete, misleading or out of date. Please note, in some situations, we may be legally permitted to not correct your personal information.
							Complaints: If you wish to make a complaint, please contact us using the details below and provide us with full details of the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take in response to your complaint. You also have the right to contact the relevant privacy authority.
							</p>
							
							<h3>Storage and security</h3>
							<p>We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures, to safeguard and secure personal information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.
							We cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at your own risk. 
							</p>

							<h3>Cookies and web beacons</h3>
							<p>We may use cookies on our online Services from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our online Services with personal information, this information may be linked to the data stored in the cookie.
							You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our online Services.
							We may use web beacons on our online Services from time to time. Web beacons (also known as Clear GIFs) are small pieces of code placed on a web page to monitor the visitor’s behaviour and collect data about the visitor’s viewing of a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.
							We may use Google Analytics to collect and process data. To find out how Google uses data when you use third party websites or applications, please see www.google.com/policies/privacy/partners/ or any other URL Google may use from time to time.
							</p>

							<h3>Links to other websites</h3>
							<p>Our Services may contain links to other websites. We do not have any control over those websites and we are not responsible for the protection and privacy of any personal information which you provide whilst visiting those websites. Those websites are not governed by this Privacy Policy.</p>

							<h3>Amendments</h3>
							<p>We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on our website. We recommend you check our website regularly to ensure you are aware of our current Privacy Policy.</p>
							
							</div>	
						</Col>
					</Row>
				</Container>
			</div>
			<FooterSmall />
		</MainLayout>
	);
}

export default AppPolicy;
