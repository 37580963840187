import ApiBuilder from './ApiBuilder';

/**
 *
 * POST : addBoat
 *
 */
const addBoat = (payload) => {
  return ApiBuilder.API.post('/boat/add', {
    ...payload,
  });
};

/**
 *
 * POST : editBoat
 *
 */
const editBoat = (boatId, payload) => {
  return ApiBuilder.API.post('/boat/edit', {
    boatId,
    updatedBoat: payload
  });
};

/**
 *
 * GET : getBoats
 *
 */
const getBoats = () => {
  return ApiBuilder.API.get('/boat/owner/created');
};

/**
 *
 * GET : getBoatById
 *
 */
const getBoatById = (boatId) => {
  return ApiBuilder.API.get(`/boat/${boatId}`);
};

export default {
  addBoat,
  editBoat,
  getBoats,
  getBoatById
};
