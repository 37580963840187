import React, { useState, useEffect } from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import WizardCard from '../../common/WizardCard'
import { useSelector, useDispatch } from 'react-redux'
import UserService from '../../../services/UserService'
import UserActions from '../../../redux/actions/UserActions'
import { uploadToS3, isOwnerAccountSetup } from '../../../helpers/common'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom'

// Forms
import OwnerAccountDetailsForm from './Forms/OwnerAccountDetailsForm'
import OwnerBankDetailsForm from './Forms/OwnerBankDetailsForm'
import PhoneNumberVerification from './Forms/PhoneNumberVerification'
import FileUploadForm from '../../common/FileUploadForm'
import WizardCompletionCard from '../../common/WizardCompletionCard'

const { confirm } = Modal;

const OwnerAccountSetupWizard = ({ history }) => {

	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user);
	const userFromNav = history.location.state ?  history.location.state.userFromNav : null // FOR EDIT MODE
	
	const [wizardIndex, setWizardIndex] = useState(0)
	const [processing, setProcessing] = useState(false)
	const [formData, setFormData] = useState({
		description: null,
		boatingLicence: null,
		phoneNumber: null,

		accountName: null, 
		accountNumber: null, 
		bankName: null, 
		bankBranch: null, 

		code: null,
		codeId: null,
		errorMessage: null
	}) 

	useEffect(() => {
		// FOR EDIT MODE
		setFormatedFromData()
	}, [])

	// FOR EDIT MODE
	const setFormatedFromData = () => {
		if(userFromNav) {
			const {
				accountName,
				accountNumber,
				bankName, 
				bankBranch
			} = userFromNav.bankDetails

			setFormData({
				...userFromNav,
				boatingLicence: null,
				accountName,
				accountNumber,
				bankName, 
				bankBranch
			})
		}
	}

	const onChangeHandler = (key, value) => {
		setFormData(formData => ({
			...formData,
			[key]: value
		}))
	}

	const onNextHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex + 1)
		}, 200);
	}

	const onBackHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex - 1)
		}, 200);
	}

	const getRequestPayload = async () => {
		const {boatingLicence} = formData

		try {
			const boatingLicenceUploadResult = (userFromNav && !boatingLicence) ? userFromNav.boatingLicence : (boatingLicence ? await uploadToS3(boatingLicence, 'owner/licence/') : []); // (userFromNav && !boatingLicence) FOR EDIT MODE
			const urls = (userFromNav && !boatingLicence) ? boatingLicenceUploadResult : (boatingLicence ? boatingLicenceUploadResult.map(item => item.key) : []);

			const {
				accountName, 
				accountNumber, 
				bankName, 
				bankBranch, 
			} = formData;

			return {
				...formData,
				bankDetails: {
					accountName, 
					accountNumber, 
					bankName, 
					bankBranch, 
				},
				boatingLicence: urls
			}
		} catch (error) {
			console.log(error)
		}
	}

	const onSubmitHandler = async () => {
		confirm({
			title: 'Do you want to submit the details?',
			icon: <ExclamationCircleOutlined />,
			centered: true,
			onOk : async (close) => {
				close()
				// =========================================================
				setProcessing(true)
				const payload = await getRequestPayload()
				
				UserService.updateOwnerDetailsAndVerifyPhoneNumber(payload).then((res) => {
					setWizardIndex(wizardIndex => wizardIndex + 1)
					setTimeout(() => {
						const user = res.data.result
						dispatch(UserActions.setUser(user))
						setProcessing(false)
					}, 100); // NOTE: Time out to avoid redirect issue - Check redirect condition
				}).catch((err) => {
					onChangeHandler("errorMessage", err)
					setProcessing(false)
				});
				// =========================================================
			}
		});
	}

	const onSubmitHandlerForEdit = async () => {
		confirm({
			title: 'Do you want to edit the details?',
			icon: <ExclamationCircleOutlined />,
			centered: true,
			onOk : async (close) => {
				close()
				// =========================================================
				setProcessing(true)
				const payload = await getRequestPayload()
				
				// FOR EDIT MODE
				UserService.updateVerifiedOwnerDetails(payload).then((res) => {
					setWizardIndex(wizardIndex => wizardIndex + 1)
					setTimeout(() => {
						const user = res.data.result
						dispatch(UserActions.setUser(user))
						setProcessing(false)
					}, 100); // NOTE: Time out to avoid redirect issue - Check redirect condition
				}).catch((err) => {
					onChangeHandler("errorMessage", err)
					setProcessing(false)
				});

				// =========================================================
			}
		});
	}

	const onCompleteHandler = () => {
		history.push('/profile/owner/settings')
	}

	const renderWizardCards = () => {
		const { 
			description,
			boatingLicence,
			phoneNumber,

			accountName, 
			accountNumber, 
			bankName, 
			bankBranch, 

			code,
			errorMessage
		} = formData

		const mainText = "OWNER ACCOUNT SETUP"
		const subText = "Setup Your Owners Account!"

		switch (wizardIndex) {
			case 0:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 1 : Enter Owner Details"
						form={<OwnerAccountDetailsForm onChange={onChangeHandler} details={{name: `${user.firstName} ${user.lastName}`, description, phoneNumber}} />}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						buttonDisabled={!(description && description.length >= 80)}
						loading={processing}
					/>
				)	
			case 1:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 2 : Upload Boating Licence"
						form={<FileUploadForm 
							files={boatingLicence} 
							onChange={onChangeHandler} 
							filesKey='boatingLicence' 
							infoText="Tip : Make sure to upload a clear document!"
							isEditMode={userFromNav ? true : null}
						/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						loading={processing}
					/>
				)	
			case 2:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 3 : Enter Bank Details"
						form={<OwnerBankDetailsForm details={{accountName, accountNumber, bankBranch, bankName}} onChange={onChangeHandler} />}
						buttonDisabled={!(accountName && accountNumber && bankBranch && bankName)} 
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						loading={processing}
					/>
				)	
			case 3:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 4 : Verify Phone Number"
						form={<PhoneNumberVerification 
							phoneNumber={phoneNumber} 
							code={code}
							errorMessage={errorMessage}
							onChange={onChangeHandler} 
							onSubmit={onSubmitHandler}
							onSubmitForEdit={onSubmitHandlerForEdit} // FOR EDIT without changing phone number
							/>
						}
						onBackClick={onBackHandler}
						loading={processing}
					/>
				)	
			case 4:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="SETUP COMPLETE"
						form={(
							<WizardCompletionCard
								mainText="Owner Account Setup Successful!"
								subText="You can now add your boat(s) and create experiences" 
							/>
						)}
						buttonText="GO TO MY PROFILE" 
						onClick={onCompleteHandler}
					/>
				)	
			default:
				return null
		}
	}

	return (
		<MainLayout>
			<div className="owner-account-setup-wizard-component">
				<div className="center-container">
					{wizardIndex !== 3 && isOwnerAccountSetup(user) && !userFromNav ?  <Redirect to='/profile/owner/settings' /> : renderWizardCards()}
				</div>
			</div>
		</MainLayout>
	);
}

export default OwnerAccountSetupWizard;
