import React from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import PageNotFoundImage from '../../../assets/images/404.png'
import { Link } from 'react-router-dom'


const PageNotFound = ({ history }) => {

	return (
		<MainLayout>
			<div className="page-not-found-custom-component">
				<div className="inner-container">
					<img src={PageNotFoundImage} alt='404'/>
						<h1>PAGE NOT FOUND</h1>
						<p>
							The requested page does not exist. Return to <Link to='/'>Home</Link> page
						</p>
				</div>
			</div>
		</MainLayout>
	);
}

export default PageNotFound;
