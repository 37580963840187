import React, { useState, useEffect } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import MainLayout from '../../layouts/MainLayout'
import Gallery from '../../common/Gallery'
import BookingCard from '../../common/BookingCard'
import MapContainer from '../../common/MapContainer'
import BoatOwnerDetailsCard from '../../common/BoatOwnerDetailsCard'
import BoatDetailsCard from '../../common/BoatDetailsCard'
import OperatorDetailCard from '../../common/OperatorDetailCard'
import ExperienceService from '../../../services/ExperienceService'
import Splash from '../Splash'
import Fade from 'react-reveal/Fade'
import './style.sass'
import { Button, Rate, Skeleton } from 'antd'
import BoatService from '../../../services/BoatService'
import FooterSmall from '../../common/FooterSmall'
import ExperienceCardSmall from '../../common/ExperienceCardSmall'
import StarReviews from '../../common/StarReviews'
import TextReviews from '../../common/TextReviews'
import AddReviewModal from '../../common/AddReviewModal'
import { useSelector } from 'react-redux'
import ReviewService from '../../../services/ReviewService'
import { getOverallRatingValue } from '../../../helpers/common'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const ExperienceDetail = ({ match, history }) => {
	const experienceId = match.params ? match.params.experienceId : null
	const [experience, setExperience] = useState(null)
	const [boatDetails, setBoatDetails] = useState(null)
	const [loading, setLoading] = useState(true)
	const [readmoreActive, setReadmoreActive] = useState(false)
	const [similarListings, setSimilarListings] = useState(null)
	const [loadingSimilarListings, setLoadingingSimilarListings] = useState(true)
	const [addReviewVisible, setAddReviewVisible] =  useState(false) 
	const [reviews, setReviews] = useState(null)
	const [loadingReviews, setLoadingReviews] = useState(true)
	const user = useSelector((state) => state.user.user);
	const { width } = useWindowDimensions()

	useEffect(() => {
		loadExperience()
  }, [experienceId])

  const loadExperience = () => {
		setLoading(true)
    ExperienceService.getExperienceById(experienceId).then((experienceRes) => {
			const fetchedExperience = experienceRes.data.result
			setExperience(fetchedExperience)

			// Load Boat Details
			BoatService.getBoatById(fetchedExperience.boatId).then((boatRes) => {
				const fetchedBoat = boatRes.data.result
				setBoatDetails(fetchedBoat)
				setLoading(false)  
			}).catch((err) => {
				console.log(err)
			});

			loadSimilarListings(fetchedExperience.address, fetchedExperience.location.lat, fetchedExperience.location.lng, fetchedExperience._id)
			loadReviews(fetchedExperience._id)
			
    }).catch((err) => {
      console.log(err)
    });
	}

	const loadSimilarListings = (address, lat, lng, expId) => {
		setLoadingingSimilarListings(true)

		ExperienceService.getExperiencesSearchResult(address, lat, lng).then((res) => {
			const fetchedExperiences = res.data.result
			const filteredFetchedExperiences =  fetchedExperiences.filter(e => e._id !== expId)
			setSimilarListings(filteredFetchedExperiences)
			setLoadingingSimilarListings(false)
		}).catch((err) => {
			console.log(err)
		});
	}

	const loadReviews = (expId) => {
		setLoadingReviews(true)

		ReviewService.getExperienceReviews(expId).then((res) => {
			const fetchedReviews = res.data.result
			setReviews(fetchedReviews)
			setLoadingReviews(false)
		}).catch((err) => {
			console.log(err)
		});
	}

	const onReviewAdded = (review) => {
		setReviews(prevReviews => ([
			review,
			...prevReviews,
		]))
	}

	const getCombinedImages = () => {
		if(Array.isArray(experience.images) && Array.isArray(boatDetails.images)) {
			return experience.images.concat(boatDetails.images)
		}
		return experience.images
	}

	const renderSimilarListingsCards = () => {
		const slicedExperiences = similarListings.sort(() => Math.random() - 0.5).slice(0, 6)

		return (slicedExperiences.length > 0 ? 
			slicedExperiences.map(exp => (
			<Col sm={6} md={6} lg={4} key={exp._id}>
				<Fade delay={0} duration={800}>
					<ExperienceCardSmall 
						experience={exp}
						onClick={() => { history.push(`/experience/detail/${exp._id}`)}}
					/>
				</Fade>
			</Col>
		)) : (
			<Col>
				<p>No Similar listings found!</p>
			</Col>
		))
	}

	return (
		<MainLayout>
			{loading || !experience || !boatDetails ? (
				<Splash />
			) : (
				<div className="experience-detail-component">
					<Gallery 
						images={getCombinedImages()}
					/>
					<Container>
						<Row>
							<Col 
								lg={{ span: 8, order: 'first' }}
								sm={{ span: 12, order: 'last' }} 
								xs={{ span: 12, order: 'last' }} 
							>
								{/* HEADING SECTION */}
								<h1>{experience.name}</h1>
								{reviews && reviews.length > 0 ? (
									<div>
										<Rate 
											style={{ color: '#fcc603'}} 
											value={getOverallRatingValue(reviews)}
											allowHalf
											disabled
										/>
										<p className="num-ratings-text"> 
											Rating {getOverallRatingValue(reviews)}/5 | {reviews.length} Reviews
										</p>
									</div>
								) : null}
								{experience.verified ? <span className="verified"><CheckCircleOutlined /> Verified</span> : <span className="not-verified"><CloseCircleOutlined /> Not Verified</span>}

								{/* DESCRIPTION SECTION */}
								{!experience.description || (
									<p className="description-text">
										{readmoreActive ? experience.description : experience.description.slice(0, 800)}
										{experience.description.length > 800 ? (
											<Button type="link" onClick={() => setReadmoreActive(!readmoreActive)}>
												{readmoreActive ? "Read Less >" : "Read More >"}
											</Button>
										) : null}
									</p>
								)}

								{/* BOOKING CARD SECTION FOR MOBILE */}
								{ width < 992 ? <BookingCard experience={experience} /> : null }

								{/* BOAT AND OWNER DETAILS SECTION */}
								<Row>
									<Col md={6}>
										<BoatDetailsCard 
											boat={boatDetails}
										/>
									</Col>
									<Col md={6}>
										<OperatorDetailCard 
											operatorOption={experience.operatorOption}
										/>
									</Col>
								</Row>
								<br />
								<BoatOwnerDetailsCard 
									name={`${boatDetails.userId.firstName} ${boatDetails.userId.lastName}`}
									description={boatDetails.userId.description}
									image={boatDetails.userId.image}
								/>

								{/* REVIEW SECTION */}
								<div className="review-section-heading-container">
									<h4 className="review-heading">Reviews from Other Guests</h4>
									{user && !loadingReviews ? (
										<Button type='primary' onClick={() => { setAddReviewVisible(true) }}>
											Add Review
										</Button>
									) : null} 
								</div>
								<AddReviewModal 
									visible={addReviewVisible}
									setVisible={setAddReviewVisible}
									experienceId={experienceId}
									onReviewAdded={onReviewAdded}
								/>
								{loadingReviews || !reviews ? (
									<Skeleton active /> 
								) : (
									reviews.length > 0 ? (
										<div>
											<StarReviews reviews={reviews} />
											<TextReviews reviews={reviews} />
										</div>
									) : (
										<p>No Reviews yet!</p>
									)
								)}

								{/* LOCATION SECTION */}
								<h4 className="sub-heading">Approximate Location</h4>
								<p>You will get the exact location once the booking is confirmed</p>
								<div className="experience-detail-map-container">
									<MapContainer 
										style={{
											height: 300,
											width: "100%"
										}}
										circles={[{radius: 800, center : {lat: experience.location.lat, lng: experience.location.lng}}]}
										initialCenter={{lat: experience.location.lat, lng: experience.location.lng}}
									/>
								</div>
		
								{/* CANCELLATION POLICY SECTION */}
								<h4 className="sub-heading">Cancellation Policy</h4>
								<p className="description-text">Full refund up to <b>5 days </b>prior to depature date.</p>

								{/* SIMILAR LISTINGS SECTION */}
								<div className="similar-listings-container">
									<h4 className="sub-heading">Similar Listings</h4>
									{loadingSimilarListings || !similarListings ? (
										<Skeleton active /> 
									) : (
										<Row>
											{renderSimilarListingsCards()}
										</Row>
									)}
								</div>
							</Col>
							<Col 
								lg={{ span: 4,order: 'last' }}
								sm={{ span: 12, order: 'first' }} 
								xs={{ span: 12, order: 'first' }} 
							>
								{/* BOOKING CARD SECTION - NOTE THAT IS COMPONENT IS REPEATED TO CHANGE POSITION IN MOBILE*/}
								{ width > 992 ? <BookingCard experience={experience} /> : null }
							</Col>
						</Row>
					</Container>
					<FooterSmall />
			</div>
			)}
		</MainLayout>
	);
}

export default ExperienceDetail;
