import React, { useEffect, useState } from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { Input, InputNumber, TimePicker, Checkbox, Select} from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import BoatService from '../../../../../services/BoatService';
import BoatActions from '../../../../../redux/actions/BoatActions';

const { Option } = Select;

const BoatDetailsForm = ({details, onChange}) => {
	const [loadingBoats, setLoadingBoats] = useState(false)
	const boats = useSelector((state) => state.boat.boats);
	const dispatch = useDispatch();
	const {name, description, boatId, groupSize, sailingDays, departureTime, numberOfHours} = details

	useEffect(() => {
		loadBoats()
	}, [])

	const loadBoats = () => {
		if (!boats) {
			setLoadingBoats(true)
			BoatService.getBoats().then((res) => {
				const userBoats = res.data.result
				dispatch(BoatActions.setBoats(userBoats))
				setLoadingBoats(false)  
			}).catch((err) => {
				console.log(err)
			});
		}
	}
	
	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	const daysCheckboxOptions = [
		{ label: 'M', value: 'Monday'},
		{ label: 'T', value: 'Tuesday' },
		{ label: 'W', value: 'Wednesday' },
		{ label: 'T', value: 'Thursday' },
		{ label: 'F', value: 'Friday' },
		{ label: 'S', value: 'Saturday' },
		{ label: 'S', value: 'Sunday' },
	];

	return (
		<div className="experience-details-form-component">
			<Container>
				<Row>
					<Col>
						<Input 
							placeholder="Experience Name" 
							onChange={(e) => onChangeHandler("name", e.target.value)}
							value={name}
						/>
						<Input.TextArea
							autoSize={{ minRows: 3, maxRows: 4 }}
							placeholder="Description"
							onChange={(e) => onChangeHandler("description", e.target.value)} 
							value={description}
						/>
						<Select 
							placeholder="Select Boat"
							onChange={(val) => onChangeHandler("boatId", val)} 
							loading={loadingBoats}
							disabled={loadingBoats || !boats}
							value={boatId ? boatId : undefined}
						>
							{!boats || boats.map(boat => <Option key={boat._id} value={boat._id}>{boat.name} - {boat.category}</Option>)}
						</Select>
						<InputNumber 
							placeholder="Group Size" 
							onChange={(val) => onChangeHandler("groupSize", val)} 
							value={groupSize}
							maxLength={5}
						/>
						<div className="sailing-days-container">
							<label className="main-label">Sailing Days</label>
							<Checkbox.Group
								options={daysCheckboxOptions} 
								onChange={(val) => onChangeHandler("sailingDays", val)} 
								value={sailingDays}
							/>
						</div>	
						<TimePicker 
							placeholder="Departure Time" 
							use12Hours 
							format="h:mm a" 
							onChange={(val) => onChangeHandler("departureTime", val)} 
							value={departureTime}
							showNow={false}
							minuteStep={10}
						/>					
						<InputNumber 
							placeholder="Number of Hours"
							onChange={(val) => onChangeHandler("numberOfHours", val)} 
							value={numberOfHours}
							maxLength={3}
							min={0}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default BoatDetailsForm;
