import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { Input } from 'antd'
import InputPhoneNumber from '../../../../common/InputPhoneNumber'


const SharingInstantBookingDetailsForm = ({details, onChange}) => {

	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	const { name, contactNumber, otherRequests } = details

	return (
		<div className="sharing-instant-booking-details-form-component">
			<Container>
				<Row>
					<Col>
						<Input 
							placeholder="Booking Name" 
							onChange={(e) => onChangeHandler("name", e.target.value)}
							value={name}
						/>
						<InputPhoneNumber
							placeholder="Contact Number (XXX XXX XXX)"
							value={contactNumber}
							onChange={(val) => onChangeHandler("contactNumber", val)} 
							countries={'all'}
						/>
						<Input.TextArea
							autoSize={{ minRows: 3, maxRows: 4 }}
							placeholder="Other Requests (Optional)"
							onChange={(e) => onChangeHandler("otherRequests", e.target.value)} 
							value={otherRequests}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default SharingInstantBookingDetailsForm;
