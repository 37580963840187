import React from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import HomeHeader from './HomeHeader'
import HomeSection1 from './HomeSection1'
import HomeSection2 from './HomeSection2'
import HomeSection3 from './HomeSection3'
import HomeSection4 from './HomeSection4'
import HomeSection5 from './HomeSection5'
import HomeFooter from './HomeFooter'
import LocationSearchBar from '../../common/LocationSearchBar'


const Home = ({history}) => {
	return (
		<MainLayout>
			<div className="home-component">
				{/* ============= Floating Search BAR ============= */}
				<LocationSearchBar history={history}/>

				{/* ============= HEADER SECTION ============= */}
				<HomeHeader />
				{/* ============= HOME SECTIONS ============= */}
				<HomeSection1 history={history}/>
				<HomeSection2 />
				<HomeSection3 />
				<HomeSection4 />
				<HomeSection5 />
				{/* ============= HOME FOOTER ============= */}
				<HomeFooter/>
			</div>
		</MainLayout>
	);
}

export default Home;
