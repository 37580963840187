import Amplify from 'aws-amplify';

export const PUBLIC_S3_BUCKET_URL = "https://theboatersclub-assets.s3.ap-southeast-2.amazonaws.com/public/"

export const configureAmplify = () => {
  Amplify.configure({
      Auth: {
          identityPoolId: 'ap-southeast-2:e35b4be9-677a-4ff2-b97f-9e5d70be38e7',
          region: 'ap-southeast-2',
          userPoolId: 'ap-southeast-2_tD7ixKPt7',
          userPoolWebClientId: '5olal0e9jr260f2m8nef4talel',
      },
      Storage: {
          AWSS3: {
              bucket: 'theboatersclub-assets',
              region: 'ap-southeast-2',
          }
      }
  });
}