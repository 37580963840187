import React, { useEffect } from 'react'
import './style.sass'
import { useDispatch } from 'react-redux'
import {Link} from 'react-router-dom'
import logo from '../../../assets/logos/logoWhite.png'
import GoogleButton from '../../common/GoogleButton'
import FacebookButton from '../../common/FacebookButton'
import {API_URL} from '../../../constants/ApiConstants'
import queryString from "query-string";
import Fade from 'react-reveal/Fade';

const SignIn = ({ history, location }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		// Saving the current 
		saveRedirectLocation()
		// Callback which trigger after succesful login
		onLoginCallback()
	}, [])

	const saveRedirectLocation = async () => {

		setTimeout(() => {
			const redirectLocation = location.state ? location.state.from.pathname : '/'
			const redirectState = location.state ? JSON.stringify(location.state.from.state) : null

			localStorage.setItem('redirectLocation', redirectLocation);
			localStorage.setItem('redirectState', redirectState);
		}, 400); // NOTE: Time out to avoid the redirectLocation from getting overridden
	};
	
	const getRedirectLocation = () => {
		return localStorage.getItem('redirectLocation');
	};

	const getRedirectState = () => {
		const redirectState = localStorage.getItem('redirectState')

		if (redirectState === "undefined" || !redirectState) {
			return null
		}
		return JSON.parse(redirectState);
	};

	const onLoginCallback = () => {
		// Getting query params from URL
		const query = queryString.parse(location.search);

		if (query.success === "true") {
			// Handle Redirect
			history.push(getRedirectLocation(), getRedirectState())
		}
	}

	return (
		<div className="sign-in-component">

			<div className="logo-container">
				<Link to="/">
					<img className="logo" src={logo} alt="logo"/>
				</Link>
			</div>
			<div className="center-container">
				<Fade delay={0} duration={500}>
					<div className="inner-container">
						<div className="header">
							<h6>SIGN IN</h6>
							<p>BOATERS CLUB</p>
						</div>
						<hr />
						<div className="body">
							<GoogleButton 
								url={`${API_URL}/auth/google`}
							/>
							<FacebookButton 
								url={`${API_URL}/auth/facebook`}
							/>
						</div>
					</div>
				</Fade>
			</div>
		</div>
	);
}

export default SignIn;
