import React from 'react'
import './style.sass'
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const FileUploadForm = ({files, filesKey, infoText, onChange, multiple = false, type, isEditMode}) => {

  const getAcceptType = () => {
    switch (type) {
      case 'image':
        return 'image/*';
      case 'pdf':
        return 'application/pdf';
      default:
        return '';
    }
  }
  const draggerProps = {
    name: 'file',
    multiple: multiple,
    accept: getAcceptType(),
    action: (file) => { 
      const selectedFiles = Array.isArray(files) ? [...files, file] : [file]
      onChange(filesKey, selectedFiles)      
    },
    onRemove: (file) => {
      if (Array.isArray(files)) {        
        const filteredFiles = files.filter(f => f.uid !== file.uid)
        const selectedFiles = filteredFiles.length > 0 ? filteredFiles : null
        onChange(filesKey, selectedFiles)     
      }
    },
    fileList: files ? files.map(file => {
      file.status = 'done'
      return file
    }) : null
  };

  return ( 
    <div className="file-upload-form-component">
      <h4>Drag and drop or click to add</h4>
      <p>{infoText}</p>

      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Support for a single or bulk upload</p>
      </Dragger> 
      {isEditMode ? (
        <div>
          <p className="note-text"><b>Note: </b>Current images/files will be overidden if you select new images/files!</p>
        </div>
      ): null}
    </div>
  );
}

export default FileUploadForm