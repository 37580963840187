import React, { useState, useEffect } from 'react';
import './style.sass';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const PrivacyPolicyBar = () => {
  const [isAccepted, setIsAccepted] = useState(true)
  
  useEffect(() => {
    const privacyPolicyisAccepted = localStorage.getItem("PrivacyPolicyisAccepted")
    if (!privacyPolicyisAccepted) {
      setIsAccepted(false)
    }
  }, [])

  const onBtnPressed = () => {
    setIsAccepted(true)
    localStorage.setItem("PrivacyPolicyisAccepted", true)
  }

  if (!isAccepted) {
    return (
      <div className="policy-bar-component">
        Please note that this website use cookies for necessary functionalities. By using our services, you accept our <Link to='/privacy' style={{ color: '#20b7da' }}>Privacy Policy</Link>
        <Button type="primary" className="understand-btn" onClick={onBtnPressed}>I Understand</Button>
      </div>
    )
  }

  return null;
}

export default PrivacyPolicyBar

