import {create} from 'axios';
import {API_URL} from '../constants/ApiConstants';

const API = create({
  baseURL: API_URL,
  timeout: 10000,
  withCredentials: true,
  headers: {},
});

API.interceptors.request.use(
  async (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => {
    if (response.data.state === false) {
      return Promise.reject(response.data.message);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default {
  API,
};
