import React, { useEffect, useState } from 'react'
import './style.sass'
import { useSelector, useDispatch } from 'react-redux'
import BookingService from '../../../../../services/BookingService'
import BookingActions from '../../../../../redux/actions/BookingActions'
import { Spin } from 'antd';
import ProfileBookingsCard from '../../../../common/ProfileCardsHorizontal/ProfileBookingsCard'
import moment from 'moment'
import Fade from 'react-reveal/Fade'

const OwnerCompletedBookings = () => {
  const [loading, setLoading] = useState(true)
  const bookings = useSelector((state) => state.booking.ownerBookings);
	const dispatch = useDispatch();

  useEffect(() => {
    loadBookings()
  }, [])

  const loadBookings = () => {
    setLoading(true)
    
    BookingService.getOwnerBookings().then((res) => {
      const fetchedOwnerBookings = res.data.result
      dispatch(BookingActions.setOwnerBookings(fetchedOwnerBookings))
      setLoading(false)  
    }).catch((err) => {
      console.log(err)
    });
  }

  // Filtering out completed bookings
  const getFilteredBookings = () => {
    return bookings.filter(b => {
      if (moment(b.departureDate).add(b.experience.numberOfHours).isBefore(moment())) {
        return true
      }
      return false
    })
  }

  return (
    <Fade delay={0} duration={1000}>
      <div className="profile-owner-completed-bookings-component">
        <div className="profile-bookings-header-container">
          <h3>Completed Bookings</h3>
        </div>
        <hr />
        {loading ? <Spin style={{padding: 10}} size='large' /> : (
          (bookings && getFilteredBookings().length > 0) ? (
            <div>
              {getFilteredBookings().map(booking => (
                <ProfileBookingsCard 
                  booking={booking}
                  key={booking._id}
                  isOwnerBooking={true}
                />
              ))}
            </div>
          ) : (
            <div className="empty-message-container">
              <h4>You have no completed bookings yet!</h4>
            </div>
          )
        )}
      </div>
    </Fade>
  )
}

export default OwnerCompletedBookings;
