import React from 'react'
import './style.sass'
import CustomButton from '../CustomButton';
import { Button, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Fade from 'react-reveal/Fade';

const WizardCard = ({mainText, subText, stepText, form, buttonText, onClick, buttonDisabled, onBackClick, loading}) => {
  if (loading) {
    return (
      <div className="wizard-card-span-component">
        <Spin size="large" style={{ marginTop: 8 }}/>
      </div>
    )
  }
  return ( 
    <Fade delay={0} duration={300}>
      <div className="wizard-card-component">
        <div className="header">
          <h5>{mainText}</h5>
          <p>{subText}</p>
        </div>
        <div className="sub-header">
          <p>{stepText}</p>
        </div>
        <div className="form-container">
          {form}
        </div>
        {
          !onClick || (
            <CustomButton 
              text={buttonText}
              style={{ width: "88%", marginTop: 10 }}
              onClick={onClick}
              disabled={buttonDisabled}
            />
          )
        }
        {!onBackClick || (
          <Button 
            style={{ width: "88%", marginTop: 5, border: 'none' }}
            onClick={onBackClick}
            icon={<ArrowLeftOutlined style={{fontSize: 15}}/>}
          >
            Back
          </Button>
        )}
      </div>
    </Fade>
  );
}

export default WizardCard