import React, {useEffect} from 'react'
import { Spin } from 'antd'
import './style.sass'

const Splash = () => {
	return (
		<div className="splash-component">
			<div className="spin-container">
				<Spin size="large"/> 
				<p className="spin-text">Loading, Please Wait...</p>
			</div>
		</div>
	);
}

export default Splash;
