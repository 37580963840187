import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { DatePicker, TimePicker } from 'antd'
import moment from 'moment'

const { RangePicker } = DatePicker;

const CustomBookingDatesForm = ({dates, experience, experienceBookedDates, onChange}) => {
	const { departureDate, departureTime, arrivalDate, arrivalTime,  } = dates

	const onChangeHandler = (dateRange) => {
		onChange("departureDate", dateRange[0])
		onChange("departureTime", dateRange[0])
		onChange("arrivalDate", dateRange[1])
		onChange("arrivalTime", dateRange[1])
	}

	const getRangePickerValue = () => {
		return [departureDate, arrivalDate]
	}

	const disabledDate = (current) => {

		// 1. Block Dates before current date and current date 
		if (current.isBefore(moment()) || current.isSame(moment())) {
			return true;
		}

		// 2. Block Dates which are already booked & Status === CONFIRMED AND Block NEXT Date(s) IF booked date + duration is after depatureTime
		const confirmedBookedDays = []

		experienceBookedDates.forEach(item => {
			if (item.status === "CONFIRMED") {
				const startDateTime = moment(item.departureDate) 
				const endDateTime = moment(startDateTime).add(24, 'h')

				while (startDateTime.isSameOrBefore(endDateTime)) {
					confirmedBookedDays.push(startDateTime.format('YYYY MM DD'))
					startDateTime.add(1, 'days');
				}
			}
		})	

		if (confirmedBookedDays.includes(current.format('YYYY MM DD'))) {
			return true;
		}

		// 3. Block Dates which are already booked & Status !== CONFIRMED & createdDate diff mins === 30mins
		const pendingBookedDays = experienceBookedDates.map(item => {
			return (item.status !== "CONFIRMED" && moment().diff(moment(item.createdDate), "minutes") < 30) ? (moment(item.departureDate).format('YYYY MM DD')) : null 
		})
	
		if (pendingBookedDays.includes(current.format('YYYY MM DD'))) {
			return true;
		}
		
		// Return FLASE if any of the above conditions are not true
    return false;
	}

	return (
		<div className="custom-booking-dates-form-component">
			<Container>
				<Row>
					<Col>
						<p>Pick <b>Departure Date/Time</b> and <b>Arrival Date/Time</b></p>
						<RangePicker
							placeholder={["Departure Date/Time", "Arrival Date/Time"]}
							showTime={{ format: 'hh:mm a' }}
							format="YYYY-MM-DD hh:mm a"
							minuteStep={10}
							onChange={(dateRange) => onChangeHandler(dateRange)}
							disabledDate={disabledDate}
							value={getRangePickerValue}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default CustomBookingDatesForm;
