import React from 'react'
import './style.sass'
import { Navbar, Nav, Dropdown} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../../../assets/logos/logoWhite.png'
import { useSelector, useDispatch } from 'react-redux'
import placeholderImg from '../../../assets/images/placeholder.png'
import UserActions from '../../../redux/actions/UserActions'
import { API_URL } from '../../../constants/ApiConstants'
import { HashLink } from 'react-router-hash-link';

const NavBar = () => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(UserActions.setUser(null))
    setTimeout(() => {
      window.open(`${API_URL}/auth/logout`, '_self');
    }, 200);
  }


  
  return (
    <div className="nav-bar-component">
      <Navbar className="nav-bar-custom" expand="lg">
        <div className="logo-container">
          <Link to="/">
            <img className="logo" src={logo} alt="logo"/>
          </Link>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end" style={{width: "100%"}}>
            <Nav.Link as={Link} to="/boat/add">
              Add Your Boat
            </Nav.Link>
            <Nav.Link as={HashLink} to="/#about">
              About
            </Nav.Link>
            {user ? (
              <React.Fragment>
                <Nav.Link as={Link} to="/profile/renter/bookings">
                  My Profile
                </Nav.Link>
                <Dropdown>
                  <Dropdown.Toggle variant="link" size="sm">
                    <img src={user.image ? user.image : placeholderImg} className='profile-image' alt='DP'/><span className="name-text">{`${user.firstName} ${user.lastName}`}</span> 
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item 
                      style={{ color: '#000 !important' }}
                      onClick={signOut}
                    >
                      Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </React.Fragment>
            ) : (
              <Nav.Link as={Link} className="specialBtn" to="/signin">
                SIGN IN
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default NavBar