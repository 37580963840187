import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import WizardCard from '../../common/WizardCard'
import BoatActions from '../../../redux/actions/BoatActions'
import BoatService from '../../../services/BoatService'
import { isOwnerAccountSetup, uploadToS3 } from '../../../helpers/common'
import OwnerAccountNotSetupCard from '../../common/OwnerAccountNotSetupCard'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BOAT_FEATURES } from '../../../constants/BoatConstants'

// Forms
import BoatCategoryForm from './Forms/BoatCategoryForm'
import BoatDetailsForm from './Forms/BoatDetailsForm'
import BoatFeaturesForm from './Forms/BoatFeaturesForm'
import FileUploadForm from '../../common/FileUploadForm'
import WizardCompletionCard from '../../common/WizardCompletionCard'

const { confirm } = Modal;

const AddBoatWizard = ({ history }) => {

	const boat = history.location.state ?  history.location.state.boat : null // FOR EDIT MODE
	const user = useSelector((state) => state.user.user);
	const [wizardIndex, setWizardIndex] = useState(0)
	const [processing, setProcessing] = useState(false)
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		category: null,
		name: null,
		registrationNumber: null,
		brand: null,
		model: null,
		yearOfManufacture: null,
		length: null,
		maxCapacity: null,
		features: null,
		otherFeatures: null,
		images: null,
		boatInsurance: null,
	}) 

	useEffect(() => {
		// FOR EDIT MODE
		setFormatedFromData()
	}, [])

	// FOR EDIT MODE
	const setFormatedFromData = () => {
		if(boat) {
			let features = []
			let otherFeatures = []

			boat.features.forEach(feature => {
				if(BOAT_FEATURES.some(bf => bf.name === feature)) {
					features.push(feature)
				} else {
					otherFeatures.push(feature)
				}
			});

			setFormData({
				...boat,
				features: features,
				otherFeatures: otherFeatures.join(','),
				images: null,
				boatInsurance: null,
			})
		}
	}

	const onChangeHandler = (key, value) => {
		setFormData(formData => ({
			...formData,
			[key]: value
		}))
	}

	const onNextHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex + 1)
		}, 200);
	}

	const onBackHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex - 1)
		}, 200);
	}

	const getRequestPayload = async () => {
		const {features, otherFeatures, images, boatInsurance} = formData

		let allFeatures = []
		if (features) allFeatures = allFeatures.concat(features)
		if (otherFeatures) allFeatures = allFeatures.concat(otherFeatures.split(',').filter((ele) => ele.length !== 0).map(item => item.trim()))

		const imagesUploadResult = (boat && !images) ? boat.images : await uploadToS3(images, 'owner/boat/images/', 'image') // NOTE (boat && !images) condition is to for checking EDIT mode and if images are available
		const imagesUrls = (boat && !images) ? imagesUploadResult : imagesUploadResult.map(item => item.key)

		const boatInsuranceUploadResult = (boat && !boatInsurance) ? boat.boatInsurance : await uploadToS3(boatInsurance, 'owner/boat/insurance/');
		const boatInsuranceUrls = (boat && !boatInsurance) ? boatInsuranceUploadResult : boatInsuranceUploadResult.map(item => item.key)
		
		return {
			...formData,
			features: allFeatures,
			images: imagesUrls,
			boatInsurance: boatInsuranceUrls
		}		
	}

	const onSubmitHandler = async () => {
		confirm({
			title: 'Do you want to submit the details?',
			icon: <ExclamationCircleOutlined />,
			centered: true,
			onOk : async (close) => {
				close()
				// =========================================================
				setProcessing(true)
				const payload = await getRequestPayload() 
				
				if (!boat) {
					BoatService.addBoat(payload).then((res) => {
						const boat = res.data.result
						dispatch(BoatActions.addBoat(boat))
						setProcessing(false)
						setWizardIndex(wizardIndex => wizardIndex + 1)
					}).catch((err) => {
						setProcessing(false)
					});
				} else {
					// FOR EDIT MODE
					BoatService.editBoat(boat._id, payload).then((res) => {
						const boat = res.data.result
						dispatch(BoatActions.editBoat(boat))
						setProcessing(false)
						setWizardIndex(wizardIndex => wizardIndex + 1)
					}).catch((err) => {
						setProcessing(false)
					});
				}
				// =========================================================
			}
		});
	}

	const onCompleteHandler = () => {
		history.push('/profile/owner/boats')
	}

	const renderWizardCards = () => {
		const {
			category, 
			name, 
			registrationNumber,
			brand, 
			model, 
			yearOfManufacture, 
			length, 
			maxCapacity, 
			features, 
			otherFeatures, 
			images,
			boatInsurance
		} = formData
		
		const mainText = !boat ? "JOIN THE BOATERS CLUB!" : "EDIT BOAT"
		const subText = !boat ? "Add your boat to our platform and make the most out of it!" : "Please note that the changes will reflect in the experiences related to this boat!"
		
		switch (wizardIndex) {
			case 0:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 1 : Select your Boat Category"
						form={<BoatCategoryForm onChange={onChangeHandler} category={category}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						buttonDisabled={!category}
						loading={processing}
					/>
				)
			case 1:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}	
						stepText="Step 2 : Enter Your Boat Details"
						form={<BoatDetailsForm onChange={onChangeHandler} details={{name, registrationNumber, brand, model, yearOfManufacture, length, maxCapacity}}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(name && brand && yearOfManufacture && maxCapacity)}
						loading={processing}
					/>
				)	
			case 2:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 3 : Select/Enter Your Boat Features"
						form={<BoatFeaturesForm onChange={onChangeHandler} features={features} otherFeatures={otherFeatures}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={
							(!features || !(features && features.length > 0)) && !otherFeatures
						}
						loading={processing}
					/>
				)	
			case 3:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 4 : Upload Boat Insurance"
						form={<FileUploadForm 
							files={boatInsurance} 
							onChange={onChangeHandler} 
							filesKey='boatInsurance'
							infoText="Tip : Make sure to upload a clear document!"
							isEditMode={boat ? true : null}
							/>
						}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!boatInsurance && !boat} 
						loading={processing}
					/>
				)	
			case 4:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 5 : Enter Your Boat Photos"
						form={<FileUploadForm 
							files={images}
							infoText="Tip : Make sure to have a good selection of high quality photos including the interior and exterior of your boat"
							type='image'
							onChange={onChangeHandler}
							filesKey='images'
							multiple={true}
							isEditMode={boat ? true : null}
						/>}
						buttonText="SUBMIT"
						onClick={onSubmitHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!images && !boat} 
						loading={processing}
					/>
				)			
			case 5:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText={boat ? "Boat Edited" : "Boat Added"}
						form={(
							<WizardCompletionCard
								mainText={boat ? "Successfully Edited Boat!" : "Welcome to TheBoatersClub!"}
								subText={boat ? "Your boat has been edited successfully. Go to your profile to view it." : "Your boat has now been added. Go to your profile to view it. You can now create an awesome experience with it!"}
							/>
						)}
						buttonText="GO TO MY PROFILE"
						onClick={onCompleteHandler}
					/>
				)	
			default:
				return null
		}
	}
	
	return (
		<MainLayout>
			<div className="add-boat-wizard-component">
				<div className="center-container">
					{isOwnerAccountSetup(user) ? renderWizardCards() : <OwnerAccountNotSetupCard />}
				</div>
			</div>
		</MainLayout>
	);
}

export default AddBoatWizard;
