import {
  SET_USER_LOADING,
  SET_USER
} from '../actions/ActionTypes';

const defaultState = {
  loading: true,
  user: null
};

const UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
};

export default UserReducer;