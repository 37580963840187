import {
  SET_RENTER_BOOKINGS,
  SET_OWNER_BOOKINGS,
  SET_RENTER_CUSTOM_BOOKING_REQUESTS,
  SET_OWNER_CUSTOM_BOOKING_REQUESTS,
  UPDATE_OWNER_CUSTOM_BOOKING_REQUEST,
  UPDATE_RENTER_CUSTOM_BOOKING_REQUEST
} from './ActionTypes';

export const setRenterBookings = (payload) => ({  
  type: SET_RENTER_BOOKINGS,
  payload
});

export const setOwnerBookings = (payload) => ({  
  type: SET_OWNER_BOOKINGS,
  payload
});

export const setRenterCustomBookingRequests = (payload) => ({  
  type: SET_RENTER_CUSTOM_BOOKING_REQUESTS,
  payload
});

export const setOwnerCustomBookingRequests = (payload) => ({  
  type: SET_OWNER_CUSTOM_BOOKING_REQUESTS,
  payload
});

export const updateOwnerCustomBookingRequest = (payload) => ({  
  type: UPDATE_OWNER_CUSTOM_BOOKING_REQUEST,
  payload
});

export const updateRenterCustomBookingRequest = (payload) => ({  
  type: UPDATE_RENTER_CUSTOM_BOOKING_REQUEST,
  payload
});

export default {
  setRenterBookings,
  setOwnerBookings,
  setRenterCustomBookingRequests,
  setOwnerCustomBookingRequests,
  updateOwnerCustomBookingRequest,
  updateRenterCustomBookingRequest
};