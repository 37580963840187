import React from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import FooterSmall from '../../common/FooterSmall'
import { Container } from 'react-bootstrap'
import { Row, Col } from 'antd'

const TermsAndConditions = () => {
	return (
		<MainLayout>
			<div className="terms-and-conditions-component">
				<Container>
					<Row>
						<Col>
							<div className="header-container">
								<h1><b>Terms</b> and <b>Conditions</b></h1>
								<hr/>
							</div>
							
							<div className="content-container">
								<p>Welcome to The Boaters Club, Australia’s number 1 boat sharing platform. We specialise in helping you find boating experiences to suit any budget, size or location! 
								These terms and conditions (Terms) are entered into between THE BOATERS CLUB PTY LTD ABN 32 641 564 918 (we, us or our) and you, together the Parties and each a Party. These Terms supplement and incorporate our privacy policy, website terms of use and any guidelines and codes of conduct posted on the Platform.
								We provide a platform where boat, vessel or maritime craft (Vessels) owners or leaseholders (Boat Owners) and people looking to hire or for an experience on a Vessel (Customers) can connect and transact (Platform). The Platform is available at www.theboatersclub.com and via other channels or addresses including our mobile application.
								Vessels for hire are as set out on the Platform, and may include small boats to large yachts, all types of boating experiences such as jet skis, scuba diving, fishing and other water sports and Boat Owners may supply crew, equipment and any other relevant inclusions.  
								In these Terms, you means (as applicable) (1) the person or entity registered with us as either a Customer or Boat Owner; or (2) the individual accessing or using the Platform.
								If you are using the Platform on behalf of your employer or a business entity, you, in your individual capacity, represent and warrant that you are authorised to act on behalf of your employer or the business entity and to bind the entity and the entity’s personnel to these Terms.
								</p>

								<h3>Acceptance</h3>
								<p>You accept these Terms by checking the box, clicking “I accept” (or similar), registering on the Platform or using the Platform or the services. You must be 18 years old to use the Platform. 
								We may amend these Terms at any time, by providing written notice to you. By clicking “I accept” (or similar) or continuing to use the Platform after the notice or 30 days after notification (whichever date is earlier), you agree to the amended terms. If you do not agree to the amendment, you may terminate these Terms in accordance with the Termination clause.
								If you access or download our mobile application from (1) the Apple App Store, you agree to any Usage Rules set forth in the App Store Terms of Service or (2) the Google Play Store, you agree to the Android, Google Inc. Terms and Conditions including the Google Apps Terms of Service.
								We may use Google Maps/Earth mapping services, including Google Maps API(s). Your use of Google Maps/Earth is subject to the Google Maps/Google Earth Additional Terms of Service.
								</p>

								<h3>Platform summary</h3>
								<p>The Platform is an online marketplace where Customers and Boat Owners can find each other and arrange, for a fee, the hiring of Vessels or an experience on a Vessel. We provide the Platform to users (including hosting and maintaining the Platform), assist Customers and Boat Owners to form contracts for the supply of Vessels or services, process payments between Customers and Boat Owners and provide promotional opportunities for Boat Owners (together The Boaters Club Services). You understand and agree that we only make available the Platform and The Boaters Club Services. We are not party to any agreement entered into between a Customer and a Boat Owner and we have no control over the conduct of Boat Owners, Customers or any other users of the Platform. 
								Customers and Boat Owners may enter into written agreements in relation to the services. To the extent there is inconsistency between any additional terms and conditions and these Terms, these Terms will prevail.
								A Boat Owner wanting to provide services or hire out a Vessel creates an Account on the Platform (in accordance with the Account clause below) and posts an accurate and complete description of the Vessel or services they can provide including details of the Vessel, any inclusions such as crew or equipment, the capacity of the Vessel, the availability of the Vessel or experience and other relevant information (Boat Owner Listing). Boat Owners may include in their Boat Owner Listing whether Customers can make instant bookings (Instant Bookings) or whether the Customer must make a booking request through the Platform which is an offer from the Customer to the Boat Owner to book the Vessel or experience described in the Boat Owner Listing (Booking Request).
								A Customer wanting to hire a Vessel or purchase an experience on a Vessel creates an Account on the Platform to view and browse matched Boat Owner Listings.
								If a Customer selects an Instant Booking or the Boat Owner accepts a Booking Request through the Platform, it becomes a Booking. 
								Boat Owners must include all additional terms and conditions relating to the hire of their Vessel or the experience in the relevant Boat Owner Listing or must clearly state that there are additional terms and conditions. By sending a Booking Request, a Customer is accepting the additional terms and conditions of the relevant Boat Owner. 
								</p>

								<h3>Accounts</h3>
								<p>You may browse Boat Owner Listing without an Account, however you must register on the Platform and create an account (Account) to access most of the Platform’s features (such as making a Booking Request as a Customer or creating a Boat Owner Listing as a Boat Owner).
								You must register for an Account using your Facebook or Google account (Social Media Account). If you sign in to your Account using your Social Media Account, you authorise us to access certain information on your Social Media Account including but not limited to your current profile photo and other basic information.
								You agree to provide accurate, current and complete information during the registration process and regularly update such information to keep it accurate, current and complete. 
								Your Account is personal and you must not transfer it to others, except with our written permission. 
								You are responsible for keeping your Account details and your username and password confidential and you will be liable for all activity on your Account, including purchases made using your Account details. You agree to immediately notify us of any unauthorised use of your Account.
								If you are a Boat Owner, we will review your request for an Account before approving the request. You must complete the online form which includes details such as your boat registration, your insurance, your licences, your experience and you must upload photos of the Vessel or experience you plan to provide. We may request additional information in order to evaluate your request to be Boat Owner on the Platform. If you do not provide us with information we reasonably request, we may refuse to create an Account for you. If you provide us with any information which indicates you are not a fit and proper person to be provided with an Account, we may refuse to provide you with an Account, in our sole discretion. If your request for an Account is approved by us, your profile page will be visible to other users, setting out photos, your experience, the availability of your Vessel or experience and any inclusions such as crew or equipment. By requesting an Account, the Boat Owner confirms that it is legally entitled to and capable of supplying the Vessel, experience or services to be described in the Boat Owner Listing.
								We may make access to and use of the Platform subject to conditions or requirements, including identity verification, cancellation history, quality of the services or threshold of reviews.
								</p>

								<h3>Boat Owner Listings (applicable only to Boat Owners)</h3>
								<p>You must ensure that any information you supply about the Vessels or experiences in your Boat Owner Listing/s is accurate, complete, reliable, up-to-date and suitable for any particular purpose. It is your responsibility to maintain the Boat Owner Listing and your calendar availability for the Boat Owner Listing.
								You are solely responsible for setting a price for your Boat Owner Listing and once a Customer makes a Booking Request or an Instant Booking, you may not request that the Customer pays a higher price than in the Boat Owner Listing.
								You are responsible for setting your calendar availability for your Boat Owner Listing and once you have accepted a Customer’s Booking Request or Instant Booking you may not change the availability for the Booking dates. 
								You must supply an adequate number and detail of photographs of your Vessel including internal and external photographs as part of the Boat Owner Listing, as set out on the Platform. These photos must have been taken less than 12 months ago. If there is a material change to your Vessel you will be required to supply up-to-date photographs which show the material change. 
								You must familiarise Customers with your Vessel (if applicable) by ensuring they have all the important information about your Vessel, including safety information.
								It is your responsibility to ensure you have in place at all times during the Boat Owner Listing of your Vessel or experience all the correct and necessary insurances, permits and licences as required by any laws or regulations which may apply to your Vessel or experience. The insurance policy you have in place must be a comprehensive insurance policy that is relevant to and covers your particular Vessel or experience. 
								It is your responsibility to ensure your Vessel or experience has all the required safety equipment as required by any applicable laws or regulations.
								At our absolute discretion we may choose how and when to display Boat Owner Listings on the Platform. 
								</p>

								<h3>Customer requirements</h3>
								<p>A Customer must only use a Vessel as directed by the Boat Owner.
								A Customer must ensure they are legally authorised under the applicable laws and regulations in their jurisdiction to operate the Vessel and that they are sufficiently skilled to operate the Vessel. We do not check any such licences or authorisations. 
								The Customer must ensure any other guest users or passengers on a Vessel meet the Boat Owner’s requirements and are aware of and agree to these Terms. Only the Customer themselves may operate the Vessel unless the Owner directly authorises specific other people to operate the Vessel and they are also legally authorised under the applicable laws and regulations in their jurisdiction to operate the Vessel.
								The Customer must return the Vessel in the same condition as it was received (including fuel levels if applicable) and the Customer will incur any costs associated with meeting this obligation (other than natural wear and tear).
								</p>

								<h3>Promotional Opportunities</h3>
								<p>As a Boat Owner you may also choose to purchase promotional opportunities (Promotional Opportunity). Promotional Opportunities are subject to the fees and the terms and conditions as set out in any Promotional Opportunity offer and displayed on the Platform or otherwise communicated to you. Payment for any Promotional Opportunity must be made in advance. To the extent permitted by law, we do not make any representations, warranties or guarantees that any Promotional Opportunity will be fit for any particular purpose, will achieve any specified result, or will provide any benefit. In the event of any conflict between any Promotional Opportunity terms and conditions and these Terms, the Promotional Opportunity terms and conditions will prevail. </p>

								<h3>Communication</h3>
								<p>We may contact you via the Platform using in-Account notifications, or via off-Platform communication channels, such as text message or email.
								Customers and Boat Owners can communicate offline using the listed contact details once a Booking has been made, but must not communicate outside of the Platform until a Booking has been made. Customers and Boat Owners must not use the contact details to organise the hire of Vessels or purchase of experiences off the Platform. 
								</p>

								<h3>Payment</h3>
								<p>It is free to register an Account on the Platform, for Boat Owners to create Boat Owner Listings, or for other users to review content on the Platform, including Boat Owner Listings.
								As a Customer, you agree to pay the relevant fees set out in the Boat Owner Listing (Listing Fees) at the time you make the Booking Request or make an Instant Booking.
								Customers and Boat Owners may use the Platform functionality to propose new Listing Fees for experiences. If both the Customer and Boat Owner confirm the same amount, this will become the Listing Fee. 
								If Boat Owners wish to charge a deposit or bond, the payment and any refunds of such amounts is between Boat Owners and Customers, and we have no involvement in such transactions. 
								In consideration for providing the Platform, we will charge the Boat Owner a service fee which is a percentage of all Listing Fees paid by Customers (Service Fee). The Service Fee is set out in the Boat Owner Account. 
								Applicable to Boat Owners only: If you are a Boat Owner, you appoint us as your limited payment collection agent solely for the purpose of accepting the Listing Fees from the relevant Customer. You agree that we will not be required to pay you any amount until we have received the Listing Fees in full from the relevant Customer. We will hold the Listing Fees until the Booking has been completed as notified by the Customer. We will deduct our Service Fee from all Listing Fees and then we will pay the remainder of the Listing Fees to the Boat Owner on a weekly basis for all Bookings that have taken place that week. If a Customer is entitled to a refund of Listing Fees in accordance with these Terms, you agree that we will refund the Customer and that you will not be paid the relevant Listing Fees.
								</p>

								<h3>General</h3>
								<p>We may pre-authorise or charge your payment method for a nominal amount to verify the payment method. 
								We provide a number of payment methods on the Platform, including our third party payment processor, currently credit card or PayPal. The payment method you choose may be subject to additional terms and conditions imposed by the applicable third party payment processor. By making payment through a third party payment processor, you accept the applicable terms and conditions.
								In the absence of fraud or mistake, all payments made are final. If you make a payment by debit card or credit card, you warrant that the information you provide to us is true and complete, that you are authorised to use the debit card or credit card to make the payment, that your payment will be honoured by your card issuer, and that you will maintain sufficient funds in your account to cover the payment.
								</p>

								<h3>Refunds and Cancellation Policy</h3>
								<p>The cancellation or refund of any services ordered on this Platform is strictly a matter between the relevant Customer and Boat Owner, subject to the rest of this clause. The terms and conditions agreed to between the Boat Owner and the Customer must be set out clearly in the relevant Boat Owner Listing.
								If (1) Customer and Boat Owner mutually agree to cancel a Booking; or (2) following reasonable attempts by a Customer to contact a Boat Owner for the Boat Owner to fulfil the Booking, the Booking is cancelled (including where the Boat Owner does not show up at the correct time and location, if applicable); and (3) we are satisfied that the Listing Fees should be returned to the Customer, we will return the Listing Fees to the Customer (less any third party payment provider fees), provided that the Listing Fees have not yet been paid to the Boat Owner. 
								For disputes between Customers and Boat Owners, we encourage Parties to attempt to resolve disputes (including claims for returns or refunds) with the other Party directly and in good faith. In the event that a dispute cannot be resolved through these means, the Parties may choose to resolve the dispute in any manner agreed between the Parties or otherwise in accordance with applicable laws. 
								</p>

								<h3>Ratings</h3>
								<p>Customers may provide a star rating in relation to their experience with the Boat Owner on the Platform (Rating). Ratings can be viewed by any user.</p>

								<h3>Content</h3>
								<p>We may allow you to (1) post, upload, publish, send or receive relevant content and information (User Content) on or through our Platform; and/or (2) access and view User Content and the content and information we make available on the Platform (The Boaters Club Content and together with User Content, Content).
								Unless otherwise indicated, we own or licence all rights, title and interest (including Intellectual Property Rights) in our Platform and all of the Content. Your use of our Platform and your use of and access to any Content does not grant or transfer to you any rights, title or interest in relation to our Platform or the Content.
								You must not, without the prior written consent of ourselves or the owner of the Content (as applicable) or except as expressly permitted by these Terms, (1) copy or use, in whole or in part, any Content; (2) reproduce, reverse engineer, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content to any third party; or (3) breach any Intellectual Property Rights connected with our Platform, including by altering or modifying any of the Content, causing any of the Content to be framed or embedded in another website, or creating derivative works from the Content.
								Subject to your compliance with these Terms, we grant you a personal, non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to use our Platform and access and view any Content solely for your personal and non-commercial use, in accordance with these Terms.  All other uses are prohibited without our prior written consent.
								You grant us a non-exclusive, perpetual, irrevocable, worldwide, royalty free, sublicensable and transferable right and licence to use, view, distribute, communicate, copy, store, modify and exploit in any manner the User Content to provide the Platform and promote the Platform in any media or promotional material.
								You agree that you are solely responsible for all User Content that you make available on or through our Platform.  You represent and warrant that (1) you are either the sole and exclusive owner of all User Content or you have all rights, licences, consents and releases that are necessary to grant to us the rights in such User Content as contemplated by these Terms; and (2) neither the User Content nor the posting, uploading, publication, sending or receiving  of the User Content or our use of the User Content on, through or by means of our Platform will infringe, misappropriate or violate a third party’s Intellectual Property Rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
								Despite anything to the contrary, to the maximum extent permitted by law, you agree to indemnify us and hold us harmless in respect of any Liability that we may suffer, incur or otherwise become liable for, arising from or in connection with the User Content you make available on or through the Platform, including as a result of an Intellectual Property Breach. 
								The Content is not comprehensive and is for general information purposes only.  It does not take into account your specific needs, objectives or circumstances, and is not advice.  While we use reasonable attempts to ensure the accuracy and completeness of the Content, to the extent permitted by law, we make no representation or warranty regarding the Content. The Content is subject to change without notice. We do not undertake to keep our Platform up-to-date and we are not liable if any Content is inaccurate or out-of-date.
								</p>

								<h3>Warranties</h3>
								<p>You represent, warrant and agree that:
								(a)	you will not use our Platform, including the Content, in any way that competes with our business;
								(b)	there are no legal restrictions preventing you from entering into these Terms;  
								(c)	all information and documentation that you provide to us in connection with these Terms is true, correct and complete;  
								(d)	you have not relied on any representations or warranties made by us in relation to the Platform (including as to whether the Platform is or will be fit or suitable for your particular purposes), unless expressly stipulated in these Terms;
								(e)	you will be responsible for the use of any part of the Platform, and you must ensure that no person uses any part of the Platform: (1) to break any law or infringe any person’s rights (including Intellectual Property Rights) (2) to transmit, publish or communicate material that is defamatory, offensive, abusive, indecent, menacing or unwanted; or (3) in any way that damages, interferes with or interrupts the supply of the Platform; 
								(f)	where you are a Boat Owner, you are responsible for complying with all laws, rules and regulations which apply to providing the Vessel or experience in your Boat Owner Listings; and
								(g)	where you are a Boat Owner, you are appropriately qualified, and have any required skills, knowledge or training, to provide the services you offer in Listings.
								</p>

								<h3>Prohibited conduct</h3>
								<p>You must not do, or attempt to do, anything that is unlawful; anything prohibited by any laws which apply to our Platform or which apply to you or your use of our Platform; anything which we would consider inappropriate; or anything which might bring us or our Platform into disrepute, including (without limitation):
								(a)	anything that would constitute a breach of an individual’s privacy (including uploading private or personal information without an individual's consent) or any other legal rights;
								(b)	using a Boat Owner’s Vessel in an offensive or irresponsible manner;
								(c)	making any alterations to a Boat Owner’s Vessel;
								(d)	using our Platform to defame, harass, threaten, menace or offend any person;
								(e)	interfering with any user using our Platform;
								(f)	tampering with or modifying our Platform, knowingly transmitting viruses or other disabling features, or damaging or interfering with our Platform, including (without limitation) using trojan horses, viruses or piracy or programming routines that may damage or interfere with our Platform;
								(g)	anything that might violate any local, state, national or other law or regulation or any order of a court, including, without limitation, zoning and tax regulations, maritime regulations or laws, and any other specific laws or regulations applicable to a particular Vessel;
								(h)	using our Platform to find a Customer or Boat Owner and then completing a Booking or transaction independent of our Platform in order to circumvent the obligation to pay any fees related to our provision of the Platform;
								(i)	as a Boat Owner, listing your Vessel as available via another service after you have accepted a Booking Request or Instant Booking for your Vessel via our Platform;
								(j)	as a Boat Owner, offering any Vessel or experience that you do not intend to provide or cannot provide, including accepting Booking Requests or Instant Bookings you do not intend to honour;
								(k)	as a Customer, making any Booking Requests or Instant Bookings to Boat Owners that you do not intend to honour;
								(l)	using our Platform to send unsolicited email messages; or
								(m)	facilitating or assisting a third party to do any of the above acts.
								</p>

								<h3>Australian Consumer Law </h3>
								<p>Certain legislation, including the Australian Consumer Law (ACL) in the Competition and Consumer Act 2010 (Cth), and similar consumer protection laws and regulations, may confer you with rights, warranties, guarantees and remedies relating to the provision of the Platform by us to you which cannot be excluded, restricted or modified (Statutory Rights).If the ACL applies to you as a consumer, nothing in these Terms excludes your Statutory Rights as a consumer under the ACL. You agree that our Liability for the Platform provided to an entity defined as a consumer under the ACL is governed solely by the ACL and these Terms.  
								Subject to your Statutory Rights, we exclude all express and implied warranties, and all material, work and services (including the Platform) are provided to you without warranties of any kind, either express or implied, whether in statute, at law or on any other basis.
								As a Customer, the goods and services provided by a Boat Owner may also confer on you certain rights under the ACL.
								This clause will survive the termination or expiry of these Terms.
								</p>

								<h3>Exclusions to liability</h3>
								<p>Despite anything to the contrary, to the maximum extent permitted by law, we will not be liable for, and you waive and release us from and against, any Liability caused or contributed to by, arising from or connected with:  
								(a)	your acts or omissions;  
								(b)	any use or application of The Boaters Club Services by a person or entity other than you, or other than as reasonably contemplated by these Terms;  
								(c)	any aspect of the Customer and Boat Owner interaction including the services offered by the Boat Owner, the description of the services requested or offered, any advice provided, the performance of services or supply and delivery of a Vessel by the Boat Owner;
								(d)	any works, services, goods, materials or items which do not form part of The Boaters Club Services (as expressed in these Terms), or which have not been provided by us;  
								(e)	any third parties or any goods and services provided by third parties, including customers, end users, suppliers, transportation or logistics providers or other subcontractors which the provision of the Platform may be contingent on, or impacted by; or
								(f)	any event outside of our reasonable control.
								This clause will survive the termination or expiry of these Terms.
								</p>

								<h3>Limitations on liability</h3>
								<p>Despite anything to the contrary, to the maximum extent permitted by law:  
								(a)	we will not be liable for Consequential Loss; 
								(b)	each Party’s liability for any Liability under these Terms will be reduced proportionately to the extent the relevant Liability was caused or contributed to by the acts or omissions of the other Party; and
								(c)	our aggregate liability for any Liability arising from or in connection with these Terms will be limited to us resupplying The Boaters Club Services to you or, in our sole discretion, to us repaying you the amount of the Service Fees paid by you to us in respect of the supply of The Boaters Club Services to which the Liability relates, or where there are no Service Fees paid, $100.  
								This clause will survive the termination or expiry of these Terms.
								</p>

								<h3>Termination</h3>
								<p>Your Account and these Terms may be terminated by you at any time, using the ‘cancel Account’ functionality (or similar) in your in the Account page section of your Account settings.
								We may terminate these Terms at any time by giving 30 days’ written notice to you (Termination for Convenience).
								We may suspend your Account or terminate these Terms immediately upon written notice to you, if:
								(a)	you breach any provision of these Terms and that breach has not been remedied within 10 business days of being notified by us; 
								(b)	as a Boat Owner you repeatedly receive low Ratings;
								(c)	there is any reason outside our control which has the effect of compromising our ability to provide The Boaters Club Services; or
								(d)	you are unable to pay your debts as they fall due.
								These Terms will terminate immediately upon written notice by you, if we:
								(a)	are in breach of a material term of these Terms, and that breach has not been remedied within 10 business days of being notified by you; or
								(b)	are unable to pay our debts as they fall due.  
								Upon expiry or termination of these Terms:
								(a)	we will remove your access to the Platform;
								(b)	we will immediately cease providing The Boaters Club Services;
								(c)	you agree that other than where termination is due to our Termination for Convenience any payments made by you to us are not refundable to you; and
								(d)	where you are a Boat Owner, we will cancel any existing Bookings and refund the relevant Customers. Where you are a Customer, you will lose any Listing Fees and other amounts paid where the termination is due to your fault.
								Termination of these Terms will not affect any rights or liabilities that a Party has accrued under it.  
								This clause will survive the termination or expiry of these Terms.
								</p>

								<h3>Boat Owner insurance</h3>
								<p>As a Boat Owner, we may request that you provide evidence of your insurance. Where we do so, we are not confirming that the insurance you have is sufficient or suitable for the services you choose to provide to Customers. If we do not ask you to provide evidence of insurance this does not indicate that we believe you do not require insurance. You acknowledge and agree it is your responsibility to make your own investigations and receive professional advice on the insurance you require. </p>

								<h3>Notice regarding Apple</h3>
								<p>To the extent that you are using or accessing our Platform on an iOS device, you further acknowledge and agree to the terms of this clause. You acknowledge that these Terms are between you and us only, not with Apple Inc. (Apple), and Apple is not responsible for the Platform and any content available on the Platform.
								Apple has no obligation to furnish you with any maintenance and support services with respect to our Platform. 
								If our mobile application fails to conform to any applicable warranty, you may notify Apple and Apple will refund the purchase price of the mobile application to you. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the mobile application and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be our responsibility.
								Apple is not responsible for addressing any claims by you or any third party relating to our mobile application or your use of our mobile application, including but not limited to (1) product liability claims; (2) any claim that our mobile application fails to conform to any applicable legal or regulatory requirement; and (3) claims arising under consumer protection or similar legislation.
								Apple is not responsible for the investigation, defence, settlement and discharge of any third-party claim that our mobile application infringes that third party’s intellectual property rights. 
								You agree to comply with any applicable third-party terms when using our mobile application, including any Usage Rules set forth in the Apple App Store Agreement of Service. 
								Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary of these Terms.
								You hereby represent and warrant that (1) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and (2) you are not listed on any U.S. Government list of prohibited or restricted parties.
								</p>

								<h3>General</h3>
								<p>Assignment: You must not assign or deal with the whole or any part of your rights or obligations under these Terms without our prior written consent.
								Disputes:  In relation to a dispute, controversy or claim arising from, or in connection with, these Terms (including any question regarding its existence, validity or termination) (Dispute) between a Customer and us, or a Boat Owner and us, a Party may not commence court proceedings relating to a Dispute without first meeting with a senior representative of the other Party to seek (in good faith) to resolve the Dispute. If the Parties cannot agree how to resolve the Dispute at that initial meeting, either Party may refer the matter to a mediator. If the Parties cannot agree on who the mediator should be, either Party may ask the Law Institute of Victoria to appoint a mediator. The mediator will decide the time, place and rules for mediation. The Parties agree to attend the mediation in good faith, to seek to resolve the Dispute. The costs of the mediation will be shared equally between the Parties. Nothing in this clause will operate to prevent a Party from seeking urgent injunctive or equitable relief from a court of appropriate jurisdiction. 
								Force Majeure: We will not be liable for any delay or failure to perform our obligations under these Terms if such delay is due to any circumstance beyond our reasonable control.
								Governing law: These Terms governed by the laws of Victoria.  Each Party irrevocably and unconditionally submits to the exclusive jurisdiction of the courts operating in Victoria and any courts entitled to hear appeals from those courts and waives any right to object to proceedings being brought in those courts.  
								Notices: Any notice given under these Terms must be in writing addressed to us at the address at the end of these Terms or to you at the address in your Account. Any notice may be sent by standard post or email, and will be deemed to have been served on the expiry of 48 hours in the case of post, or at the time of transmission in the case of transmission by email.
								Relationship of Parties: These Terms are not intended to create a partnership, joint venture, employment or agency relationship (except to the extent set out in the Payment clause as limited payment collection agent) between the Parties.
								Severance: If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision is to be read down as narrowly as necessary to allow it to be valid or enforceable, failing which, that provision (or that part of that provision) will be severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.
								Definitions 
								Consequential Loss includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.
								Intellectual Property means any domain names, know-how, inventions, processes, trade secrets or confidential information; or circuit layouts, software, computer programs, databases or source codes, including any application, or right to apply, for registration of, and any improvements, enhancements or modifications of, the foregoing.
								Intellectual Property Rights means for the duration of the rights in any part of the world, any industrial or intellectual property rights, whether registrable or not, including in respect of Intellectual Property. 
								Intellectual Property Breach means any breach by you of any of our Intellectual Property Rights (or any breaches of third party rights including any Intellectual Property Rights of third parties).
								Liability means any expense, cost, liability, loss, damage, claim, notice, entitlement, investigation, demand, proceeding or judgment (whether under statute, contract, equity, tort (including negligence), indemnity or otherwise), howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent and whether involving a third party or a party to these Terms or otherwise.
								</p>									
							</div>	
						</Col>
					</Row>
				</Container>
			</div>
			<FooterSmall />
		</MainLayout>
	);
}

export default TermsAndConditions;
