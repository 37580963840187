import React, { useState } from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { InputNumber, Spin, Button } from 'antd'
import UserService from '../../../../../services/UserService'
import InputPhoneNumber from '../../../../common/InputPhoneNumber'
import CustomButton from '../../../../common/CustomButton'
import { isOwnerAccountSetup } from '../../../../../helpers/common'
import { useSelector } from 'react-redux'

const PhoneNumberVerification = ({phoneNumber, code, errorMessage, onChange, onSubmit, onSubmitForEdit}) => {
	const user = useSelector((state) => state.user.user);
	const [isCodeEnterMode, setIsCodeEnterMode] = useState(false)
	const [isChangePhoneNumberMode, setIsChangePhoneNumberMode] = useState(false)
	const [processing, setProcessing] = useState(false)

	const onChangeHandler = (key, value) => {
		if (key === 'phoneNumber') {
			onChange('errorMessage', null)
		}
		onChange(key, value)
	}

	const onSendCodePressed = () => {
		setProcessing(true)

		UserService.getGeneratedVerificationCode(phoneNumber).then((res) => {
			setIsCodeEnterMode(true)
			onChangeHandler('codeId', res.data.result.id)
			onChangeHandler('errorMessage', null)
			setProcessing(false)
		}).catch((err) => {
			onChangeHandler('code', null)
			onChangeHandler('errorMessage', err)
			setProcessing(false)
		});
	}

	if (processing) {
		return (
			<Spin />
		)
	}

	return (
		<div className="phone-number-verification-form-component">
			<Container>
				<Row>
					{isOwnerAccountSetup(user) && !isChangePhoneNumberMode ? (
						<Col>
							<p>Phone Number <b>({user.phoneNumber})</b> Already Verified!</p>
							<p></p>
							<Button 
								type='link' 
								style={{marginBottom: 10}}
								onClick={() => setIsChangePhoneNumberMode(true)}
							>
								Change Phone Number
							</Button>
							<CustomButton
								text={"SUBMIT"}
								style={{ width: "100%", marginTop: 10 }}
								onClick={() => { onSubmitForEdit() }}
							/>
						</Col>
					) : (
						<Col>
							{!isCodeEnterMode ? (
								<div>
									<p>Please enter the <b>phone number associated with your bank account!</b></p>
									<InputPhoneNumber
										placeholder="Phone Number (XXX XXX XXX)"
										value={phoneNumber}
										onChange={(val) => onChangeHandler("phoneNumber", val)} 
										countries={'all'}
									/>
									{!errorMessage || <p className="error-msg">{errorMessage}</p>}
									<CustomButton
										text={"NEXT STEP"}
										style={{ width: "100%", marginTop: 10 }}
										onClick={() => onSendCodePressed()}
										disabled={!(phoneNumber && phoneNumber.length === 12)}
									/>
								</div>
							) : (
								<div>
									<p>Please enter the <b>code</b> sent to : <b>{phoneNumber}</b></p>
									<InputNumber 
										placeholder="Code (XXXXXX)" 
										value={code}
										onChange={(val) => onChangeHandler('code', val)} 
										pattern="[-+]?[0-9]*[.,]?[0-9]+"
									/>
									{!errorMessage || <p className="error-msg">{errorMessage}</p>}

									<label>
										Didn't get code?{" "}
										<Button 
											style={{ border: 'none', padding: 0, margin: 0, color: '#20b7da' }}
											onClick={() => {
												onSendCodePressed()
												onChangeHandler('code', null)
											}}
										>
											Resend Code
										</Button>
										{" "}or{" "}
										<Button 
											style={{ border: 'none', padding: 0, margin: 0, color: '#20b7da' }}
											onClick={() => {
												setIsCodeEnterMode(false)
												onChangeHandler('code', null)
											}}
										>
											Change Number
										</Button>
									</label>

									<CustomButton
										text={"SUBMIT"}
										style={{ width: "100%", marginTop: 10 }}
										onClick={() => { onSubmit() }}
										disabled={!code}
									/>
								</div>
							)}
						</Col>
						)}
				</Row>
			</Container>
		</div>
	);
}

export default PhoneNumberVerification;
