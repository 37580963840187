import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import configureStore from './redux/store/configureStore'
import AppRouter from './components/containers/AppRouter'
import UserActions from './redux/actions/UserActions'
import UserService from '../src/services/UserService'
import {configureAmplify} from './constants/AwsConstants'
import { isIE } from './helpers/common'

/* Styles */
import './styles/custom-antd.less';
import './styles/style.sass';


const store = configureStore();
const amplify = configureAmplify();
const isInternetExplorer = isIE() // Renders a "Not Supported" message if IE

const App = () => {

  useEffect(() => {
    setTimeout(() => {
      initUser()
    }, 100);
  }, []);

  const initUser = () => {
    store.dispatch((UserActions.setUserLoading(true)));

    UserService.getUser().then((res) => {
      const user = res.data.result
      store.dispatch(UserActions.setUser(user));
      setTimeout(() => store.dispatch(UserActions.setUserLoading(false)), 500);
    }).catch((err) => {
      store.dispatch(UserActions.setUser(null));
      setTimeout(() => store.dispatch(UserActions.setUserLoading(false)), 500);
    });
  }

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  )
}

export default App
