import React from 'react'
import './style.sass'
import { Link } from 'react-router-dom';

const CustomLinkButton = ({text, to}) => {
  return ( 
    <div className="custom-link-button-component" >
      <Link 
        className='special-btn'
        to={to}
      >
        {text}
      </Link>
    </div>
  );
}

export default CustomLinkButton