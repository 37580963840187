import React from 'react'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import './style.sass'
import canoe from '../../../assets/icons/boatCategoryIcons/canoe.png'
import catamaran from '../../../assets/icons/boatCategoryIcons/catamaran.png'
import ferry from '../../../assets/icons/boatCategoryIcons/ferry.png'
import fishing from '../../../assets/icons/boatCategoryIcons/fishing.png'
import jetski from '../../../assets/icons/boatCategoryIcons/jetski.png'
import sail from '../../../assets/icons/boatCategoryIcons//sail.png'
import speed from '../../../assets/icons/boatCategoryIcons/speed.png'
import yacht from '../../../assets/icons/boatCategoryIcons/yacht.png'
import houseboat from '../../../assets/icons/boatCategoryIcons/houseboat.png'
// import dinghy from '../../../assets/icons/boatCategoryIcons/dinghy.png'

const BoatDetailsCard = ({boat}) => {

  const getBoatImage = () => {
    switch (boat.category) {
      case 'Canoe':
        return canoe
      case 'Catamaran':
        return catamaran
      case 'Ferry':
        return ferry
      case 'Fishing':
        return fishing
      case 'Jetski':
        return jetski
      case 'Sail':
        return sail
      case 'Speed':
        return speed
      case 'Yacht':
        return yacht
      case 'House':
        return houseboat
      // case 'Dinghy':
      //     return dinghy
      default:
        return sail // DEFAULT
    }

  }
  return ( 
    <div className="boat-details-card-component">
      <h4>Boat Details</h4>
      <hr/>
      {!boat || (
        <div className="flex-container">
          <div className="right">
            <img src={getBoatImage()} alt="logo"/>
          </div>
          <div className="left">
            <h5>{boat.brand} - {boat.model}</h5>
            {boat.verified ? <span className="boat-verified"><CheckCircleOutlined /> Verified</span> : <span className="boat-not-verified"><CloseCircleOutlined /> Not Verified</span>}
            <p>{boat.name}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default BoatDetailsCard