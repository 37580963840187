import React from 'react'
import './style.sass'
import { Alert } from 'antd'
import { Link } from 'react-router-dom'

const OwnerAccountNotSetupCard = () => (
  <div className='owner-account-not-setup-card-component'>
    <Alert
      message="Owner Account Not Setup!"
      description="Please setup owner account first!"
      type="warning"
      showIcon
    />
    <p>
      <Link
        to='/owner/setup'
        style={{ color: '#20b7da', fontWeight: '400', marginRight: 5 }}
      > 
        Click here
      </Link>
      to setup owner account!
    </p>
  </div>
)

export default OwnerAccountNotSetupCard