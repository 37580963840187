export const BOAT_FEATURES = [
{ name: "Life jackets and wearable personal flotation devices (PFDs)", required: true },
{ name: "Throwable flotation devices", required: true },
{ name: "Fire extinguishers", required: true },
{ name: "Visual signaling devices", required: true },
{ name: " Sound signaling devices", required: true },

//   { name: "Life Jackets", required: true },
//   { name: "Flotation Devices", required: true },
//   { name: "Fire Extinguishers", required: true },
//   { name: "Visual Signaling", required: true },
//   { name: "Sound Signaling", required: true },
//   { name: "Bailing Device", required: true },
//   { name: "Medical Kit", required: true },
//   { name: "Anchor", required: true },
//   { name: "Snorkel Mask", required: true },
//   { name: "Skier", required: true },
//   { name: "Running Lights", required: true },
//   { name: "Galley Stove", required: false },
//   { name: "Chart Plotter", required: false },
//   { name: "CD Player", required: false },
//   { name: "Cabin", required: false },
//   { name: "Dodger", required: false },
//   { name: "Furling Mainsail", required: false },
//   { name: "Autopilot", required: false },
//   { name: "Air Conditioning", required: false },
//   { name: "Inboard Engine", required: false },
//   { name: "Generator", required: false },
]

export const BOAT_BRANDS = [
  {
     "type":"value",
     "displayValue":"145 Class",
     "value":"Make.145 Class."
  },
  {
     "type":"value",
     "displayValue":"3D TENDER",
     "value":"Make.3D TENDER."
  },
  {
     "type":"value",
     "displayValue":"4 Seasons",
     "value":"Make.4 Seasons."
  },
  {
     "type":"value",
     "displayValue":"A CLASS",
     "value":"Make.A CLASS."
  },
  {
     "type":"value",
     "displayValue":"AB Inflatables",
     "value":"Make.AB Inflatables."
  },
  {
     "type":"value",
     "displayValue":"ABEKING RASMUSSEN",
     "value":"Make.ABEKING RASMUSSEN."
  },
  {
     "type":"value",
     "displayValue":"ABSOLUTE",
     "value":"Make.ABSOLUTE."
  },
  {
     "type":"value",
     "displayValue":"AC Marine",
     "value":"Make.AC Marine."
  },
  {
     "type":"value",
     "displayValue":"ACADEMY",
     "value":"Make.ACADEMY."
  },
  {
     "type":"value",
     "displayValue":"Ace Craft",
     "value":"Make.Ace Craft."
  },
  {
     "type":"value",
     "displayValue":"ACHILLES",
     "value":"Make.ACHILLES."
  },
  {
     "type":"value",
     "displayValue":"Action Craft",
     "value":"Make.Action Craft."
  },
  {
     "type":"value",
     "displayValue":"ACTIVA",
     "value":"Make.ACTIVA."
  },
  {
     "type":"value",
     "displayValue":"ADAMS",
     "value":"Make.ADAMS."
  },
  {
     "type":"value",
     "displayValue":"ADMIRAL",
     "value":"Make.ADMIRAL."
  },
  {
     "type":"value",
     "displayValue":"ADVENTURE CRAFT",
     "value":"Make.ADVENTURE CRAFT."
  },
  {
     "type":"value",
     "displayValue":"ADVENTURER CLASS",
     "value":"Make.ADVENTURER CLASS."
  },
  {
     "type":"value",
     "displayValue":"AFRICAT YACHTS",
     "value":"Make.AFRICAT YACHTS."
  },
  {
     "type":"value",
     "displayValue":"AICON",
     "value":"Make.AICON."
  },
  {
     "type":"value",
     "displayValue":"AIRIB",
     "value":"Make.AIRIB."
  },
  {
     "type":"value",
     "displayValue":"Al Dhaen",
     "value":"Make.Al Dhaen."
  },
  {
     "type":"value",
     "displayValue":"ALAN PAYNE",
     "value":"Make.ALAN PAYNE."
  },
  {
     "type":"value",
     "displayValue":"ALAN WRIGHT",
     "value":"Make.ALAN WRIGHT."
  },
  {
     "type":"value",
     "displayValue":"Alaska",
     "value":"Make.Alaska."
  },
  {
     "type":"value",
     "displayValue":"ALBEMARLE",
     "value":"Make.ALBEMARLE."
  },
  {
     "type":"value",
     "displayValue":"ALBERG",
     "value":"Make.ALBERG."
  },
  {
     "type":"value",
     "displayValue":"ALDEN",
     "value":"Make.ALDEN."
  },
  {
     "type":"value",
     "displayValue":"ALLICRAFT",
     "value":"Make.ALLICRAFT."
  },
  {
     "type":"value",
     "displayValue":"Allison",
     "value":"Make.Allison."
  },
  {
     "type":"value",
     "displayValue":"Allison Alloy",
     "value":"Make.Allison Alloy."
  },
  {
     "type":"value",
     "displayValue":"ALLOY",
     "value":"Make.ALLOY."
  },
  {
     "type":"value",
     "displayValue":"ALLOY BOATS",
     "value":"Make.ALLOY BOATS."
  },
  {
     "type":"value",
     "displayValue":"ALLSEAS YACHTS",
     "value":"Make.ALLSEAS YACHTS."
  },
  {
     "type":"value",
     "displayValue":"ALLY CRAFT",
     "value":"Make.ALLY CRAFT."
  },
  {
     "type":"value",
     "displayValue":"ALOCRAFT",
     "value":"Make.ALOCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Aloha",
     "value":"Make.Aloha."
  },
  {
     "type":"value",
     "displayValue":"ALUCRAFT",
     "value":"Make.ALUCRAFT."
  },
  {
     "type":"value",
     "displayValue":"ALUFARM",
     "value":"Make.ALUFARM."
  },
  {
     "type":"value",
     "displayValue":"ALUMINIUM",
     "value":"Make.ALUMINIUM."
  },
  {
     "type":"value",
     "displayValue":"Amara Boats",
     "value":"Make.Amara Boats."
  },
  {
     "type":"value",
     "displayValue":"AMBASSADOR",
     "value":"Make.AMBASSADOR."
  },
  {
     "type":"value",
     "displayValue":"Amel",
     "value":"Make.Amel."
  },
  {
     "type":"value",
     "displayValue":"AMITY",
     "value":"Make.AMITY."
  },
  {
     "type":"value",
     "displayValue":"AMPHIBIOUS",
     "value":"Make.AMPHIBIOUS."
  },
  {
     "type":"value",
     "displayValue":"ANGLAPRO",
     "value":"Make.ANGLAPRO."
  },
  {
     "type":"value",
     "displayValue":"ANGLER",
     "value":"Make.ANGLER."
  },
  {
     "type":"value",
     "displayValue":"Angler Boats",
     "value":"Make.Angler Boats."
  },
  {
     "type":"value",
     "displayValue":"APOLLO",
     "value":"Make.APOLLO."
  },
  {
     "type":"value",
     "displayValue":"APREAMARE",
     "value":"Make.APREAMARE."
  },
  {
     "type":"value",
     "displayValue":"AQUAMASTER",
     "value":"Make.AQUAMASTER."
  },
  {
     "type":"value",
     "displayValue":"Aquapro",
     "value":"Make.Aquapro."
  },
  {
     "type":"value",
     "displayValue":"AQUATRUCK",
     "value":"Make.AQUATRUCK."
  },
  {
     "type":"value",
     "displayValue":"AQUILA",
     "value":"Make.AQUILA."
  },
  {
     "type":"value",
     "displayValue":"AQUOS",
     "value":"Make.AQUOS."
  },
  {
     "type":"value",
     "displayValue":"Archambault",
     "value":"Make.Archambault."
  },
  {
     "type":"value",
     "displayValue":"ARENDS",
     "value":"Make.ARENDS."
  },
  {
     "type":"value",
     "displayValue":"ARISTOCRAFT",
     "value":"Make.ARISTOCRAFT."
  },
  {
     "type":"value",
     "displayValue":"ARMITAGE",
     "value":"Make.ARMITAGE."
  },
  {
     "type":"value",
     "displayValue":"ARROWCAT",
     "value":"Make.ARROWCAT."
  },
  {
     "type":"value",
     "displayValue":"ARVOR",
     "value":"Make.ARVOR."
  },
  {
     "type":"value",
     "displayValue":"ASIA CATAMARANS",
     "value":"Make.ASIA CATAMARANS."
  },
  {
     "type":"value",
     "displayValue":"Atkinson",
     "value":"Make.Atkinson."
  },
  {
     "type":"value",
     "displayValue":"ATOMIC",
     "value":"Make.ATOMIC."
  },
  {
     "type":"value",
     "displayValue":"ATOMIX",
     "value":"Make.ATOMIX."
  },
  {
     "type":"value",
     "displayValue":"AURORA",
     "value":"Make.AURORA."
  },
  {
     "type":"value",
     "displayValue":"AUSMARINE",
     "value":"Make.AUSMARINE."
  },
  {
     "type":"value",
     "displayValue":"Aussie Whaler",
     "value":"Make.Aussie Whaler."
  },
  {
     "type":"value",
     "displayValue":"AUSTRAL",
     "value":"Make.AUSTRAL."
  },
  {
     "type":"value",
     "displayValue":"AUSTRAL MARINE",
     "value":"Make.AUSTRAL MARINE."
  },
  {
     "type":"value",
     "displayValue":"Australian Master Marine",
     "value":"Make.Australian Master Marine."
  },
  {
     "type":"value",
     "displayValue":"Avalon",
     "value":"Make.Avalon."
  },
  {
     "type":"value",
     "displayValue":"Aventura Catamarans",
     "value":"Make.Aventura Catamarans."
  },
  {
     "type":"value",
     "displayValue":"AVON",
     "value":"Make.AVON."
  },
  {
     "type":"value",
     "displayValue":"Axion",
     "value":"Make.Axion."
  },
  {
     "type":"value",
     "displayValue":"AXIS",
     "value":"Make.AXIS."
  },
  {
     "type":"value",
     "displayValue":"Axis By Malibu",
     "value":"Make.Axis By Malibu."
  },
  {
      "type":"value",
      "displayValue":"MALIBU",
      "value":"Make.MALIBU."
  },
  {
     "type":"value",
     "displayValue":"Axopar",
     "value":"Make.Axopar."
  },
  {
     "type":"value",
     "displayValue":"Azimut",
     "value":"Make.Azimut."
  },
  {
     "type":"value",
     "displayValue":"AZTECRAFT",
     "value":"Make.AZTECRAFT."
  },
  {
     "type":"value",
     "displayValue":"Azuree",
     "value":"Make.Azuree."
  },
  {
     "type":"value",
     "displayValue":"Back Cove",
     "value":"Make.Back Cove."
  },
  {
     "type":"value",
     "displayValue":"BADENACH",
     "value":"Make.BADENACH."
  },
  {
     "type":"value",
     "displayValue":"BAHAMA",
     "value":"Make.BAHAMA."
  },
  {
     "type":"value",
     "displayValue":"Baia",
     "value":"Make.Baia."
  },
  {
     "type":"value",
     "displayValue":"BAJA",
     "value":"Make.BAJA."
  },
  {
     "type":"value",
     "displayValue":"BAJCRAFT",
     "value":"Make.BAJCRAFT."
  },
  {
     "type":"value",
     "displayValue":"BAKRI CONO",
     "value":"Make.BAKRI CONO."
  },
  {
     "type":"value",
     "displayValue":"BALI CATAMARANS",
     "value":"Make.BALI CATAMARANS."
  },
  {
     "type":"value",
     "displayValue":"Bar Crusher",
     "value":"Make.Bar Crusher."
  },
  {
     "type":"value",
     "displayValue":"BARGE",
     "value":"Make.BARGE."
  },
  {
     "type":"value",
     "displayValue":"BASS BOAT",
     "value":"Make.BASS BOAT."
  },
  {
     "type":"value",
     "displayValue":"BASS STRAIT",
     "value":"Make.BASS STRAIT."
  },
  {
     "type":"value",
     "displayValue":"BAVARIA",
     "value":"Make.BAVARIA."
  },
  {
     "type":"value",
     "displayValue":"BAY CRUISER",
     "value":"Make.BAY CRUISER."
  },
  {
     "type":"value",
     "displayValue":"BAYCRAFT",
     "value":"Make.BAYCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Bayliner",
     "value":"Make.Bayliner."
  },
  {
     "type":"value",
     "displayValue":"BAYSPORT",
     "value":"Make.BAYSPORT."
  },
  {
     "type":"value",
     "displayValue":"BEACHCRAFT",
     "value":"Make.BEACHCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Beastmaster Boats",
     "value":"Make.Beastmaster Boats."
  },
  {
     "type":"value",
     "displayValue":"BELASSI",
     "value":"Make.BELASSI."
  },
  {
     "type":"value",
     "displayValue":"BELIZE",
     "value":"Make.BELIZE."
  },
  {
     "type":"value",
     "displayValue":"BELLA",
     "value":"Make.BELLA."
  },
  {
     "type":"value",
     "displayValue":"BELLBOY",
     "value":"Make.BELLBOY."
  },
  {
     "type":"value",
     "displayValue":"BELLCRAFT",
     "value":"Make.BELLCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Benelli",
     "value":"Make.Benelli."
  },
  {
     "type":"value",
     "displayValue":"Beneteau",
     "value":"Make.Beneteau."
  },
  {
     "type":"value",
     "displayValue":"BENETTI",
     "value":"Make.BENETTI."
  },
  {
     "type":"value",
     "displayValue":"BENITO BOATS",
     "value":"Make.BENITO BOATS."
  },
  {
     "type":"value",
     "displayValue":"BENNINGTON",
     "value":"Make.BENNINGTON."
  },
  {
     "type":"value",
     "displayValue":"BERING",
     "value":"Make.BERING."
  },
  {
     "type":"value",
     "displayValue":"Bermuda",
     "value":"Make.Bermuda."
  },
  {
     "type":"value",
     "displayValue":"BERNICO",
     "value":"Make.BERNICO."
  },
  {
     "type":"value",
     "displayValue":"BERT ELLIS",
     "value":"Make.BERT ELLIS."
  },
  {
     "type":"value",
     "displayValue":"Bertram",
     "value":"Make.Bertram."
  },
  {
     "type":"value",
     "displayValue":"BERTRAM CARIBBEAN",
     "value":"Make.BERTRAM CARIBBEAN."
  },
  {
     "type":"value",
     "displayValue":"BESTYEAR",
     "value":"Make.BESTYEAR."
  },
  {
     "type":"value",
     "displayValue":"BETHWAITE",
     "value":"Make.BETHWAITE."
  },
  {
     "type":"value",
     "displayValue":"BG Boatbuilding",
     "value":"Make.BG Boatbuilding."
  },
  {
     "type":"value",
     "displayValue":"Bill Bott",
     "value":"Make.Bill Bott."
  },
  {
     "type":"value",
     "displayValue":"Bill Porter",
     "value":"Make.Bill Porter."
  },
  {
     "type":"value",
     "displayValue":"Bingstar",
     "value":"Make.Bingstar."
  },
  {
     "type":"value",
     "displayValue":"BLACK WATCH",
     "value":"Make.BLACK WATCH."
  },
  {
     "type":"value",
     "displayValue":"Blackdog Cat",
     "value":"Make.Blackdog Cat."
  },
  {
     "type":"value",
     "displayValue":"BLACKFIN",
     "value":"Make.BLACKFIN."
  },
  {
     "type":"value",
     "displayValue":"BLISSCRAFT",
     "value":"Make.BLISSCRAFT."
  },
  {
     "type":"value",
     "displayValue":"BLUE SEAS",
     "value":"Make.BLUE SEAS."
  },
  {
     "type":"value",
     "displayValue":"BLUE WATER",
     "value":"Make.BLUE WATER."
  },
  {
     "type":"value",
     "displayValue":"Blueblue",
     "value":"Make.Blueblue."
  },
  {
     "type":"value",
     "displayValue":"BLUEFIN",
     "value":"Make.BLUEFIN."
  },
  {
     "type":"value",
     "displayValue":"BLUEWATER",
     "value":"Make.BLUEWATER."
  },
  {
     "type":"value",
     "displayValue":"BLUNT BOAT BUILDERS",
     "value":"Make.BLUNT BOAT BUILDERS."
  },
  {
     "type":"value",
     "displayValue":"Boden",
     "value":"Make.Boden."
  },
  {
     "type":"value",
     "displayValue":"BOLLARD",
     "value":"Make.BOLLARD."
  },
  {
     "type":"value",
     "displayValue":"Bombard",
     "value":"Make.Bombard."
  },
  {
     "type":"value",
     "displayValue":"BONITO",
     "value":"Make.BONITO."
  },
  {
     "type":"value",
     "displayValue":"BOOMERANG",
     "value":"Make.BOOMERANG."
  },
  {
     "type":"value",
     "displayValue":"BORLASE",
     "value":"Make.BORLASE."
  },
  {
     "type":"value",
     "displayValue":"BORO",
     "value":"Make.BORO."
  },
  {
     "type":"value",
     "displayValue":"Boston Whaler",
     "value":"Make.Boston Whaler."
  },
  {
     "type":"value",
     "displayValue":"BOTTERILL",
     "value":"Make.BOTTERILL."
  },
  {
     "type":"value",
     "displayValue":"BOUNTY",
     "value":"Make.BOUNTY."
  },
  {
     "type":"value",
     "displayValue":"BOUNTYCRAFT ",
     "value":"Make.BOUNTYCRAFT ."
  },
  {
     "type":"value",
     "displayValue":"BOWDEN",
     "value":"Make.BOWDEN."
  },
  {
     "type":"value",
     "displayValue":"BOWDIDGE MARINE DESIGNS",
     "value":"Make.BOWDIDGE MARINE DESIGNS."
  },
  {
     "type":"value",
     "displayValue":"BOWMAN",
     "value":"Make.BOWMAN."
  },
  {
     "type":"value",
     "displayValue":"Boyer",
     "value":"Make.Boyer."
  },
  {
     "type":"value",
     "displayValue":"BRABUS MARINE",
     "value":"Make.BRABUS MARINE."
  },
  {
     "type":"value",
     "displayValue":"BRACKEN",
     "value":"Make.BRACKEN."
  },
  {
     "type":"value",
     "displayValue":"BRADY",
     "value":"Make.BRADY."
  },
  {
     "type":"value",
     "displayValue":"BRAND CATAMARANS PALAWAN",
     "value":"Make.BRAND CATAMARANS PALAWAN."
  },
  {
     "type":"value",
     "displayValue":"BRIG",
     "value":"Make.BRIG."
  },
  {
     "type":"value",
     "displayValue":"BRINOVO",
     "value":"Make.BRINOVO."
  },
  {
     "type":"value",
     "displayValue":"Bris",
     "value":"Make.Bris."
  },
  {
     "type":"value",
     "displayValue":"Brooker",
     "value":"Make.Brooker."
  },
  {
     "type":"value",
     "displayValue":"BROWARD",
     "value":"Make.BROWARD."
  },
  {
     "type":"value",
     "displayValue":"BROWN BROTHERS",
     "value":"Make.BROWN BROTHERS."
  },
  {
     "type":"value",
     "displayValue":"BRUCE HARRIS",
     "value":"Make.BRUCE HARRIS."
  },
  {
     "type":"value",
     "displayValue":"BRUCE ROBERTS",
     "value":"Make.BRUCE ROBERTS."
  },
  {
     "type":"value",
     "displayValue":"Buccaneer",
     "value":"Make.Buccaneer."
  },
  {
     "type":"value",
     "displayValue":"BUEHLER",
     "value":"Make.BUEHLER."
  },
  {
     "type":"value",
     "displayValue":"BUIZEN",
     "value":"Make.BUIZEN."
  },
  {
     "type":"value",
     "displayValue":"Bullet",
     "value":"Make.Bullet."
  },
  {
     "type":"value",
     "displayValue":"CABO",
     "value":"Make.CABO."
  },
  {
     "type":"value",
     "displayValue":"CABRIOLET",
     "value":"Make.CABRIOLET."
  },
  {
     "type":"value",
     "displayValue":"Cadenazzi",
     "value":"Make.Cadenazzi."
  },
  {
     "type":"value",
     "displayValue":"Cairns Custom Craft",
     "value":"Make.Cairns Custom Craft."
  },
  {
     "type":"value",
     "displayValue":"CAL",
     "value":"Make.CAL."
  },
  {
     "type":"value",
     "displayValue":"CALABRIA",
     "value":"Make.CALABRIA."
  },
  {
     "type":"value",
     "displayValue":"CAMARO",
     "value":"Make.CAMARO."
  },
  {
     "type":"value",
     "displayValue":"CAMCRAFT",
     "value":"Make.CAMCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Camero",
     "value":"Make.Camero."
  },
  {
     "type":"value",
     "displayValue":"Campion",
     "value":"Make.Campion."
  },
  {
     "type":"value",
     "displayValue":"CANTIERI DI SARNICO",
     "value":"Make.CANTIERI DI SARNICO."
  },
  {
     "type":"value",
     "displayValue":"CANTIERI MAGAZZU",
     "value":"Make.CANTIERI MAGAZZU."
  },
  {
     "type":"value",
     "displayValue":"CAPE",
     "value":"Make.CAPE."
  },
  {
     "type":"value",
     "displayValue":"CAPE SCOTT",
     "value":"Make.CAPE SCOTT."
  },
  {
     "type":"value",
     "displayValue":"CAPELLI",
     "value":"Make.CAPELLI."
  },
  {
     "type":"value",
     "displayValue":"CAPER CAT",
     "value":"Make.CAPER CAT."
  },
  {
     "type":"value",
     "displayValue":"CAPRICORN",
     "value":"Make.CAPRICORN."
  },
  {
     "type":"value",
     "displayValue":"CARA MARINE",
     "value":"Make.CARA MARINE."
  },
  {
     "type":"value",
     "displayValue":"Caravelle",
     "value":"Make.Caravelle."
  },
  {
     "type":"value",
     "displayValue":"CARBINEER",
     "value":"Make.CARBINEER."
  },
  {
     "type":"value",
     "displayValue":"CAREEL",
     "value":"Make.CAREEL."
  },
  {
     "type":"value",
     "displayValue":"CAREY",
     "value":"Make.CAREY."
  },
  {
     "type":"value",
     "displayValue":"Caribbean",
     "value":"Make.Caribbean."
  },
  {
     "type":"value",
     "displayValue":"Caribbean Bertram",
     "value":"Make.Caribbean Bertram."
  },
  {
     "type":"value",
     "displayValue":"CARIBE",
     "value":"Make.CARIBE."
  },
  {
     "type":"value",
     "displayValue":"CAROLINA CLASSIC",
     "value":"Make.CAROLINA CLASSIC."
  },
  {
     "type":"value",
     "displayValue":"CARVEL",
     "value":"Make.CARVEL."
  },
  {
     "type":"value",
     "displayValue":"CARVER",
     "value":"Make.CARVER."
  },
  {
     "type":"value",
     "displayValue":"CARVER YACHTS",
     "value":"Make.CARVER YACHTS."
  },
  {
     "type":"value",
     "displayValue":"CASTLE",
     "value":"Make.CASTLE."
  },
  {
     "type":"value",
     "displayValue":"CATALINA YACHTS",
     "value":"Make.CATALINA YACHTS."
  },
  {
     "type":"value",
     "displayValue":"CATAMARAN",
     "value":"Make.CATAMARAN."
  },
  {
     "type":"value",
     "displayValue":"CATANA",
     "value":"Make.CATANA."
  },
  {
     "type":"value",
     "displayValue":"CATATHAI",
     "value":"Make.CATATHAI."
  },
  {
     "type":"value",
     "displayValue":"CAVALIER",
     "value":"Make.CAVALIER."
  },
  {
     "type":"value",
     "displayValue":"CAYAGO",
     "value":"Make.CAYAGO."
  },
  {
     "type":"value",
     "displayValue":"Cayman",
     "value":"Make.Cayman."
  },
  {
     "type":"value",
     "displayValue":"CDMARINE",
     "value":"Make.CDMARINE."
  },
  {
     "type":"value",
     "displayValue":"CELEBRITY",
     "value":"Make.CELEBRITY."
  },
  {
     "type":"value",
     "displayValue":"CELESTIAL",
     "value":"Make.CELESTIAL."
  },
  {
     "type":"value",
     "displayValue":"CENTURION",
     "value":"Make.CENTURION."
  },
  {
     "type":"value",
     "displayValue":"Century",
     "value":"Make.Century."
  },
  {
     "type":"value",
     "displayValue":"CHALLENGE",
     "value":"Make.CHALLENGE."
  },
  {
     "type":"value",
     "displayValue":"CHALLENGER",
     "value":"Make.CHALLENGER."
  },
  {
     "type":"value",
     "displayValue":"Chaparral",
     "value":"Make.Chaparral."
  },
  {
     "type":"value",
     "displayValue":"CHARTER",
     "value":"Make.CHARTER."
  },
  {
     "type":"value",
     "displayValue":"CHEETAH",
     "value":"Make.CHEETAH."
  },
  {
     "type":"value",
     "displayValue":"CHEOY LEE",
     "value":"Make.CHEOY LEE."
  },
  {
     "type":"value",
     "displayValue":"CHILDSPLAY",
     "value":"Make.CHILDSPLAY."
  },
  {
     "type":"value",
     "displayValue":"CHINCOGAN",
     "value":"Make.CHINCOGAN."
  },
  {
     "type":"value",
     "displayValue":"Chinese Junk",
     "value":"Make.Chinese Junk."
  },
  {
     "type":"value",
     "displayValue":"CHIVERS",
     "value":"Make.CHIVERS."
  },
  {
     "type":"value",
     "displayValue":"CHRIS CRAFT",
     "value":"Make.CHRIS CRAFT."
  },
  {
     "type":"value",
     "displayValue":"CHRISTENSEN",
     "value":"Make.CHRISTENSEN."
  },
  {
     "type":"value",
     "displayValue":"CHUCK PAINE",
     "value":"Make.CHUCK PAINE."
  },
  {
     "type":"value",
     "displayValue":"CHUNG HWA",
     "value":"Make.CHUNG HWA."
  },
  {
     "type":"value",
     "displayValue":"Cigarette",
     "value":"Make.Cigarette."
  },
  {
     "type":"value",
     "displayValue":"Circa Marine",
     "value":"Make.Circa Marine."
  },
  {
     "type":"value",
     "displayValue":"CITATION",
     "value":"Make.CITATION."
  },
  {
     "type":"value",
     "displayValue":"Clark",
     "value":"Make.Clark."
  },
  {
     "type":"value",
     "displayValue":"CLARKE",
     "value":"Make.CLARKE."
  },
  {
     "type":"value",
     "displayValue":"CLASSIC",
     "value":"Make.CLASSIC."
  },
  {
     "type":"value",
     "displayValue":"CLASSIC CRAFT",
     "value":"Make.CLASSIC CRAFT."
  },
  {
     "type":"value",
     "displayValue":"CLASSIC TIMBER LAUNCH",
     "value":"Make.CLASSIC TIMBER LAUNCH."
  },
  {
     "type":"value",
     "displayValue":"CLEARWATER",
     "value":"Make.CLEARWATER."
  },
  {
     "type":"value",
     "displayValue":"CLEM MASTERS",
     "value":"Make.CLEM MASTERS."
  },
  {
     "type":"value",
     "displayValue":"Clipper",
     "value":"Make.Clipper."
  },
  {
     "type":"value",
     "displayValue":"CLOUD",
     "value":"Make.CLOUD."
  },
  {
     "type":"value",
     "displayValue":"CLOUDY BAY",
     "value":"Make.CLOUDY BAY."
  },
  {
     "type":"value",
     "displayValue":"CNB YACHTS",
     "value":"Make.CNB YACHTS."
  },
  {
     "type":"value",
     "displayValue":"CNC Marine",
     "value":"Make.CNC Marine."
  },
  {
     "type":"value",
     "displayValue":"COASTER",
     "value":"Make.COASTER."
  },
  {
     "type":"value",
     "displayValue":"COASTLINE",
     "value":"Make.COASTLINE."
  },
  {
     "type":"value",
     "displayValue":"Cobalt",
     "value":"Make.Cobalt."
  },
  {
     "type":"value",
     "displayValue":"COBIA",
     "value":"Make.COBIA."
  },
  {
     "type":"value",
     "displayValue":"COBIA INFLATABLES",
     "value":"Make.COBIA INFLATABLES."
  },
  {
     "type":"value",
     "displayValue":"COHER",
     "value":"Make.COHER."
  },
  {
     "type":"value",
     "displayValue":"COLE",
     "value":"Make.COLE."
  },
  {
     "type":"value",
     "displayValue":"COLIN ARCHER",
     "value":"Make.COLIN ARCHER."
  },
  {
     "type":"value",
     "displayValue":"COLOMBO YACHTS",
     "value":"Make.COLOMBO YACHTS."
  },
  {
     "type":"value",
     "displayValue":"COLUMBIA",
     "value":"Make.COLUMBIA."
  },
  {
     "type":"value",
     "displayValue":"Colvic",
     "value":"Make.Colvic."
  },
  {
     "type":"value",
     "displayValue":"COMET",
     "value":"Make.COMET."
  },
  {
     "type":"value",
     "displayValue":"COMMERCIAL",
     "value":"Make.COMMERCIAL."
  },
  {
     "type":"value",
     "displayValue":"Commercial Marine",
     "value":"Make.Commercial Marine."
  },
  {
     "type":"value",
     "displayValue":"Compass",
     "value":"Make.Compass."
  },
  {
     "type":"value",
     "displayValue":"CONCEPT",
     "value":"Make.CONCEPT."
  },
  {
     "type":"value",
     "displayValue":"Conquest",
     "value":"Make.Conquest."
  },
  {
     "type":"value",
     "displayValue":"Contender",
     "value":"Make.Contender."
  },
  {
     "type":"value",
     "displayValue":"CONTESSA",
     "value":"Make.CONTESSA."
  },
  {
     "type":"value",
     "displayValue":"COOKE YACHTS",
     "value":"Make.COOKE YACHTS."
  },
  {
     "type":"value",
     "displayValue":"COOPER QUEENSHIP",
     "value":"Make.COOPER QUEENSHIP."
  },
  {
     "type":"value",
     "displayValue":"COOTA CRAFT",
     "value":"Make.COOTA CRAFT."
  },
  {
     "type":"value",
     "displayValue":"COOTACRAFT",
     "value":"Make.COOTACRAFT."
  },
  {
     "type":"value",
     "displayValue":"CORAL COAST",
     "value":"Make.CORAL COAST."
  },
  {
     "type":"value",
     "displayValue":"CORSAIR",
     "value":"Make.CORSAIR."
  },
  {
     "type":"value",
     "displayValue":"COUACH",
     "value":"Make.COUACH."
  },
  {
     "type":"value",
     "displayValue":"COUGAR",
     "value":"Make.COUGAR."
  },
  {
     "type":"value",
     "displayValue":"COUGAR CAT",
     "value":"Make.COUGAR CAT."
  },
  {
     "type":"value",
     "displayValue":"COUGAR CRAFT",
     "value":"Make.COUGAR CRAFT."
  },
  {
     "type":"value",
     "displayValue":"COURT",
     "value":"Make.COURT."
  },
  {
     "type":"value",
     "displayValue":"COUTA BOAT",
     "value":"Make.COUTA BOAT."
  },
  {
     "type":"value",
     "displayValue":"Coxcraft",
     "value":"Make.Coxcraft."
  },
  {
     "type":"value",
     "displayValue":"CRANCHI",
     "value":"Make.CRANCHI."
  },
  {
     "type":"value",
     "displayValue":"CREST CRAFT",
     "value":"Make.CREST CRAFT."
  },
  {
     "type":"value",
     "displayValue":"CRESTA",
     "value":"Make.CRESTA."
  },
  {
     "type":"value",
     "displayValue":"Crestliner",
     "value":"Make.Crestliner."
  },
  {
     "type":"value",
     "displayValue":"CRESTRIDA",
     "value":"Make.CRESTRIDA."
  },
  {
     "type":"value",
     "displayValue":"CROSS X COUNTRY",
     "value":"Make.CROSS X COUNTRY."
  },
  {
     "type":"value",
     "displayValue":"Crownline",
     "value":"Make.Crownline."
  },
  {
     "type":"value",
     "displayValue":"Crowther",
     "value":"Make.Crowther."
  },
  {
     "type":"value",
     "displayValue":"Cruise Craft",
     "value":"Make.Cruise Craft."
  },
  {
     "type":"value",
     "displayValue":"CRUISECAT",
     "value":"Make.CRUISECAT."
  },
  {
     "type":"value",
     "displayValue":"CRUISER",
     "value":"Make.CRUISER."
  },
  {
     "type":"value",
     "displayValue":"CRUISERS",
     "value":"Make.CRUISERS."
  },
  {
     "type":"value",
     "displayValue":"Cruisers Yachts",
     "value":"Make.Cruisers Yachts."
  },
  {
     "type":"value",
     "displayValue":"Cruisette",
     "value":"Make.Cruisette."
  },
  {
     "type":"value",
     "displayValue":"CRUISING YACHT",
     "value":"Make.CRUISING YACHT."
  },
  {
     "type":"value",
     "displayValue":"CRUSADER",
     "value":"Make.CRUSADER."
  },
  {
     "type":"value",
     "displayValue":"CSB HUNTSMAN",
     "value":"Make.CSB HUNTSMAN."
  },
  {
     "type":"value",
     "displayValue":"CUDDLES",
     "value":"Make.CUDDLES."
  },
  {
     "type":"value",
     "displayValue":"CURRENT 23",
     "value":"Make.CURRENT 23."
  },
  {
     "type":"value",
     "displayValue":"Custom",
     "value":"Make.Custom."
  },
  {
     "type":"value",
     "displayValue":"CUSTOM SKI BOATS",
     "value":"Make.CUSTOM SKI BOATS."
  },
  {
     "type":"value",
     "displayValue":"CUTTER",
     "value":"Make.CUTTER."
  },
  {
     "type":"value",
     "displayValue":"Cyclone",
     "value":"Make.Cyclone."
  },
  {
     "type":"value",
     "displayValue":"DAGLESS",
     "value":"Make.DAGLESS."
  },
  {
     "type":"value",
     "displayValue":"DAN LEECH",
     "value":"Make.DAN LEECH."
  },
  {
     "type":"value",
     "displayValue":"DANCRAFT",
     "value":"Make.DANCRAFT."
  },
  {
     "type":"value",
     "displayValue":"DAVID PAYNE",
     "value":"Make.DAVID PAYNE."
  },
  {
     "type":"value",
     "displayValue":"David Young",
     "value":"Make.David Young."
  },
  {
     "type":"value",
     "displayValue":"DAVIDSON",
     "value":"Make.DAVIDSON."
  },
  {
     "type":"value",
     "displayValue":"DAYDREAM",
     "value":"Make.DAYDREAM."
  },
  {
     "type":"value",
     "displayValue":"DE ANTONIO YACHTS",
     "value":"Make.DE ANTONIO YACHTS."
  },
  {
     "type":"value",
     "displayValue":"DE HAVILLAND",
     "value":"Make.DE HAVILLAND."
  },
  {
     "type":"value",
     "displayValue":"DEAN CATAMARANS",
     "value":"Make.DEAN CATAMARANS."
  },
  {
     "type":"value",
     "displayValue":"DEEP V",
     "value":"Make.DEEP V."
  },
  {
     "type":"value",
     "displayValue":"DEFEVER",
     "value":"Make.DEFEVER."
  },
  {
     "type":"value",
     "displayValue":"DEHLER",
     "value":"Make.DEHLER."
  },
  {
     "type":"value",
     "displayValue":"DELTA",
     "value":"Make.DELTA."
  },
  {
     "type":"value",
     "displayValue":"DELTACRAFT",
     "value":"Make.DELTACRAFT."
  },
  {
     "type":"value",
     "displayValue":"DENIS WALSH",
     "value":"Make.DENIS WALSH."
  },
  {
     "type":"value",
     "displayValue":"DESTINATION",
     "value":"Make.DESTINATION."
  },
  {
     "type":"value",
     "displayValue":"Devil Cat",
     "value":"Make.Devil Cat."
  },
  {
     "type":"value",
     "displayValue":"DEVONPORT YACHT",
     "value":"Make.DEVONPORT YACHT."
  },
  {
     "type":"value",
     "displayValue":"DIAMOND",
     "value":"Make.DIAMOND."
  },
  {
     "type":"value",
     "displayValue":"Dickey Boats",
     "value":"Make.Dickey Boats."
  },
  {
     "type":"value",
     "displayValue":"DINGHY",
     "value":"Make.DINGHY."
  },
  {
     "type":"value",
     "displayValue":"Divine",
     "value":"Make.Divine."
  },
  {
     "type":"value",
     "displayValue":"DIVINE CRAFT",
     "value":"Make.DIVINE CRAFT."
  },
  {
     "type":"value",
     "displayValue":"DKG Marine",
     "value":"Make.DKG Marine."
  },
  {
     "type":"value",
     "displayValue":"DOLPHIN",
     "value":"Make.DOLPHIN."
  },
  {
     "type":"value",
     "displayValue":"DOMINATOR",
     "value":"Make.DOMINATOR."
  },
  {
     "type":"value",
     "displayValue":"DON BROOKE",
     "value":"Make.DON BROOKE."
  },
  {
     "type":"value",
     "displayValue":"DON JONES",
     "value":"Make.DON JONES."
  },
  {
     "type":"value",
     "displayValue":"DONZI",
     "value":"Make.DONZI."
  },
  {
     "type":"value",
     "displayValue":"Dorado",
     "value":"Make.Dorado."
  },
  {
     "type":"value",
     "displayValue":"DOVELL",
     "value":"Make.DOVELL."
  },
  {
     "type":"value",
     "displayValue":"DRAGONFLY",
     "value":"Make.DRAGONFLY."
  },
  {
     "type":"value",
     "displayValue":"Dreamcatcher",
     "value":"Make.Dreamcatcher."
  },
  {
     "type":"value",
     "displayValue":"DUBOIS",
     "value":"Make.DUBOIS."
  },
  {
     "type":"value",
     "displayValue":"DUCK FLAT WOODEN BOATS",
     "value":"Make.DUCK FLAT WOODEN BOATS."
  },
  {
     "type":"value",
     "displayValue":"DUFOUR YACHTS",
     "value":"Make.DUFOUR YACHTS."
  },
  {
     "type":"value",
     "displayValue":"DUNCANSON",
     "value":"Make.DUNCANSON."
  },
  {
     "type":"value",
     "displayValue":"Dyna",
     "value":"Make.Dyna."
  },
  {
     "type":"value",
     "displayValue":"DYNAMIQ",
     "value":"Make.DYNAMIQ."
  },
  {
     "type":"value",
     "displayValue":"EAGLE CATAMARANS",
     "value":"Make.EAGLE CATAMARANS."
  },
  {
     "type":"value",
     "displayValue":"EASTCOAST",
     "value":"Make.EASTCOAST."
  },
  {
     "type":"value",
     "displayValue":"EASY",
     "value":"Make.EASY."
  },
  {
     "type":"value",
     "displayValue":"EASY RIDER",
     "value":"Make.EASY RIDER."
  },
  {
     "type":"value",
     "displayValue":"EBBTIDE",
     "value":"Make.EBBTIDE."
  },
  {
     "type":"value",
     "displayValue":"ED MONK",
     "value":"Make.ED MONK."
  },
  {
     "type":"value",
     "displayValue":"Edencraft",
     "value":"Make.Edencraft."
  },
  {
     "type":"value",
     "displayValue":"EDGEWATER",
     "value":"Make.EDGEWATER."
  },
  {
     "type":"value",
     "displayValue":"ELAN",
     "value":"Make.ELAN."
  },
  {
     "type":"value",
     "displayValue":"ELITE",
     "value":"Make.ELITE."
  },
  {
     "type":"value",
     "displayValue":"Endeavour",
     "value":"Make.Endeavour."
  },
  {
     "type":"value",
     "displayValue":"Ensign",
     "value":"Make.Ensign."
  },
  {
     "type":"value",
     "displayValue":"ETCHELLS",
     "value":"Make.ETCHELLS."
  },
  {
     "type":"value",
     "displayValue":"EVENTIDE",
     "value":"Make.EVENTIDE."
  },
  {
     "type":"value",
     "displayValue":"Evercraft",
     "value":"Make.Evercraft."
  },
  {
     "type":"value",
     "displayValue":"EVERINGHAM",
     "value":"Make.EVERINGHAM."
  },
  {
     "type":"value",
     "displayValue":"EVINRUDE",
     "value":"Make.EVINRUDE."
  },
  {
     "type":"value",
     "displayValue":"Evo",
     "value":"Make.Evo."
  },
  {
     "type":"value",
     "displayValue":"EVO YACHTS",
     "value":"Make.EVO YACHTS."
  },
  {
     "type":"value",
     "displayValue":"EVOLUTION",
     "value":"Make.EVOLUTION."
  },
  {
     "type":"value",
     "displayValue":"EXCEDO",
     "value":"Make.EXCEDO."
  },
  {
     "type":"value",
     "displayValue":"EXCEL",
     "value":"Make.EXCEL."
  },
  {
     "type":"value",
     "displayValue":"EXCESS CATAMARANS",
     "value":"Make.EXCESS CATAMARANS."
  },
  {
     "type":"value",
     "displayValue":"EXPEDITION",
     "value":"Make.EXPEDITION."
  },
  {
     "type":"value",
     "displayValue":"EXPRESS",
     "value":"Make.EXPRESS."
  },
  {
     "type":"value",
     "displayValue":"EXTREME",
     "value":"Make.EXTREME."
  },
  {
     "type":"value",
     "displayValue":"EZY TOPPER",
     "value":"Make.EZY TOPPER."
  },
  {
     "type":"value",
     "displayValue":"Fairline",
     "value":"Make.Fairline."
  },
  {
     "type":"value",
     "displayValue":"FAIRWAY",
     "value":"Make.FAIRWAY."
  },
  {
     "type":"value",
     "displayValue":"Falcon",
     "value":"Make.Falcon."
  },
  {
     "type":"value",
     "displayValue":"FALCON INFLATABLES",
     "value":"Make.FALCON INFLATABLES."
  },
  {
     "type":"value",
     "displayValue":"Fareast",
     "value":"Make.Fareast."
  },
  {
     "type":"value",
     "displayValue":"FARR",
     "value":"Make.FARR."
  },
  {
     "type":"value",
     "displayValue":"FARRIER",
     "value":"Make.FARRIER."
  },
  {
     "type":"value",
     "displayValue":"FAST",
     "value":"Make.FAST."
  },
  {
     "type":"value",
     "displayValue":"FASTBACK",
     "value":"Make.FASTBACK."
  },
  {
     "type":"value",
     "displayValue":"FEELING",
     "value":"Make.FEELING."
  },
  {
     "type":"value",
     "displayValue":"FERRETTI",
     "value":"Make.FERRETTI."
  },
  {
     "type":"value",
     "displayValue":"FERRY",
     "value":"Make.FERRY."
  },
  {
     "type":"value",
     "displayValue":"FI-GLASS",
     "value":"Make.FI-GLASS."
  },
  {
     "type":"value",
     "displayValue":"FIBRAFORT",
     "value":"Make.FIBRAFORT."
  },
  {
     "type":"value",
     "displayValue":"FIBREGLASS",
     "value":"Make.FIBREGLASS."
  },
  {
     "type":"value",
     "displayValue":"FIBREMASTER",
     "value":"Make.FIBREMASTER."
  },
  {
     "type":"value",
     "displayValue":"FIDDLER",
     "value":"Make.FIDDLER."
  },
  {
     "type":"value",
     "displayValue":"FINE ENTRY",
     "value":"Make.FINE ENTRY."
  },
  {
     "type":"value",
     "displayValue":"FIREBIRD",
     "value":"Make.FIREBIRD."
  },
  {
     "type":"value",
     "displayValue":"FISHER",
     "value":"Make.FISHER."
  },
  {
     "type":"value",
     "displayValue":"FISHING BOAT",
     "value":"Make.FISHING BOAT."
  },
  {
     "type":"value",
     "displayValue":"Fjord",
     "value":"Make.Fjord."
  },
  {
     "type":"value",
     "displayValue":"Flagship",
     "value":"Make.Flagship."
  },
  {
     "type":"value",
     "displayValue":"FLEMING",
     "value":"Make.FLEMING."
  },
  {
     "type":"value",
     "displayValue":"Flightcraft",
     "value":"Make.Flightcraft."
  },
  {
     "type":"value",
     "displayValue":"FLINDERS",
     "value":"Make.FLINDERS."
  },
  {
     "type":"value",
     "displayValue":"FLIPPER BOATS",
     "value":"Make.FLIPPER BOATS."
  },
  {
     "type":"value",
     "displayValue":"FLORIDA BAY COASTER",
     "value":"Make.FLORIDA BAY COASTER."
  },
  {
     "type":"value",
     "displayValue":"FLYCRAFT",
     "value":"Make.FLYCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Flying Fifteen",
     "value":"Make.Flying Fifteen."
  },
  {
     "type":"value",
     "displayValue":"FOCUS MOTOR YACHTS",
     "value":"Make.FOCUS MOTOR YACHTS."
  },
  {
     "type":"value",
     "displayValue":"Force",
     "value":"Make.Force."
  },
  {
     "type":"value",
     "displayValue":"FORCE 4",
     "value":"Make.FORCE 4."
  },
  {
     "type":"value",
     "displayValue":"Formosa",
     "value":"Make.Formosa."
  },
  {
     "type":"value",
     "displayValue":"Formula",
     "value":"Make.Formula."
  },
  {
     "type":"value",
     "displayValue":"FOUNDATION",
     "value":"Make.FOUNDATION."
  },
  {
     "type":"value",
     "displayValue":"Fountaine Pajot",
     "value":"Make.Fountaine Pajot."
  },
  {
     "type":"value",
     "displayValue":"FOUR WINNS",
     "value":"Make.FOUR WINNS."
  },
  {
     "type":"value",
     "displayValue":"Fraser",
     "value":"Make.Fraser."
  },
  {
     "type":"value",
     "displayValue":"FRED FLEMING",
     "value":"Make.FRED FLEMING."
  },
  {
     "type":"value",
     "displayValue":"FREDERICK PARKER",
     "value":"Make.FREDERICK PARKER."
  },
  {
     "type":"value",
     "displayValue":"Freedom",
     "value":"Make.Freedom."
  },
  {
     "type":"value",
     "displayValue":"FREEMAN BAY",
     "value":"Make.FREEMAN BAY."
  },
  {
     "type":"value",
     "displayValue":"G\u0026S Boats",
     "value":"Make.G\u0026S Boats."
  },
  {
     "type":"value",
     "displayValue":"G3 BOATS",
     "value":"Make.G3 BOATS."
  },
  {
     "type":"value",
     "displayValue":"GAFF RIGGED",
     "value":"Make.GAFF RIGGED."
  },
  {
     "type":"value",
     "displayValue":"GALE FORCE",
     "value":"Make.GALE FORCE."
  },
  {
     "type":"value",
     "displayValue":"GALEON YACHTS",
     "value":"Make.GALEON YACHTS."
  },
  {
     "type":"value",
     "displayValue":"GANNET",
     "value":"Make.GANNET."
  },
  {
     "type":"value",
     "displayValue":"Garcia",
     "value":"Make.Garcia."
  },
  {
     "type":"value",
     "displayValue":"GBE",
     "value":"Make.GBE."
  },
  {
     "type":"value",
     "displayValue":"GEMINI",
     "value":"Make.GEMINI."
  },
  {
     "type":"value",
     "displayValue":"GETA",
     "value":"Make.GETA."
  },
  {
     "type":"value",
     "displayValue":"GILCRAFT",
     "value":"Make.GILCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Gilflite",
     "value":"Make.Gilflite."
  },
  {
     "type":"value",
     "displayValue":"GLACIER BAY",
     "value":"Make.GLACIER BAY."
  },
  {
     "type":"value",
     "displayValue":"GLASSTREAM",
     "value":"Make.GLASSTREAM."
  },
  {
     "type":"value",
     "displayValue":"Glastron",
     "value":"Make.Glastron."
  },
  {
     "type":"value",
     "displayValue":"GLEN L MARINE",
     "value":"Make.GLEN L MARINE."
  },
  {
     "type":"value",
     "displayValue":"Glorious Yachts",
     "value":"Make.Glorious Yachts."
  },
  {
     "type":"value",
     "displayValue":"GODFREY",
     "value":"Make.GODFREY."
  },
  {
     "type":"value",
     "displayValue":"GOLD COAST SHIPS",
     "value":"Make.GOLD COAST SHIPS."
  },
  {
     "type":"value",
     "displayValue":"GOLDSTAR",
     "value":"Make.GOLDSTAR."
  },
  {
     "type":"value",
     "displayValue":"GOOLWACRAFT",
     "value":"Make.GOOLWACRAFT."
  },
  {
     "type":"value",
     "displayValue":"GOZZO SCHIAFFINO",
     "value":"Make.GOZZO SCHIAFFINO."
  },
  {
     "type":"value",
     "displayValue":"GR PONTOON",
     "value":"Make.GR PONTOON."
  },
  {
     "type":"value",
     "displayValue":"GRADY-WHITE",
     "value":"Make.GRADY-WHITE."
  },
  {
     "type":"value",
     "displayValue":"Grand",
     "value":"Make.Grand."
  },
  {
     "type":"value",
     "displayValue":"GRAND BANKS",
     "value":"Make.GRAND BANKS."
  },
  {
     "type":"value",
     "displayValue":"Grand Harbour",
     "value":"Make.Grand Harbour."
  },
  {
     "type":"value",
     "displayValue":"GRAND SOLEIL",
     "value":"Make.GRAND SOLEIL."
  },
  {
     "type":"value",
     "displayValue":"GRANOCEAN",
     "value":"Make.GRANOCEAN."
  },
  {
     "type":"value",
     "displayValue":"GRAYWILL",
     "value":"Make.GRAYWILL."
  },
  {
     "type":"value",
     "displayValue":"GREENLINE",
     "value":"Make.GREENLINE."
  },
  {
     "type":"value",
     "displayValue":"GRIFFIN",
     "value":"Make.GRIFFIN."
  },
  {
     "type":"value",
     "displayValue":"GULF CRAFT",
     "value":"Make.GULF CRAFT."
  },
  {
     "type":"value",
     "displayValue":"GULL WING",
     "value":"Make.GULL WING."
  },
  {
     "type":"value",
     "displayValue":"GUY COUACH",
     "value":"Make.GUY COUACH."
  },
  {
     "type":"value",
     "displayValue":"H:Craft",
     "value":"Make.H:Craft."
  },
  {
     "type":"value",
     "displayValue":"H28",
     "value":"Make.H28."
  },
  {
     "type":"value",
     "displayValue":"HAINES",
     "value":"Make.HAINES."
  },
  {
     "type":"value",
     "displayValue":"Haines Hunter",
     "value":"Make.Haines Hunter."
  },
  {
     "type":"value",
     "displayValue":"HAINES SIGNATURE",
     "value":"Make.HAINES SIGNATURE."
  },
  {
     "type":"value",
     "displayValue":"Haines Traveller",
     "value":"Make.Haines Traveller."
  },
  {
     "type":"value",
     "displayValue":"HALLBERG-RASSY",
     "value":"Make.HALLBERG-RASSY."
  },
  {
     "type":"value",
     "displayValue":"Hallett",
     "value":"Make.Hallett."
  },
  {
     "type":"value",
     "displayValue":"Halvorsen",
     "value":"Make.Halvorsen."
  },
  {
     "type":"value",
     "displayValue":"Hames",
     "value":"Make.Hames."
  },
  {
     "type":"value",
     "displayValue":"HAMMOND",
     "value":"Make.HAMMOND."
  },
  {
     "type":"value",
     "displayValue":"Hampton",
     "value":"Make.Hampton."
  },
  {
     "type":"value",
     "displayValue":"HANKINSON",
     "value":"Make.HANKINSON."
  },
  {
     "type":"value",
     "displayValue":"HANSE",
     "value":"Make.HANSE."
  },
  {
     "type":"value",
     "displayValue":"HARRIS",
     "value":"Make.HARRIS."
  },
  {
     "type":"value",
     "displayValue":"HARRISCRAFT",
     "value":"Make.HARRISCRAFT."
  },
  {
     "type":"value",
     "displayValue":"HARTLEY",
     "value":"Make.HARTLEY."
  },
  {
     "type":"value",
     "displayValue":"HATTERAS",
     "value":"Make.HATTERAS."
  },
  {
     "type":"value",
     "displayValue":"Hayes",
     "value":"Make.Hayes."
  },
  {
     "type":"value",
     "displayValue":"Hayes \u0026 Sons",
     "value":"Make.Hayes \u0026 Sons."
  },
  {
     "type":"value",
     "displayValue":"HEDGES",
     "value":"Make.HEDGES."
  },
  {
     "type":"value",
     "displayValue":"HEESEN YACHTS",
     "value":"Make.HEESEN YACHTS."
  },
  {
     "type":"value",
     "displayValue":"HELIOTROPE",
     "value":"Make.HELIOTROPE."
  },
  {
     "type":"value",
     "displayValue":"HERRESHOFF",
     "value":"Make.HERRESHOFF."
  },
  {
     "type":"value",
     "displayValue":"HERSHINE",
     "value":"Make.HERSHINE."
  },
  {
     "type":"value",
     "displayValue":"HEWES",
     "value":"Make.HEWES."
  },
  {
     "type":"value",
     "displayValue":"Heysea",
     "value":"Make.Heysea."
  },
  {
     "type":"value",
     "displayValue":"HI STAR",
     "value":"Make.HI STAR."
  },
  {
     "type":"value",
     "displayValue":"HIGH SEAS",
     "value":"Make.HIGH SEAS."
  },
  {
     "type":"value",
     "displayValue":"HIGH SEAS EXPLORER",
     "value":"Make.HIGH SEAS EXPLORER."
  },
  {
     "type":"value",
     "displayValue":"HIGHFIELD",
     "value":"Make.HIGHFIELD."
  },
  {
     "type":"value",
     "displayValue":"HINTON",
     "value":"Make.HINTON."
  },
  {
     "type":"value",
     "displayValue":"Hobie",
     "value":"Make.Hobie."
  },
  {
     "type":"value",
     "displayValue":"Holland",
     "value":"Make.Holland."
  },
  {
     "type":"value",
     "displayValue":"HOMECRUISER",
     "value":"Make.HOMECRUISER."
  },
  {
     "type":"value",
     "displayValue":"Honda",
     "value":"Make.Honda."
  },
  {
     "type":"value",
     "displayValue":"HOOD",
     "value":"Make.HOOD."
  },
  {
     "type":"value",
     "displayValue":"Hoodlum",
     "value":"Make.Hoodlum."
  },
  {
     "type":"value",
     "displayValue":"Hooked On Boats",
     "value":"Make.Hooked On Boats."
  },
  {
     "type":"value",
     "displayValue":"HOOKER",
     "value":"Make.HOOKER."
  },
  {
     "type":"value",
     "displayValue":"Horace Tate",
     "value":"Make.Horace Tate."
  },
  {
     "type":"value",
     "displayValue":"Horizon",
     "value":"Make.Horizon."
  },
  {
     "type":"value",
     "displayValue":"HORIZON YACHT",
     "value":"Make.HORIZON YACHT."
  },
  {
     "type":"value",
     "displayValue":"HOUSEBOAT",
     "value":"Make.HOUSEBOAT."
  },
  {
     "type":"value",
     "displayValue":"HUGH MORRIS",
     "value":"Make.HUGH MORRIS."
  },
  {
     "type":"value",
     "displayValue":"HUNTER",
     "value":"Make.HUNTER."
  },
  {
     "type":"value",
     "displayValue":"Huntsman",
     "value":"Make.Huntsman."
  },
  {
     "type":"value",
     "displayValue":"HUON PINE",
     "value":"Make.HUON PINE."
  },
  {
     "type":"value",
     "displayValue":"HURRICANE",
     "value":"Make.HURRICANE."
  },
  {
     "type":"value",
     "displayValue":"HUSTLER",
     "value":"Make.HUSTLER."
  },
  {
     "type":"value",
     "displayValue":"HUTTON",
     "value":"Make.HUTTON."
  },
  {
     "type":"value",
     "displayValue":"HYATT",
     "value":"Make.HYATT."
  },
  {
     "type":"value",
     "displayValue":"HYDRA-SPORTS",
     "value":"Make.HYDRA-SPORTS."
  },
  {
     "type":"value",
     "displayValue":"HYDRO MARINE",
     "value":"Make.HYDRO MARINE."
  },
  {
     "type":"value",
     "displayValue":"Hydrofield",
     "value":"Make.Hydrofield."
  },
  {
     "type":"value",
     "displayValue":"HYLAS",
     "value":"Make.HYLAS."
  },
  {
     "type":"value",
     "displayValue":"Hyundai",
     "value":"Make.Hyundai."
  },
  {
     "type":"value",
     "displayValue":"IAG YACHTS",
     "value":"Make.IAG YACHTS."
  },
  {
     "type":"value",
     "displayValue":"ICE YACHTS",
     "value":"Make.ICE YACHTS."
  },
  {
     "type":"value",
     "displayValue":"Iluka Yachts",
     "value":"Make.Iluka Yachts."
  },
  {
     "type":"value",
     "displayValue":"IMAGE",
     "value":"Make.IMAGE."
  },
  {
     "type":"value",
     "displayValue":"IMEXUS",
     "value":"Make.IMEXUS."
  },
  {
     "type":"value",
     "displayValue":"Incat Crowther",
     "value":"Make.Incat Crowther."
  },
  {
     "type":"value",
     "displayValue":"INFLATABLES",
     "value":"Make.INFLATABLES."
  },
  {
     "type":"value",
     "displayValue":"INFLATABLES / DINGHIES",
     "value":"Make.INFLATABLES / DINGHIES."
  },
  {
     "type":"value",
     "displayValue":"INGLIS",
     "value":"Make.INGLIS."
  },
  {
     "type":"value",
     "displayValue":"INNOVATION",
     "value":"Make.INNOVATION."
  },
  {
     "type":"value",
     "displayValue":"Integrity",
     "value":"Make.Integrity."
  },
  {
     "type":"value",
     "displayValue":"International",
     "value":"Make.International."
  },
  {
     "type":"value",
     "displayValue":"INVICTUS",
     "value":"Make.INVICTUS."
  },
  {
     "type":"value",
     "displayValue":"INVINCIBLE BOATS",
     "value":"Make.INVINCIBLE BOATS."
  },
  {
     "type":"value",
     "displayValue":"IRWIN",
     "value":"Make.IRWIN."
  },
  {
     "type":"value",
     "displayValue":"ISLAND GYPSY",
     "value":"Make.ISLAND GYPSY."
  },
  {
     "type":"value",
     "displayValue":"ISLAND INFLATABLES",
     "value":"Make.ISLAND INFLATABLES."
  },
  {
     "type":"value",
     "displayValue":"ISLAND PACKET",
     "value":"Make.ISLAND PACKET."
  },
  {
     "type":"value",
     "displayValue":"ISLAND SPIRIT CATAMARAN",
     "value":"Make.ISLAND SPIRIT CATAMARAN."
  },
  {
     "type":"value",
     "displayValue":"ITAC MARINE",
     "value":"Make.ITAC MARINE."
  },
  {
     "type":"value",
     "displayValue":"ITALBOATS",
     "value":"Make.ITALBOATS."
  },
  {
     "type":"value",
     "displayValue":"ITALIA YACHTS",
     "value":"Make.ITALIA YACHTS."
  },
  {
     "type":"value",
     "displayValue":"ITAMA",
     "value":"Make.ITAMA."
  },
  {
     "type":"value",
     "displayValue":"J Boats",
     "value":"Make.J Boats."
  },
  {
     "type":"value",
     "displayValue":"JACANA",
     "value":"Make.JACANA."
  },
  {
     "type":"value",
     "displayValue":"JACK EDDIE",
     "value":"Make.JACK EDDIE."
  },
  {
     "type":"value",
     "displayValue":"JACKMAN",
     "value":"Make.JACKMAN."
  },
  {
     "type":"value",
     "displayValue":"JADE YACHTS",
     "value":"Make.JADE YACHTS."
  },
  {
     "type":"value",
     "displayValue":"JAVELIN",
     "value":"Make.JAVELIN."
  },
  {
     "type":"value",
     "displayValue":"JBS Marine",
     "value":"Make.JBS Marine."
  },
  {
     "type":"value",
     "displayValue":"JEABA CRAFT",
     "value":"Make.JEABA CRAFT."
  },
  {
     "type":"value",
     "displayValue":"JEANNEAU",
     "value":"Make.JEANNEAU."
  },
  {
     "type":"value",
     "displayValue":"JENKS CRAFT",
     "value":"Make.JENKS CRAFT."
  },
  {
     "type":"value",
     "displayValue":"JET SKI",
     "value":"Make.JET SKI."
  },
  {
     "type":"value",
     "displayValue":"JFA CHANTIER NAVAL",
     "value":"Make.JFA CHANTIER NAVAL."
  },
  {
     "type":"value",
     "displayValue":"JJ SAVAGE \u0026 SONS",
     "value":"Make.JJ SAVAGE \u0026 SONS."
  },
  {
     "type":"value",
     "displayValue":"JOCK MUIR",
     "value":"Make.JOCK MUIR."
  },
  {
     "type":"value",
     "displayValue":"JOHN ANDERTON",
     "value":"Make.JOHN ANDERTON."
  },
  {
     "type":"value",
     "displayValue":"JOHN DENGATE",
     "value":"Make.JOHN DENGATE."
  },
  {
     "type":"value",
     "displayValue":"JOHN PUGH",
     "value":"Make.JOHN PUGH."
  },
  {
     "type":"value",
     "displayValue":"Johnson",
     "value":"Make.Johnson."
  },
  {
     "type":"value",
     "displayValue":"JOUBERT",
     "value":"Make.JOUBERT."
  },
  {
     "type":"value",
     "displayValue":"JUTSON",
     "value":"Make.JUTSON."
  },
  {
     "type":"value",
     "displayValue":"KAIZEN",
     "value":"Make.KAIZEN."
  },
  {
     "type":"value",
     "displayValue":"KAOS",
     "value":"Make.KAOS."
  },
  {
     "type":"value",
     "displayValue":"KARNIC",
     "value":"Make.KARNIC."
  },
  {
     "type":"value",
     "displayValue":"Kawasaki",
     "value":"Make.Kawasaki."
  },
  {
     "type":"value",
     "displayValue":"KAYSER",
     "value":"Make.KAYSER."
  },
  {
     "type":"value",
     "displayValue":"KELLICK",
     "value":"Make.KELLICK."
  },
  {
     "type":"value",
     "displayValue":"Kelly Peterson",
     "value":"Make.Kelly Peterson."
  },
  {
     "type":"value",
     "displayValue":"KEVLACAT",
     "value":"Make.KEVLACAT."
  },
  {
     "type":"value",
     "displayValue":"KEY WEST",
     "value":"Make.KEY WEST."
  },
  {
     "type":"value",
     "displayValue":"KINGCAT",
     "value":"Make.KINGCAT."
  },
  {
     "type":"value",
     "displayValue":"KINGFISHER",
     "value":"Make.KINGFISHER."
  },
  {
     "type":"value",
     "displayValue":"KINGFISHER ROYAL",
     "value":"Make.KINGFISHER ROYAL."
  },
  {
     "type":"value",
     "displayValue":"KINGSHIP",
     "value":"Make.KINGSHIP."
  },
  {
     "type":"value",
     "displayValue":"KINGSTON",
     "value":"Make.KINGSTON."
  },
  {
     "type":"value",
     "displayValue":"KLIESE",
     "value":"Make.KLIESE."
  },
  {
     "type":"value",
     "displayValue":"KNOOP",
     "value":"Make.KNOOP."
  },
  {
     "type":"value",
     "displayValue":"KNUD REIMERS",
     "value":"Make.KNUD REIMERS."
  },
  {
     "type":"value",
     "displayValue":"KONG HALVORSEN",
     "value":"Make.KONG HALVORSEN."
  },
  {
     "type":"value",
     "displayValue":"Krash Industries",
     "value":"Make.Krash Industries."
  },
  {
     "type":"value",
     "displayValue":"KUIPERS DOGGERSBANK",
     "value":"Make.KUIPERS DOGGERSBANK."
  },
  {
     "type":"value",
     "displayValue":"Kwikkraft",
     "value":"Make.Kwikkraft."
  },
  {
     "type":"value",
     "displayValue":"LABSPORT",
     "value":"Make.LABSPORT."
  },
  {
     "type":"value",
     "displayValue":"LACCO",
     "value":"Make.LACCO."
  },
  {
     "type":"value",
     "displayValue":"LAGOON",
     "value":"Make.LAGOON."
  },
  {
     "type":"value",
     "displayValue":"LARC",
     "value":"Make.LARC."
  },
  {
     "type":"value",
     "displayValue":"Larson",
     "value":"Make.Larson."
  },
  {
     "type":"value",
     "displayValue":"LASER",
     "value":"Make.LASER."
  },
  {
     "type":"value",
     "displayValue":"LAURENT GILES",
     "value":"Make.LAURENT GILES."
  },
  {
     "type":"value",
     "displayValue":"LAURIE DAVIDSON",
     "value":"Make.LAURIE DAVIDSON."
  },
  {
     "type":"value",
     "displayValue":"LAURIN",
     "value":"Make.LAURIN."
  },
  {
     "type":"value",
     "displayValue":"LAVEYCRAFT",
     "value":"Make.LAVEYCRAFT."
  },
  {
     "type":"value",
     "displayValue":"LAZZARA",
     "value":"Make.LAZZARA."
  },
  {
     "type":"value",
     "displayValue":"LCT",
     "value":"Make.LCT."
  },
  {
     "type":"value",
     "displayValue":"LEEDER",
     "value":"Make.LEEDER."
  },
  {
     "type":"value",
     "displayValue":"LEGEND BOATS",
     "value":"Make.LEGEND BOATS."
  },
  {
     "type":"value",
     "displayValue":"LEIGHT NOTIKA",
     "value":"Make.LEIGHT NOTIKA."
  },
  {
     "type":"value",
     "displayValue":"LEISURECAT",
     "value":"Make.LEISURECAT."
  },
  {
     "type":"value",
     "displayValue":"LEISURECRAFT",
     "value":"Make.LEISURECRAFT."
  },
  {
     "type":"value",
     "displayValue":"LEN HEDGES",
     "value":"Make.LEN HEDGES."
  },
  {
     "type":"value",
     "displayValue":"LEONARDO YACHTS",
     "value":"Make.LEONARDO YACHTS."
  },
  {
     "type":"value",
     "displayValue":"LEOPARD",
     "value":"Make.LEOPARD."
  },
  {
     "type":"value",
     "displayValue":"LEOPARD CATAMARANS",
     "value":"Make.LEOPARD CATAMARANS."
  },
  {
     "type":"value",
     "displayValue":"LEWIS BOATS",
     "value":"Make.LEWIS BOATS."
  },
  {
     "type":"value",
     "displayValue":"LEWIS SKI BOATS",
     "value":"Make.LEWIS SKI BOATS."
  },
  {
     "type":"value",
     "displayValue":"LIDGARD",
     "value":"Make.LIDGARD."
  },
  {
     "type":"value",
     "displayValue":"LIFESTYLE",
     "value":"Make.LIFESTYLE."
  },
  {
     "type":"value",
     "displayValue":"LIGHTWAVE",
     "value":"Make.LIGHTWAVE."
  },
  {
     "type":"value",
     "displayValue":"LLOYD",
     "value":"Make.LLOYD."
  },
  {
     "type":"value",
     "displayValue":"LOGAN",
     "value":"Make.LOGAN."
  },
  {
     "type":"value",
     "displayValue":"LOMAC",
     "value":"Make.LOMAC."
  },
  {
     "type":"value",
     "displayValue":"LOTUS",
     "value":"Make.LOTUS."
  },
  {
     "type":"value",
     "displayValue":"LTN",
     "value":"Make.LTN."
  },
  {
     "type":"value",
     "displayValue":"LUCAS",
     "value":"Make.LUCAS."
  },
  {
     "type":"value",
     "displayValue":"Luhrs",
     "value":"Make.Luhrs."
  },
  {
     "type":"value",
     "displayValue":"LYCREST",
     "value":"Make.LYCREST."
  },
  {
     "type":"value",
     "displayValue":"Lyons",
     "value":"Make.Lyons."
  },
  {
     "type":"value",
     "displayValue":"MACGREGOR",
     "value":"Make.MACGREGOR."
  },
  {
     "type":"value",
     "displayValue":"MACHO",
     "value":"Make.MACHO."
  },
  {
     "type":"value",
     "displayValue":"MACKENZIE",
     "value":"Make.MACKENZIE."
  },
  {
     "type":"value",
     "displayValue":"MADISON",
     "value":"Make.MADISON."
  },
  {
     "type":"value",
     "displayValue":"Magazzu",
     "value":"Make.Magazzu."
  },
  {
     "type":"value",
     "displayValue":"MAGIC",
     "value":"Make.MAGIC."
  },
  {
     "type":"value",
     "displayValue":"MAGNUM",
     "value":"Make.MAGNUM."
  },
  {
     "type":"value",
     "displayValue":"MAINSHIP",
     "value":"Make.MAINSHIP."
  },
  {
     "type":"value",
     "displayValue":"MAIORA",
     "value":"Make.MAIORA."
  },
  {
     "type":"value",
     "displayValue":"MAJESTY YACHTS",
     "value":"Make.MAJESTY YACHTS."
  },
  {
     "type":"value",
     "displayValue":"Mako",
     "value":"Make.Mako."
  },
  {
     "type":"value",
     "displayValue":"MAKOCRAFT",
     "value":"Make.MAKOCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Mallards",
     "value":"Make.Mallards."
  },
  {
     "type":"value",
     "displayValue":"MANGROVE JACK",
     "value":"Make.MANGROVE JACK."
  },
  {
     "type":"value",
     "displayValue":"MANGUSTA",
     "value":"Make.MANGUSTA."
  },
  {
     "type":"value",
     "displayValue":"MANITOU",
     "value":"Make.MANITOU."
  },
  {
     "type":"value",
     "displayValue":"Manta",
     "value":"Make.Manta."
  },
  {
     "type":"value",
     "displayValue":"MAPLE LEAF",
     "value":"Make.MAPLE LEAF."
  },
  {
     "type":"value",
     "displayValue":"MARAUDER",
     "value":"Make.MARAUDER."
  },
  {
     "type":"value",
     "displayValue":"MARCO",
     "value":"Make.MARCO."
  },
  {
     "type":"value",
     "displayValue":"MARES",
     "value":"Make.MARES."
  },
  {
     "type":"value",
     "displayValue":"Marex",
     "value":"Make.Marex."
  },
  {
     "type":"value",
     "displayValue":"Mariah",
     "value":"Make.Mariah."
  },
  {
     "type":"value",
     "displayValue":"MARINA BERTH",
     "value":"Make.MARINA BERTH."
  },
  {
     "type":"value",
     "displayValue":"Mariner",
     "value":"Make.Mariner."
  },
  {
     "type":"value",
     "displayValue":"MARITIMO",
     "value":"Make.MARITIMO."
  },
  {
     "type":"value",
     "displayValue":"MARK ELLIS DESIGN",
     "value":"Make.MARK ELLIS DESIGN."
  },
  {
     "type":"value",
     "displayValue":"Markham",
     "value":"Make.Markham."
  },
  {
     "type":"value",
     "displayValue":"Markham Whaler",
     "value":"Make.Markham Whaler."
  },
  {
     "type":"value",
     "displayValue":"MARKLINE",
     "value":"Make.MARKLINE."
  },
  {
     "type":"value",
     "displayValue":"MARKO",
     "value":"Make.MARKO."
  },
  {
     "type":"value",
     "displayValue":"MARKO SAMBRAILO",
     "value":"Make.MARKO SAMBRAILO."
  },
  {
     "type":"value",
     "displayValue":"MARLIN",
     "value":"Make.MARLIN."
  },
  {
     "type":"value",
     "displayValue":"Marlin Broadbill",
     "value":"Make.Marlin Broadbill."
  },
  {
     "type":"value",
     "displayValue":"MARLOW YACHTS",
     "value":"Make.MARLOW YACHTS."
  },
  {
     "type":"value",
     "displayValue":"Marshall Lord",
     "value":"Make.Marshall Lord."
  },
  {
     "type":"value",
     "displayValue":"MASRM",
     "value":"Make.MASRM."
  },
  {
     "type":"value",
     "displayValue":"MasterCraft",
     "value":"Make.MasterCraft."
  },
  {
     "type":"value",
     "displayValue":"MASTERS",
     "value":"Make.MASTERS."
  },
  {
     "type":"value",
     "displayValue":"MATRIX",
     "value":"Make.MATRIX."
  },
  {
     "type":"value",
     "displayValue":"MAURICE GRIFFITHS",
     "value":"Make.MAURICE GRIFFITHS."
  },
  {
     "type":"value",
     "displayValue":"MAVERICK",
     "value":"Make.MAVERICK."
  },
  {
     "type":"value",
     "displayValue":"MAX CREESE",
     "value":"Make.MAX CREESE."
  },
  {
     "type":"value",
     "displayValue":"MAXICAT",
     "value":"Make.MAXICAT."
  },
  {
     "type":"value",
     "displayValue":"MAXIM",
     "value":"Make.MAXIM."
  },
  {
     "type":"value",
     "displayValue":"Maxum",
     "value":"Make.Maxum."
  },
  {
     "type":"value",
     "displayValue":"MB Boats",
     "value":"Make.MB Boats."
  },
  {
     "type":"value",
     "displayValue":"MCDONALD-SMITH",
     "value":"Make.MCDONALD-SMITH."
  },
  {
     "type":"value",
     "displayValue":"Mcgregor",
     "value":"Make.Mcgregor."
  },
  {
     "type":"value",
     "displayValue":"McLaren",
     "value":"Make.McLaren."
  },
  {
     "type":"value",
     "displayValue":"MCLAY",
     "value":"Make.MCLAY."
  },
  {
     "type":"value",
     "displayValue":"MCMULLEN AND WING",
     "value":"Make.MCMULLEN AND WING."
  },
  {
     "type":"value",
     "displayValue":"MEC",
     "value":"Make.MEC."
  },
  {
     "type":"value",
     "displayValue":"MELGES",
     "value":"Make.MELGES."
  },
  {
     "type":"value",
     "displayValue":"MEOS",
     "value":"Make.MEOS."
  },
  {
     "type":"value",
     "displayValue":"Mercury",
     "value":"Make.Mercury."
  },
  {
     "type":"value",
     "displayValue":"MERCURY MERCRUISER",
     "value":"Make.MERCURY MERCRUISER."
  },
  {
     "type":"value",
     "displayValue":"Meridian",
     "value":"Make.Meridian."
  },
  {
     "type":"value",
     "displayValue":"MERLIN",
     "value":"Make.MERLIN."
  },
  {
     "type":"value",
     "displayValue":"Midcoast Fabrication Marine",
     "value":"Make.Midcoast Fabrication Marine."
  },
  {
     "type":"value",
     "displayValue":"MILLENIUM",
     "value":"Make.MILLENIUM."
  },
  {
     "type":"value",
     "displayValue":"MILLER AND WHITWORTH",
     "value":"Make.MILLER AND WHITWORTH."
  },
  {
     "type":"value",
     "displayValue":"MILLIGAN",
     "value":"Make.MILLIGAN."
  },
  {
     "type":"value",
     "displayValue":"MILLKRAFT",
     "value":"Make.MILLKRAFT."
  },
  {
     "type":"value",
     "displayValue":"MILLMAN",
     "value":"Make.MILLMAN."
  },
  {
     "type":"value",
     "displayValue":"MINI TRANSAT",
     "value":"Make.MINI TRANSAT."
  },
  {
     "type":"value",
     "displayValue":"MINNOW",
     "value":"Make.MINNOW."
  },
  {
     "type":"value",
     "displayValue":"MIRAGE",
     "value":"Make.MIRAGE."
  },
  {
     "type":"value",
     "displayValue":"MISTY HARBOR",
     "value":"Make.MISTY HARBOR."
  },
  {
     "type":"value",
     "displayValue":"MITHRAL MARINE",
     "value":"Make.MITHRAL MARINE."
  },
  {
     "type":"value",
     "displayValue":"MOBY",
     "value":"Make.MOBY."
  },
  {
     "type":"value",
     "displayValue":"MODA",
     "value":"Make.MODA."
  },
  {
     "type":"value",
     "displayValue":"MONACO",
     "value":"Make.MONACO."
  },
  {
     "type":"value",
     "displayValue":"MONARK",
     "value":"Make.MONARK."
  },
  {
     "type":"value",
     "displayValue":"MONTE CARLO",
     "value":"Make.MONTE CARLO."
  },
  {
     "type":"value",
     "displayValue":"MONTE CARLO YACHTS",
     "value":"Make.MONTE CARLO YACHTS."
  },
  {
     "type":"value",
     "displayValue":"MONTE FINO",
     "value":"Make.MONTE FINO."
  },
  {
     "type":"value",
     "displayValue":"Monterey",
     "value":"Make.Monterey."
  },
  {
     "type":"value",
     "displayValue":"MOODY",
     "value":"Make.MOODY."
  },
  {
     "type":"value",
     "displayValue":"MOOMBA",
     "value":"Make.MOOMBA."
  },
  {
     "type":"value",
     "displayValue":"Moonen",
     "value":"Make.Moonen."
  },
  {
     "type":"value",
     "displayValue":"MORNINGSTAR",
     "value":"Make.MORNINGSTAR."
  },
  {
     "type":"value",
     "displayValue":"MOTOR CRUISER",
     "value":"Make.MOTOR CRUISER."
  },
  {
     "type":"value",
     "displayValue":"MOTOR GUIDE",
     "value":"Make.MOTOR GUIDE."
  },
  {
     "type":"value",
     "displayValue":"MOTORSAILER",
     "value":"Make.MOTORSAILER."
  },
  {
     "type":"value",
     "displayValue":"MOTTLE",
     "value":"Make.MOTTLE."
  },
  {
     "type":"value",
     "displayValue":"MULDER",
     "value":"Make.MULDER."
  },
  {
     "type":"value",
     "displayValue":"MURRAY",
     "value":"Make.MURRAY."
  },
  {
     "type":"value",
     "displayValue":"MURRAY BURNS \u0026 DOVELL",
     "value":"Make.MURRAY BURNS \u0026 DOVELL."
  },
  {
     "type":"value",
     "displayValue":"MUSTANG",
     "value":"Make.MUSTANG."
  },
  {
     "type":"value",
     "displayValue":"NACRA",
     "value":"Make.NACRA."
  },
  {
     "type":"value",
     "displayValue":"NAIAD",
     "value":"Make.NAIAD."
  },
  {
     "type":"value",
     "displayValue":"NANKERVIS",
     "value":"Make.NANKERVIS."
  },
  {
     "type":"value",
     "displayValue":"NANTUCKET",
     "value":"Make.NANTUCKET."
  },
  {
     "type":"value",
     "displayValue":"NATWELL",
     "value":"Make.NATWELL."
  },
  {
     "type":"value",
     "displayValue":"Nautica",
     "value":"Make.Nautica."
  },
  {
     "type":"value",
     "displayValue":"NAUTICA LED",
     "value":"Make.NAUTICA LED."
  },
  {
     "type":"value",
     "displayValue":"NAUTICAT",
     "value":"Make.NAUTICAT."
  },
  {
     "type":"value",
     "displayValue":"NAUTICSTAR",
     "value":"Make.NAUTICSTAR."
  },
  {
     "type":"value",
     "displayValue":"NAUTICSTAR USA",
     "value":"Make.NAUTICSTAR USA."
  },
  {
     "type":"value",
     "displayValue":"NAUTIGLASS",
     "value":"Make.NAUTIGLASS."
  },
  {
     "type":"value",
     "displayValue":"NAUTIQUE",
     "value":"Make.NAUTIQUE."
  },
  {
     "type":"value",
     "displayValue":"NAUTITECH",
     "value":"Make.NAUTITECH."
  },
  {
     "type":"value",
     "displayValue":"Nautor Swan",
     "value":"Make.Nautor Swan."
  },
  {
     "type":"value",
     "displayValue":"NAVIGATOR",
     "value":"Make.NAVIGATOR."
  },
  {
     "type":"value",
     "displayValue":"NEDSHIP",
     "value":"Make.NEDSHIP."
  },
  {
     "type":"value",
     "displayValue":"NEEL TRIMARANS",
     "value":"Make.NEEL TRIMARANS."
  },
  {
     "type":"value",
     "displayValue":"NEMESIS",
     "value":"Make.NEMESIS."
  },
  {
     "type":"value",
     "displayValue":"NEREUS",
     "value":"Make.NEREUS."
  },
  {
     "type":"value",
     "displayValue":"NEW OCEAN YACHTS",
     "value":"Make.NEW OCEAN YACHTS."
  },
  {
     "type":"value",
     "displayValue":"NEWICK",
     "value":"Make.NEWICK."
  },
  {
     "type":"value",
     "displayValue":"NEWPORT",
     "value":"Make.NEWPORT."
  },
  {
     "type":"value",
     "displayValue":"NIAD INFLATABLE",
     "value":"Make.NIAD INFLATABLE."
  },
  {
     "type":"value",
     "displayValue":"NICHE MARINE",
     "value":"Make.NICHE MARINE."
  },
  {
     "type":"value",
     "displayValue":"NIMBUS",
     "value":"Make.NIMBUS."
  },
  {
     "type":"value",
     "displayValue":"Nitro",
     "value":"Make.Nitro."
  },
  {
     "type":"value",
     "displayValue":"NOAH THOMPSON",
     "value":"Make.NOAH THOMPSON."
  },
  {
     "type":"value",
     "displayValue":"Noble",
     "value":"Make.Noble."
  },
  {
     "type":"value",
     "displayValue":"NOBLE BOATS INTERNATIONAL",
     "value":"Make.NOBLE BOATS INTERNATIONAL."
  },
  {
     "type":"value",
     "displayValue":"NOBLE SUPER VEE",
     "value":"Make.NOBLE SUPER VEE."
  },
  {
     "type":"value",
     "displayValue":"NOELEX",
     "value":"Make.NOELEX."
  },
  {
     "type":"value",
     "displayValue":"NOMAD YACHTS",
     "value":"Make.NOMAD YACHTS."
  },
  {
     "type":"value",
     "displayValue":"NOOSA CAT",
     "value":"Make.NOOSA CAT."
  },
  {
     "type":"value",
     "displayValue":"Norcat",
     "value":"Make.Norcat."
  },
  {
     "type":"value",
     "displayValue":"Nordhavn",
     "value":"Make.Nordhavn."
  },
  {
     "type":"value",
     "displayValue":"NORDIC",
     "value":"Make.NORDIC."
  },
  {
     "type":"value",
     "displayValue":"NORMAN WRIGHT",
     "value":"Make.NORMAN WRIGHT."
  },
  {
     "type":"value",
     "displayValue":"NORTH HARBOUR MOTOR YACHTS",
     "value":"Make.NORTH HARBOUR MOTOR YACHTS."
  },
  {
     "type":"value",
     "displayValue":"NORTHBANK",
     "value":"Make.NORTHBANK."
  },
  {
     "type":"value",
     "displayValue":"NORTHENER",
     "value":"Make.NORTHENER."
  },
  {
     "type":"value",
     "displayValue":"Northern Sailcraft",
     "value":"Make.Northern Sailcraft."
  },
  {
     "type":"value",
     "displayValue":"NORTHSHORE",
     "value":"Make.NORTHSHORE."
  },
  {
     "type":"value",
     "displayValue":"Northside Fibreglass",
     "value":"Make.Northside Fibreglass."
  },
  {
     "type":"value",
     "displayValue":"Northstar",
     "value":"Make.Northstar."
  },
  {
     "type":"value",
     "displayValue":"NORTHWIND",
     "value":"Make.NORTHWIND."
  },
  {
     "type":"value",
     "displayValue":"NORWALK ISLANDS SHARPIES",
     "value":"Make.NORWALK ISLANDS SHARPIES."
  },
  {
     "type":"value",
     "displayValue":"Nova",
     "value":"Make.Nova."
  },
  {
     "type":"value",
     "displayValue":"Novamarine",
     "value":"Make.Novamarine."
  },
  {
     "type":"value",
     "displayValue":"NOVATEC",
     "value":"Make.NOVATEC."
  },
  {
     "type":"value",
     "displayValue":"NOVURANIA",
     "value":"Make.NOVURANIA."
  },
  {
     "type":"value",
     "displayValue":"NS14",
     "value":"Make.NS14."
  },
  {
     "type":"value",
     "displayValue":"NUMARINE",
     "value":"Make.NUMARINE."
  },
  {
     "type":"value",
     "displayValue":"NUOVA JOLLY",
     "value":"Make.NUOVA JOLLY."
  },
  {
     "type":"value",
     "displayValue":"NUSTAR",
     "value":"Make.NUSTAR."
  },
  {
     "type":"value",
     "displayValue":"OCEAN",
     "value":"Make.OCEAN."
  },
  {
     "type":"value",
     "displayValue":"OCEAN ALEXANDER",
     "value":"Make.OCEAN ALEXANDER."
  },
  {
     "type":"value",
     "displayValue":"Ocean Craft",
     "value":"Make.Ocean Craft."
  },
  {
     "type":"value",
     "displayValue":"OCEAN CYLINDER",
     "value":"Make.OCEAN CYLINDER."
  },
  {
     "type":"value",
     "displayValue":"Ocean Master",
     "value":"Make.Ocean Master."
  },
  {
     "type":"value",
     "displayValue":"OCEAN MAX",
     "value":"Make.OCEAN MAX."
  },
  {
     "type":"value",
     "displayValue":"OCEAN TREK",
     "value":"Make.OCEAN TREK."
  },
  {
     "type":"value",
     "displayValue":"OCEAN VOYAGER",
     "value":"Make.OCEAN VOYAGER."
  },
  {
     "type":"value",
     "displayValue":"OCEAN WHALER",
     "value":"Make.OCEAN WHALER."
  },
  {
     "type":"value",
     "displayValue":"OCEAN YACHTS",
     "value":"Make.OCEAN YACHTS."
  },
  {
     "type":"value",
     "displayValue":"OCEANCAT",
     "value":"Make.OCEANCAT."
  },
  {
     "type":"value",
     "displayValue":"OCEANFAST",
     "value":"Make.OCEANFAST."
  },
  {
     "type":"value",
     "displayValue":"Oceania",
     "value":"Make.Oceania."
  },
  {
     "type":"value",
     "displayValue":"OCEANIC",
     "value":"Make.OCEANIC."
  },
  {
     "type":"value",
     "displayValue":"Oceanic Aluminium Boats",
     "value":"Make.Oceanic Aluminium Boats."
  },
  {
     "type":"value",
     "displayValue":"OCEANIC FABRICATION",
     "value":"Make.OCEANIC FABRICATION."
  },
  {
     "type":"value",
     "displayValue":"OCEANTECH",
     "value":"Make.OCEANTECH."
  },
  {
     "type":"value",
     "displayValue":"OFFSHORE",
     "value":"Make.OFFSHORE."
  },
  {
     "type":"value",
     "displayValue":"OFFSHORE MARINE MASTER",
     "value":"Make.OFFSHORE MARINE MASTER."
  },
  {
     "type":"value",
     "displayValue":"OK",
     "value":"Make.OK."
  },
  {
     "type":"value",
     "displayValue":"OPTIMIST",
     "value":"Make.OPTIMIST."
  },
  {
     "type":"value",
     "displayValue":"ORAM",
     "value":"Make.ORAM."
  },
  {
     "type":"value",
     "displayValue":"ORIGIN",
     "value":"Make.ORIGIN."
  },
  {
     "type":"value",
     "displayValue":"ORYX YACHTS",
     "value":"Make.ORYX YACHTS."
  },
  {
     "type":"value",
     "displayValue":"OUGHTRED",
     "value":"Make.OUGHTRED."
  },
  {
     "type":"value",
     "displayValue":"OUTREMER",
     "value":"Make.OUTREMER."
  },
  {
     "type":"value",
     "displayValue":"OVATION",
     "value":"Make.OVATION."
  },
  {
     "type":"value",
     "displayValue":"OYSTER",
     "value":"Make.OYSTER."
  },
  {
     "type":"value",
     "displayValue":"Ozsea Plate Boats",
     "value":"Make.Ozsea Plate Boats."
  },
  {
     "type":"value",
     "displayValue":"OZYCAT",
     "value":"Make.OZYCAT."
  },
  {
     "type":"value",
     "displayValue":"Pacemaker",
     "value":"Make.Pacemaker."
  },
  {
     "type":"value",
     "displayValue":"PACHOUD",
     "value":"Make.PACHOUD."
  },
  {
     "type":"value",
     "displayValue":"PACIFIC",
     "value":"Make.PACIFIC."
  },
  {
     "type":"value",
     "displayValue":"PACIFIC PONTOONS",
     "value":"Make.PACIFIC PONTOONS."
  },
  {
     "type":"value",
     "displayValue":"PADDLE WHEEL",
     "value":"Make.PADDLE WHEEL."
  },
  {
     "type":"value",
     "displayValue":"PALM BEACH MOTOR YACHTS",
     "value":"Make.PALM BEACH MOTOR YACHTS."
  },
  {
     "type":"value",
     "displayValue":"PALMCO",
     "value":"Make.PALMCO."
  },
  {
     "type":"value",
     "displayValue":"Palmer Johnson",
     "value":"Make.Palmer Johnson."
  },
  {
     "type":"value",
     "displayValue":"PARAGON",
     "value":"Make.PARAGON."
  },
  {
     "type":"value",
     "displayValue":"PARDO YACHTS",
     "value":"Make.PARDO YACHTS."
  },
  {
     "type":"value",
     "displayValue":"PARRAMATTA RIVER MARINE",
     "value":"Make.PARRAMATTA RIVER MARINE."
  },
  {
     "type":"value",
     "displayValue":"PATHFINDER",
     "value":"Make.PATHFINDER."
  },
  {
     "type":"value",
     "displayValue":"PELAGIC BOATS",
     "value":"Make.PELAGIC BOATS."
  },
  {
     "type":"value",
     "displayValue":"PENGUIN",
     "value":"Make.PENGUIN."
  },
  {
     "type":"value",
     "displayValue":"PERRY",
     "value":"Make.PERRY."
  },
  {
     "type":"value",
     "displayValue":"Perry Catamarans",
     "value":"Make.Perry Catamarans."
  },
  {
     "type":"value",
     "displayValue":"PERSHING",
     "value":"Make.PERSHING."
  },
  {
     "type":"value",
     "displayValue":"PESCOTT",
     "value":"Make.PESCOTT."
  },
  {
     "type":"value",
     "displayValue":"PETER KERR",
     "value":"Make.PETER KERR."
  },
  {
     "type":"value",
     "displayValue":"PHANTOM",
     "value":"Make.PHANTOM."
  },
  {
     "type":"value",
     "displayValue":"PHIBICAT",
     "value":"Make.PHIBICAT."
  },
  {
     "type":"value",
     "displayValue":"PHIL CURRAN",
     "value":"Make.PHIL CURRAN."
  },
  {
     "type":"value",
     "displayValue":"PHILIP RHODES",
     "value":"Make.PHILIP RHODES."
  },
  {
     "type":"value",
     "displayValue":"PION",
     "value":"Make.PION."
  },
  {
     "type":"value",
     "displayValue":"PIONEER",
     "value":"Make.PIONEER."
  },
  {
     "type":"value",
     "displayValue":"PIRATE",
     "value":"Make.PIRATE."
  },
  {
     "type":"value",
     "displayValue":"Pirelli Pzero",
     "value":"Make.Pirelli Pzero."
  },
  {
     "type":"value",
     "displayValue":"PLATE ALLOY",
     "value":"Make.PLATE ALLOY."
  },
  {
     "type":"value",
     "displayValue":"PLEYSIER",
     "value":"Make.PLEYSIER."
  },
  {
     "type":"value",
     "displayValue":"Polaris",
     "value":"Make.Polaris."
  },
  {
     "type":"value",
     "displayValue":"Polycraft",
     "value":"Make.Polycraft."
  },
  {
     "type":"value",
     "displayValue":"POMPEI",
     "value":"Make.POMPEI."
  },
  {
     "type":"value",
     "displayValue":"PONGRASS",
     "value":"Make.PONGRASS."
  },
  {
     "type":"value",
     "displayValue":"PONTOON",
     "value":"Make.PONTOON."
  },
  {
     "type":"value",
     "displayValue":"PORTA-BOTE",
     "value":"Make.PORTA-BOTE."
  },
  {
     "type":"value",
     "displayValue":"POSEIDON",
     "value":"Make.POSEIDON."
  },
  {
     "type":"value",
     "displayValue":"POWERCAT",
     "value":"Make.POWERCAT."
  },
  {
     "type":"value",
     "displayValue":"Powerplay",
     "value":"Make.Powerplay."
  },
  {
     "type":"value",
     "displayValue":"POWERQUEST",
     "value":"Make.POWERQUEST."
  },
  {
     "type":"value",
     "displayValue":"PRECISION",
     "value":"Make.PRECISION."
  },
  {
     "type":"value",
     "displayValue":"Predator",
     "value":"Make.Predator."
  },
  {
     "type":"value",
     "displayValue":"PRESIDENT",
     "value":"Make.PRESIDENT."
  },
  {
     "type":"value",
     "displayValue":"PRESTIGE",
     "value":"Make.PRESTIGE."
  },
  {
     "type":"value",
     "displayValue":"PRIDE",
     "value":"Make.PRIDE."
  },
  {
     "type":"value",
     "displayValue":"Princess",
     "value":"Make.Princess."
  },
  {
     "type":"value",
     "displayValue":"PRIVILEGE",
     "value":"Make.PRIVILEGE."
  },
  {
     "type":"value",
     "displayValue":"PRO SPORT",
     "value":"Make.PRO SPORT."
  },
  {
     "type":"value",
     "displayValue":"PRO-LINE",
     "value":"Make.PRO-LINE."
  },
  {
     "type":"value",
     "displayValue":"PROBE",
     "value":"Make.PROBE."
  },
  {
     "type":"value",
     "displayValue":"Profile",
     "value":"Make.Profile."
  },
  {
     "type":"value",
     "displayValue":"PROFISH",
     "value":"Make.PROFISH."
  },
  {
     "type":"value",
     "displayValue":"Project",
     "value":"Make.Project."
  },
  {
     "type":"value",
     "displayValue":"PROMARINE",
     "value":"Make.PROMARINE."
  },
  {
     "type":"value",
     "displayValue":"PROTECTOR",
     "value":"Make.PROTECTOR."
  },
  {
     "type":"value",
     "displayValue":"PROUT",
     "value":"Make.PROUT."
  },
  {
     "type":"value",
     "displayValue":"PT BOATS",
     "value":"Make.PT BOATS."
  },
  {
     "type":"value",
     "displayValue":"PUCCINI YACHTS",
     "value":"Make.PUCCINI YACHTS."
  },
  {
     "type":"value",
     "displayValue":"PURSUIT",
     "value":"Make.PURSUIT."
  },
  {
     "type":"value",
     "displayValue":"QINGDAO",
     "value":"Make.QINGDAO."
  },
  {
     "type":"value",
     "displayValue":"QUANTUM",
     "value":"Make.QUANTUM."
  },
  {
     "type":"value",
     "displayValue":"QUANTUM CAT",
     "value":"Make.QUANTUM CAT."
  },
  {
     "type":"value",
     "displayValue":"Quicksilver",
     "value":"Make.Quicksilver."
  },
  {
     "type":"value",
     "displayValue":"QUICKSILVER INFLATABLES",
     "value":"Make.QUICKSILVER INFLATABLES."
  },
  {
     "type":"value",
     "displayValue":"QUINTREX",
     "value":"Make.QUINTREX."
  },
  {
     "type":"value",
     "displayValue":"RADFORD",
     "value":"Make.RADFORD."
  },
  {
     "type":"value",
     "displayValue":"RAE LINE",
     "value":"Make.RAE LINE."
  },
  {
     "type":"value",
     "displayValue":"RAEBEL",
     "value":"Make.RAEBEL."
  },
  {
     "type":"value",
     "displayValue":"Raider",
     "value":"Make.Raider."
  },
  {
     "type":"value",
     "displayValue":"RAMPAGE",
     "value":"Make.RAMPAGE."
  },
  {
     "type":"value",
     "displayValue":"RAMSAY",
     "value":"Make.RAMSAY."
  },
  {
     "type":"value",
     "displayValue":"RAND BOATS",
     "value":"Make.RAND BOATS."
  },
  {
     "type":"value",
     "displayValue":"RANDALL",
     "value":"Make.RANDALL."
  },
  {
     "type":"value",
     "displayValue":"RANDELL",
     "value":"Make.RANDELL."
  },
  {
     "type":"value",
     "displayValue":"RANGER",
     "value":"Make.RANGER."
  },
  {
     "type":"value",
     "displayValue":"Ranger Fishing Boats",
     "value":"Make.Ranger Fishing Boats."
  },
  {
     "type":"value",
     "displayValue":"RAPTOR",
     "value":"Make.RAPTOR."
  },
  {
     "type":"value",
     "displayValue":"RAVEN",
     "value":"Make.RAVEN."
  },
  {
     "type":"value",
     "displayValue":"Rayglass",
     "value":"Make.Rayglass."
  },
  {
     "type":"value",
     "displayValue":"Razerline",
     "value":"Make.Razerline."
  },
  {
     "type":"value",
     "displayValue":"REFLEX",
     "value":"Make.REFLEX."
  },
  {
     "type":"value",
     "displayValue":"REGAL",
     "value":"Make.REGAL."
  },
  {
     "type":"value",
     "displayValue":"Regulator",
     "value":"Make.Regulator."
  },
  {
     "type":"value",
     "displayValue":"REINELL",
     "value":"Make.REINELL."
  },
  {
     "type":"value",
     "displayValue":"Release Boats",
     "value":"Make.Release Boats."
  },
  {
     "type":"value",
     "displayValue":"RESORT",
     "value":"Make.RESORT."
  },
  {
     "type":"value",
     "displayValue":"Retronaught",
     "value":"Make.Retronaught."
  },
  {
     "type":"value",
     "displayValue":"REVIVAL",
     "value":"Make.REVIVAL."
  },
  {
     "type":"value",
     "displayValue":"REX NORTON",
     "value":"Make.REX NORTON."
  },
  {
     "type":"value",
     "displayValue":"Rhea",
     "value":"Make.Rhea."
  },
  {
     "type":"value",
     "displayValue":"RIB BOAT",
     "value":"Make.RIB BOAT."
  },
  {
     "type":"value",
     "displayValue":"RIB JET",
     "value":"Make.RIB JET."
  },
  {
     "type":"value",
     "displayValue":"RIBBON",
     "value":"Make.RIBBON."
  },
  {
     "type":"value",
     "displayValue":"Richards",
     "value":"Make.Richards."
  },
  {
     "type":"value",
     "displayValue":"RICHMOND",
     "value":"Make.RICHMOND."
  },
  {
     "type":"value",
     "displayValue":"Ringle",
     "value":"Make.Ringle."
  },
  {
     "type":"value",
     "displayValue":"RINKER",
     "value":"Make.RINKER."
  },
  {
     "type":"value",
     "displayValue":"RIVA",
     "value":"Make.RIVA."
  },
  {
     "type":"value",
     "displayValue":"River to Reef",
     "value":"Make.River to Reef."
  },
  {
     "type":"value",
     "displayValue":"Riviera",
     "value":"Make.Riviera."
  },
  {
     "type":"value",
     "displayValue":"RIZZARDI",
     "value":"Make.RIZZARDI."
  },
  {
     "type":"value",
     "displayValue":"ROB LEGG",
     "value":"Make.ROB LEGG."
  },
  {
     "type":"value",
     "displayValue":"ROBALO",
     "value":"Make.ROBALO."
  },
  {
     "type":"value",
     "displayValue":"ROBERT CLARKE",
     "value":"Make.ROBERT CLARKE."
  },
  {
     "type":"value",
     "displayValue":"ROBERTS",
     "value":"Make.ROBERTS."
  },
  {
     "type":"value",
     "displayValue":"ROGER HILL",
     "value":"Make.ROGER HILL."
  },
  {
     "type":"value",
     "displayValue":"ROGERS",
     "value":"Make.ROGERS."
  },
  {
     "type":"value",
     "displayValue":"Rolco",
     "value":"Make.Rolco."
  },
  {
     "type":"value",
     "displayValue":"RON HOLLAND",
     "value":"Make.RON HOLLAND."
  },
  {
     "type":"value",
     "displayValue":"ROPER",
     "value":"Make.ROPER."
  },
  {
     "type":"value",
     "displayValue":"ROSS",
     "value":"Make.ROSS."
  },
  {
     "type":"value",
     "displayValue":"ROUGHNECK",
     "value":"Make.ROUGHNECK."
  },
  {
     "type":"value",
     "displayValue":"Rover",
     "value":"Make.Rover."
  },
  {
     "type":"value",
     "displayValue":"ROYAL DENSHIP",
     "value":"Make.ROYAL DENSHIP."
  },
  {
     "type":"value",
     "displayValue":"RS Sailing",
     "value":"Make.RS Sailing."
  },
  {
     "type":"value",
     "displayValue":"RUNABOUT",
     "value":"Make.RUNABOUT."
  },
  {
     "type":"value",
     "displayValue":"RUNAWAY BAY PONTOON BOATS",
     "value":"Make.RUNAWAY BAY PONTOON BOATS."
  },
  {
     "type":"value",
     "displayValue":"RUNNALLS",
     "value":"Make.RUNNALLS."
  },
  {
     "type":"value",
     "displayValue":"RYAN",
     "value":"Make.RYAN."
  },
  {
     "type":"value",
     "displayValue":"SABRE",
     "value":"Make.SABRE."
  },
  {
     "type":"value",
     "displayValue":"SACS MARINE",
     "value":"Make.SACS MARINE."
  },
  {
     "type":"value",
     "displayValue":"SAILFISH",
     "value":"Make.SAILFISH."
  },
  {
     "type":"value",
     "displayValue":"SALACIA YACHTS",
     "value":"Make.SALACIA YACHTS."
  },
  {
     "type":"value",
     "displayValue":"Salem",
     "value":"Make.Salem."
  },
  {
     "type":"value",
     "displayValue":"Salthouse",
     "value":"Make.Salthouse."
  },
  {
     "type":"value",
     "displayValue":"SALTWATER COMMERCIAL WORKBOATS",
     "value":"Make.SALTWATER COMMERCIAL WORKBOATS."
  },
  {
     "type":"value",
     "displayValue":"SAMSON",
     "value":"Make.SAMSON."
  },
  {
     "type":"value",
     "displayValue":"SANGER",
     "value":"Make.SANGER."
  },
  {
     "type":"value",
     "displayValue":"SANLORENZO",
     "value":"Make.SANLORENZO."
  },
  {
     "type":"value",
     "displayValue":"SANTANA",
     "value":"Make.SANTANA."
  },
  {
     "type":"value",
     "displayValue":"SASGA YACHTS",
     "value":"Make.SASGA YACHTS."
  },
  {
     "type":"value",
     "displayValue":"Savage",
     "value":"Make.Savage."
  },
  {
     "type":"value",
     "displayValue":"SAYER",
     "value":"Make.SAYER."
  },
  {
     "type":"value",
     "displayValue":"SB20",
     "value":"Make.SB20."
  },
  {
     "type":"value",
     "displayValue":"SBF SHIPBUILDERS",
     "value":"Make.SBF SHIPBUILDERS."
  },
  {
     "type":"value",
     "displayValue":"SCARAB",
     "value":"Make.SCARAB."
  },
  {
     "type":"value",
     "displayValue":"SCARIFF",
     "value":"Make.SCARIFF."
  },
  {
     "type":"value",
     "displayValue":"SCB Boats",
     "value":"Make.SCB Boats."
  },
  {
     "type":"value",
     "displayValue":"SCHAAF",
     "value":"Make.SCHAAF."
  },
  {
     "type":"value",
     "displayValue":"SCHAEFER YACHTS",
     "value":"Make.SCHAEFER YACHTS."
  },
  {
     "type":"value",
     "displayValue":"SCHIONNING",
     "value":"Make.SCHIONNING."
  },
  {
     "type":"value",
     "displayValue":"SCHOCK",
     "value":"Make.SCHOCK."
  },
  {
     "type":"value",
     "displayValue":"SCHOONER",
     "value":"Make.SCHOONER."
  },
  {
     "type":"value",
     "displayValue":"Schwetz Design",
     "value":"Make.Schwetz Design."
  },
  {
     "type":"value",
     "displayValue":"SCIMITAR",
     "value":"Make.SCIMITAR."
  },
  {
     "type":"value",
     "displayValue":"SCOTT ROBSON",
     "value":"Make.SCOTT ROBSON."
  },
  {
     "type":"value",
     "displayValue":"SCOUT",
     "value":"Make.SCOUT."
  },
  {
     "type":"value",
     "displayValue":"Sea Adex",
     "value":"Make.Sea Adex."
  },
  {
     "type":"value",
     "displayValue":"Sea Austral",
     "value":"Make.Sea Austral."
  },
  {
     "type":"value",
     "displayValue":"SEA BOSS",
     "value":"Make.SEA BOSS."
  },
  {
     "type":"value",
     "displayValue":"SEA CAT",
     "value":"Make.SEA CAT."
  },
  {
     "type":"value",
     "displayValue":"SEA CREST",
     "value":"Make.SEA CREST."
  },
  {
     "type":"value",
     "displayValue":"SEA FOX",
     "value":"Make.SEA FOX."
  },
  {
     "type":"value",
     "displayValue":"Sea Hunter",
     "value":"Make.Sea Hunter."
  },
  {
     "type":"value",
     "displayValue":"SEA JAY",
     "value":"Make.SEA JAY."
  },
  {
     "type":"value",
     "displayValue":"SEA LEOPARD",
     "value":"Make.SEA LEOPARD."
  },
  {
     "type":"value",
     "displayValue":"SEA PRO",
     "value":"Make.SEA PRO."
  },
  {
     "type":"value",
     "displayValue":"SEA RANGER",
     "value":"Make.SEA RANGER."
  },
  {
     "type":"value",
     "displayValue":"Sea Ray",
     "value":"Make.Sea Ray."
  },
  {
     "type":"value",
     "displayValue":"Sea Skimmer",
     "value":"Make.Sea Skimmer."
  },
  {
     "type":"value",
     "displayValue":"SEA SPIRIT",
     "value":"Make.SEA SPIRIT."
  },
  {
     "type":"value",
     "displayValue":"SEA STELLA",
     "value":"Make.SEA STELLA."
  },
  {
     "type":"value",
     "displayValue":"SEA TIGER",
     "value":"Make.SEA TIGER."
  },
  {
     "type":"value",
     "displayValue":"Sea Water",
     "value":"Make.Sea Water."
  },
  {
     "type":"value",
     "displayValue":"Sea-Al",
     "value":"Make.Sea-Al."
  },
  {
     "type":"value",
     "displayValue":"SEA-DOO",
     "value":"Make.SEA-DOO."
  },
  {
     "type":"value",
     "displayValue":"SEABAT",
     "value":"Make.SEABAT."
  },
  {
     "type":"value",
     "displayValue":"SEACART",
     "value":"Make.SEACART."
  },
  {
     "type":"value",
     "displayValue":"SEACHROME",
     "value":"Make.SEACHROME."
  },
  {
     "type":"value",
     "displayValue":"Seacraft",
     "value":"Make.Seacraft."
  },
  {
     "type":"value",
     "displayValue":"SEADANCER",
     "value":"Make.SEADANCER."
  },
  {
     "type":"value",
     "displayValue":"SEAFARER",
     "value":"Make.SEAFARER."
  },
  {
     "type":"value",
     "displayValue":"SEAFARER VAGABOND",
     "value":"Make.SEAFARER VAGABOND."
  },
  {
     "type":"value",
     "displayValue":"SEAHORSE",
     "value":"Make.SEAHORSE."
  },
  {
     "type":"value",
     "displayValue":"SEAir",
     "value":"Make.SEAir."
  },
  {
     "type":"value",
     "displayValue":"SEALEGS",
     "value":"Make.SEALEGS."
  },
  {
     "type":"value",
     "displayValue":"SEALINE",
     "value":"Make.SEALINE."
  },
  {
     "type":"value",
     "displayValue":"Sealver",
     "value":"Make.Sealver."
  },
  {
     "type":"value",
     "displayValue":"SEAMASTER",
     "value":"Make.SEAMASTER."
  },
  {
     "type":"value",
     "displayValue":"SEAQUEST",
     "value":"Make.SEAQUEST."
  },
  {
     "type":"value",
     "displayValue":"SEARLE",
     "value":"Make.SEARLE."
  },
  {
     "type":"value",
     "displayValue":"SEASPRAY",
     "value":"Make.SEASPRAY."
  },
  {
     "type":"value",
     "displayValue":"Seaswirl",
     "value":"Make.Seaswirl."
  },
  {
     "type":"value",
     "displayValue":"SEATAMER",
     "value":"Make.SEATAMER."
  },
  {
     "type":"value",
     "displayValue":"SEATECH",
     "value":"Make.SEATECH."
  },
  {
     "type":"value",
     "displayValue":"SEAVIEW",
     "value":"Make.SEAVIEW."
  },
  {
     "type":"value",
     "displayValue":"SEAWAY",
     "value":"Make.SEAWAY."
  },
  {
     "type":"value",
     "displayValue":"Seawind",
     "value":"Make.Seawind."
  },
  {
     "type":"value",
     "displayValue":"SEEKER PONTOON BOATS",
     "value":"Make.SEEKER PONTOON BOATS."
  },
  {
     "type":"value",
     "displayValue":"Selene",
     "value":"Make.Selene."
  },
  {
     "type":"value",
     "displayValue":"Senator",
     "value":"Make.Senator."
  },
  {
     "type":"value",
     "displayValue":"SENSATION",
     "value":"Make.SENSATION."
  },
  {
     "type":"value",
     "displayValue":"SERICA",
     "value":"Make.SERICA."
  },
  {
     "type":"value",
     "displayValue":"SESSA",
     "value":"Make.SESSA."
  },
  {
     "type":"value",
     "displayValue":"Shallow Sport Boats",
     "value":"Make.Shallow Sport Boats."
  },
  {
     "type":"value",
     "displayValue":"Shannon",
     "value":"Make.Shannon."
  },
  {
     "type":"value",
     "displayValue":"SHARK CAT",
     "value":"Make.SHARK CAT."
  },
  {
     "type":"value",
     "displayValue":"SHARPIE",
     "value":"Make.SHARPIE."
  },
  {
     "type":"value",
     "displayValue":"Shepirocraft",
     "value":"Make.Shepirocraft."
  },
  {
     "type":"value",
     "displayValue":"SHIP",
     "value":"Make.SHIP."
  },
  {
     "type":"value",
     "displayValue":"SILENT YACHTS",
     "value":"Make.SILENT YACHTS."
  },
  {
     "type":"value",
     "displayValue":"SILVER ARROWS MARINE",
     "value":"Make.SILVER ARROWS MARINE."
  },
  {
     "type":"value",
     "displayValue":"Silver Marine",
     "value":"Make.Silver Marine."
  },
  {
     "type":"value",
     "displayValue":"SILVERCRAFT",
     "value":"Make.SILVERCRAFT."
  },
  {
     "type":"value",
     "displayValue":"SILVERLINE",
     "value":"Make.SILVERLINE."
  },
  {
     "type":"value",
     "displayValue":"SILVERTON",
     "value":"Make.SILVERTON."
  },
  {
     "type":"value",
     "displayValue":"SIMONIS",
     "value":"Make.SIMONIS."
  },
  {
     "type":"value",
     "displayValue":"SIMPKIN",
     "value":"Make.SIMPKIN."
  },
  {
     "type":"value",
     "displayValue":"Simply Boats",
     "value":"Make.Simply Boats."
  },
  {
     "type":"value",
     "displayValue":"SIMPSON",
     "value":"Make.SIMPSON."
  },
  {
     "type":"value",
     "displayValue":"SINCLAIR AND MORRISON",
     "value":"Make.SINCLAIR AND MORRISON."
  },
  {
     "type":"value",
     "displayValue":"SIRENA YACHTS",
     "value":"Make.SIRENA YACHTS."
  },
  {
     "type":"value",
     "displayValue":"SIROCCO",
     "value":"Make.SIROCCO."
  },
  {
     "type":"value",
     "displayValue":"SKATER",
     "value":"Make.SKATER."
  },
  {
     "type":"value",
     "displayValue":"Skeeter",
     "value":"Make.Skeeter."
  },
  {
     "type":"value",
     "displayValue":"SKI BOAT",
     "value":"Make.SKI BOAT."
  },
  {
     "type":"value",
     "displayValue":"Ski Master",
     "value":"Make.Ski Master."
  },
  {
     "type":"value",
     "displayValue":"SKI SUPREME",
     "value":"Make.SKI SUPREME."
  },
  {
     "type":"value",
     "displayValue":"Skicraft",
     "value":"Make.Skicraft."
  },
  {
     "type":"value",
     "displayValue":"SKILINE",
     "value":"Make.SKILINE."
  },
  {
     "type":"value",
     "displayValue":"SLEEKCRAFT",
     "value":"Make.SLEEKCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Sleekline",
     "value":"Make.Sleekline."
  },
  {
     "type":"value",
     "displayValue":"SLOOP",
     "value":"Make.SLOOP."
  },
  {
     "type":"value",
     "displayValue":"SMARTWAVE",
     "value":"Make.SMARTWAVE."
  },
  {
     "type":"value",
     "displayValue":"Smith Marine Design",
     "value":"Make.Smith Marine Design."
  },
  {
     "type":"value",
     "displayValue":"Smuggler",
     "value":"Make.Smuggler."
  },
  {
     "type":"value",
     "displayValue":"SNYPER",
     "value":"Make.SNYPER."
  },
  {
     "type":"value",
     "displayValue":"SOLARIS",
     "value":"Make.SOLARIS."
  },
  {
     "type":"value",
     "displayValue":"SOLARIS POWER",
     "value":"Make.SOLARIS POWER."
  },
  {
     "type":"value",
     "displayValue":"SOLING",
     "value":"Make.SOLING."
  },
  {
     "type":"value",
     "displayValue":"SONATA",
     "value":"Make.SONATA."
  },
  {
     "type":"value",
     "displayValue":"SOSSEGO",
     "value":"Make.SOSSEGO."
  },
  {
     "type":"value",
     "displayValue":"South Coast",
     "value":"Make.South Coast."
  },
  {
     "type":"value",
     "displayValue":"SOUTH PACIFIC",
     "value":"Make.SOUTH PACIFIC."
  },
  {
     "type":"value",
     "displayValue":"SOUTHERLY",
     "value":"Make.SOUTHERLY."
  },
  {
     "type":"value",
     "displayValue":"SOUTHERN CROSS",
     "value":"Make.SOUTHERN CROSS."
  },
  {
     "type":"value",
     "displayValue":"SOUTHERN FORMULA",
     "value":"Make.SOUTHERN FORMULA."
  },
  {
     "type":"value",
     "displayValue":"SOUTHERN PACIFIC",
     "value":"Make.SOUTHERN PACIFIC."
  },
  {
     "type":"value",
     "displayValue":"Southwind",
     "value":"Make.Southwind."
  },
  {
     "type":"value",
     "displayValue":"SOVEREIGN",
     "value":"Make.SOVEREIGN."
  },
  {
     "type":"value",
     "displayValue":"SPACESAILER",
     "value":"Make.SPACESAILER."
  },
  {
     "type":"value",
     "displayValue":"SPARKMAN \u0026 STEPHENS",
     "value":"Make.SPARKMAN \u0026 STEPHENS."
  },
  {
     "type":"value",
     "displayValue":"SPECTRA",
     "value":"Make.SPECTRA."
  },
  {
     "type":"value",
     "displayValue":"SPEEDWELL",
     "value":"Make.SPEEDWELL."
  },
  {
     "type":"value",
     "displayValue":"SPIRITED",
     "value":"Make.SPIRITED."
  },
  {
     "type":"value",
     "displayValue":"SPORTCRAFT",
     "value":"Make.SPORTCRAFT."
  },
  {
     "type":"value",
     "displayValue":"SPORTFISH",
     "value":"Make.SPORTFISH."
  },
  {
     "type":"value",
     "displayValue":"Sportscraft",
     "value":"Make.Sportscraft."
  },
  {
     "type":"value",
     "displayValue":"SPORTSMAN",
     "value":"Make.SPORTSMAN."
  },
  {
     "type":"value",
     "displayValue":"Sportsman Boats",
     "value":"Make.Sportsman Boats."
  },
  {
     "type":"value",
     "displayValue":"Sportsman Craft",
     "value":"Make.Sportsman Craft."
  },
  {
     "type":"value",
     "displayValue":"SPRAY",
     "value":"Make.SPRAY."
  },
  {
     "type":"value",
     "displayValue":"Spy Boats",
     "value":"Make.Spy Boats."
  },
  {
     "type":"value",
     "displayValue":"SPYDERCRAFT",
     "value":"Make.SPYDERCRAFT."
  },
  {
     "type":"value",
     "displayValue":"Stabicraft",
     "value":"Make.Stabicraft."
  },
  {
     "type":"value",
     "displayValue":"Stacer",
     "value":"Make.Stacer."
  },
  {
     "type":"value",
     "displayValue":"STANYON",
     "value":"Make.STANYON."
  },
  {
     "type":"value",
     "displayValue":"STAR BOATS",
     "value":"Make.STAR BOATS."
  },
  {
     "type":"value",
     "displayValue":"Starcraft",
     "value":"Make.Starcraft."
  },
  {
     "type":"value",
     "displayValue":"STEALTH",
     "value":"Make.STEALTH."
  },
  {
     "type":"value",
     "displayValue":"Steber",
     "value":"Make.Steber."
  },
  {
     "type":"value",
     "displayValue":"Stebercraft",
     "value":"Make.Stebercraft."
  },
  {
     "type":"value",
     "displayValue":"STEEL",
     "value":"Make.STEEL."
  },
  {
     "type":"value",
     "displayValue":"STEGCRAFT",
     "value":"Make.STEGCRAFT."
  },
  {
     "type":"value",
     "displayValue":"STEINMAN",
     "value":"Make.STEINMAN."
  },
  {
     "type":"value",
     "displayValue":"Stejcraft",
     "value":"Make.Stejcraft."
  },
  {
     "type":"value",
     "displayValue":"Stephens",
     "value":"Make.Stephens."
  },
  {
     "type":"value",
     "displayValue":"STERNCRAFT",
     "value":"Make.STERNCRAFT."
  },
  {
     "type":"value",
     "displayValue":"STESSCO",
     "value":"Make.STESSCO."
  },
  {
     "type":"value",
     "displayValue":"STESSL",
     "value":"Make.STESSL."
  },
  {
     "type":"value",
     "displayValue":"STEVE WARD",
     "value":"Make.STEVE WARD."
  },
  {
     "type":"value",
     "displayValue":"STEVENS",
     "value":"Make.STEVENS."
  },
  {
     "type":"value",
     "displayValue":"STINGRAY",
     "value":"Make.STINGRAY."
  },
  {
     "type":"value",
     "displayValue":"Storebro",
     "value":"Make.Storebro."
  },
  {
     "type":"value",
     "displayValue":"STRATACRAFT",
     "value":"Make.STRATACRAFT."
  },
  {
     "type":"value",
     "displayValue":"STRATOS",
     "value":"Make.STRATOS."
  },
  {
     "type":"value",
     "displayValue":"Streaker",
     "value":"Make.Streaker."
  },
  {
     "type":"value",
     "displayValue":"STREAMLINE CATAMARANS",
     "value":"Make.STREAMLINE CATAMARANS."
  },
  {
     "type":"value",
     "displayValue":"STRIKER",
     "value":"Make.STRIKER."
  },
  {
     "type":"value",
     "displayValue":"Striper",
     "value":"Make.Striper."
  },
  {
     "type":"value",
     "displayValue":"STYLECRAFT",
     "value":"Make.STYLECRAFT."
  },
  {
     "type":"value",
     "displayValue":"Success Craft",
     "value":"Make.Success Craft."
  },
  {
     "type":"value",
     "displayValue":"SUGAR SAND",
     "value":"Make.SUGAR SAND."
  },
  {
     "type":"value",
     "displayValue":"Sun Tracker",
     "value":"Make.Sun Tracker."
  },
  {
     "type":"value",
     "displayValue":"SUNBEAM",
     "value":"Make.SUNBEAM."
  },
  {
     "type":"value",
     "displayValue":"SUNBIRD",
     "value":"Make.SUNBIRD."
  },
  {
     "type":"value",
     "displayValue":"Sunchaser",
     "value":"Make.Sunchaser."
  },
  {
     "type":"value",
     "displayValue":"Sundeck Yachts",
     "value":"Make.Sundeck Yachts."
  },
  {
     "type":"value",
     "displayValue":"SUNMAID",
     "value":"Make.SUNMAID."
  },
  {
     "type":"value",
     "displayValue":"SUNNER",
     "value":"Make.SUNNER."
  },
  {
     "type":"value",
     "displayValue":"SUNREEF YACHTS",
     "value":"Make.SUNREEF YACHTS."
  },
  {
     "type":"value",
     "displayValue":"Sunrunner",
     "value":"Make.Sunrunner."
  },
  {
     "type":"value",
     "displayValue":"SUNSEEKER",
     "value":"Make.SUNSEEKER."
  },
  {
     "type":"value",
     "displayValue":"SUPER TRAC",
     "value":"Make.SUPER TRAC."
  },
  {
     "type":"value",
     "displayValue":"SUPERTRAC",
     "value":"Make.SUPERTRAC."
  },
  {
     "type":"value",
     "displayValue":"Supra",
     "value":"Make.Supra."
  },
  {
     "type":"value",
     "displayValue":"Supreme",
     "value":"Make.Supreme."
  },
  {
     "type":"value",
     "displayValue":"SUPREME CRAFT",
     "value":"Make.SUPREME CRAFT."
  },
  {
     "type":"value",
     "displayValue":"SurfRider International",
     "value":"Make.SurfRider International."
  },
  {
     "type":"value",
     "displayValue":"SURTEES",
     "value":"Make.SURTEES."
  },
  {
     "type":"value",
     "displayValue":"Suzuki",
     "value":"Make.Suzuki."
  },
  {
     "type":"value",
     "displayValue":"Svensson Boats",
     "value":"Make.Svensson Boats."
  },
  {
     "type":"value",
     "displayValue":"SWANSON",
     "value":"Make.SWANSON."
  },
  {
     "type":"value",
     "displayValue":"SWARBRICK",
     "value":"Make.SWARBRICK."
  },
  {
     "type":"value",
     "displayValue":"SWARBRICK \u0026 SWARBRICK",
     "value":"Make.SWARBRICK \u0026 SWARBRICK."
  },
  {
     "type":"value",
     "displayValue":"Sweden Marine",
     "value":"Make.Sweden Marine."
  },
  {
     "type":"value",
     "displayValue":"Sweetwater",
     "value":"Make.Sweetwater."
  },
  {
     "type":"value",
     "displayValue":"SWIFT",
     "value":"Make.SWIFT."
  },
  {
     "type":"value",
     "displayValue":"Swift Craft",
     "value":"Make.Swift Craft."
  },
  {
     "type":"value",
     "displayValue":"SWIFT MARINE",
     "value":"Make.SWIFT MARINE."
  },
  {
     "type":"value",
     "displayValue":"SWORDFISH",
     "value":"Make.SWORDFISH."
  },
  {
     "type":"value",
     "displayValue":"SYDNEY",
     "value":"Make.SYDNEY."
  },
  {
     "type":"value",
     "displayValue":"SYMBOL",
     "value":"Make.SYMBOL."
  },
  {
     "type":"value",
     "displayValue":"SYNDICATE",
     "value":"Make.SYNDICATE."
  },
  {
     "type":"value",
     "displayValue":"Tabs",
     "value":"Make.Tabs."
  },
  {
     "type":"value",
     "displayValue":"Tahoe",
     "value":"Make.Tahoe."
  },
  {
     "type":"value",
     "displayValue":"TAILORED MARINE",
     "value":"Make.TAILORED MARINE."
  },
  {
     "type":"value",
     "displayValue":"TAKACAT ",
     "value":"Make.TAKACAT ."
  },
  {
     "type":"value",
     "displayValue":"TARTAN",
     "value":"Make.TARTAN."
  },
  {
     "type":"value",
     "displayValue":"TASAR",
     "value":"Make.TASAR."
  },
  {
     "type":"value",
     "displayValue":"Tascraft",
     "value":"Make.Tascraft."
  },
  {
     "type":"value",
     "displayValue":"TASMAN",
     "value":"Make.TASMAN."
  },
  {
     "type":"value",
     "displayValue":"TASMANIAN",
     "value":"Make.TASMANIAN."
  },
  {
     "type":"value",
     "displayValue":"TAYANA",
     "value":"Make.TAYANA."
  },
  {
     "type":"value",
     "displayValue":"TECHNOHULL",
     "value":"Make.TECHNOHULL."
  },
  {
     "type":"value",
     "displayValue":"TELWATER",
     "value":"Make.TELWATER."
  },
  {
     "type":"value",
     "displayValue":"TEMPTRESS",
     "value":"Make.TEMPTRESS."
  },
  {
     "type":"value",
     "displayValue":"TENDER",
     "value":"Make.TENDER."
  },
  {
     "type":"value",
     "displayValue":"THEODORE",
     "value":"Make.THEODORE."
  },
  {
     "type":"value",
     "displayValue":"THIRA",
     "value":"Make.THIRA."
  },
  {
     "type":"value",
     "displayValue":"THOMASCRAFT",
     "value":"Make.THOMASCRAFT."
  },
  {
     "type":"value",
     "displayValue":"THURGER",
     "value":"Make.THURGER."
  },
  {
     "type":"value",
     "displayValue":"TIDEWATER",
     "value":"Make.TIDEWATER."
  },
  {
     "type":"value",
     "displayValue":"Tige",
     "value":"Make.Tige."
  },
  {
     "type":"value",
     "displayValue":"TIMBER",
     "value":"Make.TIMBER."
  },
  {
     "type":"value",
     "displayValue":"Tohatsu",
     "value":"Make.Tohatsu."
  },
  {
     "type":"value",
     "displayValue":"TOP HAT",
     "value":"Make.TOP HAT."
  },
  {
     "type":"value",
     "displayValue":"Top Hat Yachts",
     "value":"Make.Top Hat Yachts."
  },
  {
     "type":"value",
     "displayValue":"TOPAZ",
     "value":"Make.TOPAZ."
  },
  {
     "type":"value",
     "displayValue":"TOPPER",
     "value":"Make.TOPPER."
  },
  {
     "type":"value",
     "displayValue":"TOURNAMENT",
     "value":"Make.TOURNAMENT."
  },
  {
     "type":"value",
     "displayValue":"Tp",
     "value":"Make.Tp."
  },
  {
     "type":"value",
     "displayValue":"TRACKER",
     "value":"Make.TRACKER."
  },
  {
     "type":"value",
     "displayValue":"TRADITIONAL",
     "value":"Make.TRADITIONAL."
  },
  {
     "type":"value",
     "displayValue":"TRAILCRAFT",
     "value":"Make.TRAILCRAFT."
  },
  {
     "type":"value",
     "displayValue":"TRAILER",
     "value":"Make.TRAILER."
  },
  {
     "type":"value",
     "displayValue":"TRAILER SAILER",
     "value":"Make.TRAILER SAILER."
  },
  {
     "type":"value",
     "displayValue":"Transocean",
     "value":"Make.Transocean."
  },
  {
     "type":"value",
     "displayValue":"Trawler",
     "value":"Make.Trawler."
  },
  {
     "type":"value",
     "displayValue":"TREKKA",
     "value":"Make.TREKKA."
  },
  {
     "type":"value",
     "displayValue":"TREKKER",
     "value":"Make.TREKKER."
  },
  {
     "type":"value",
     "displayValue":"TRIDENT",
     "value":"Make.TRIDENT."
  },
  {
     "type":"value",
     "displayValue":"TRIMARAN",
     "value":"Make.TRIMARAN."
  },
  {
     "type":"value",
     "displayValue":"Trimcraft",
     "value":"Make.Trimcraft."
  },
  {
     "type":"value",
     "displayValue":"TRINITY YACHTS",
     "value":"Make.TRINITY YACHTS."
  },
  {
     "type":"value",
     "displayValue":"TRIPP",
     "value":"Make.TRIPP."
  },
  {
     "type":"value",
     "displayValue":"TRISTRAM",
     "value":"Make.TRISTRAM."
  },
  {
     "type":"value",
     "displayValue":"TRITON",
     "value":"Make.TRITON."
  },
  {
     "type":"value",
     "displayValue":"Triumph",
     "value":"Make.Triumph."
  },
  {
     "type":"value",
     "displayValue":"Trophy",
     "value":"Make.Trophy."
  },
  {
     "type":"value",
     "displayValue":"TROPIC",
     "value":"Make.TROPIC."
  },
  {
     "type":"value",
     "displayValue":"TUG",
     "value":"Make.TUG."
  },
  {
     "type":"value",
     "displayValue":"Turkish",
     "value":"Make.Turkish."
  },
  {
     "type":"value",
     "displayValue":"TURNCRAFT",
     "value":"Make.TURNCRAFT."
  },
  {
     "type":"value",
     "displayValue":"UFO",
     "value":"Make.UFO."
  },
  {
     "type":"value",
     "displayValue":"ULTIMATE",
     "value":"Make.ULTIMATE."
  },
  {
     "type":"value",
     "displayValue":"UNICORN",
     "value":"Make.UNICORN."
  },
  {
     "type":"value",
     "displayValue":"Usual",
     "value":"Make.Usual."
  },
  {
     "type":"value",
     "displayValue":"VALHALLA",
     "value":"Make.VALHALLA."
  },
  {
     "type":"value",
     "displayValue":"VALIANT",
     "value":"Make.VALIANT."
  },
  {
     "type":"value",
     "displayValue":"VAN DAM NORDIA",
     "value":"Make.VAN DAM NORDIA."
  },
  {
     "type":"value",
     "displayValue":"VAN DE STADT",
     "value":"Make.VAN DE STADT."
  },
  {
     "type":"value",
     "displayValue":"Van Diemen Luxury Craft",
     "value":"Make.Van Diemen Luxury Craft."
  },
  {
     "type":"value",
     "displayValue":"VANQUISH YACHTS",
     "value":"Make.VANQUISH YACHTS."
  },
  {
     "type":"value",
     "displayValue":"VENTNOR",
     "value":"Make.VENTNOR."
  },
  {
     "type":"value",
     "displayValue":"VENTURER",
     "value":"Make.VENTURER."
  },
  {
     "type":"value",
     "displayValue":"VERANDA PONTOONS",
     "value":"Make.VERANDA PONTOONS."
  },
  {
     "type":"value",
     "displayValue":"VERSILCRAFT",
     "value":"Make.VERSILCRAFT."
  },
  {
     "type":"value",
     "displayValue":"VIKING YACHTS",
     "value":"Make.VIKING YACHTS."
  },
  {
     "type":"value",
     "displayValue":"VINDEX",
     "value":"Make.VINDEX."
  },
  {
     "type":"value",
     "displayValue":"Viper",
     "value":"Make.Viper."
  },
  {
     "type":"value",
     "displayValue":"VIPER HPS",
     "value":"Make.VIPER HPS."
  },
  {
     "type":"value",
     "displayValue":"VITECH",
     "value":"Make.VITECH."
  },
  {
     "type":"value",
     "displayValue":"VIVACITY",
     "value":"Make.VIVACITY."
  },
  {
     "type":"value",
     "displayValue":"VMAX",
     "value":"Make.VMAX."
  },
  {
     "type":"value",
     "displayValue":"VOLERO",
     "value":"Make.VOLERO."
  },
  {
     "type":"value",
     "displayValue":"Volvo",
     "value":"Make.Volvo."
  },
  {
     "type":"value",
     "displayValue":"Volvo Penta",
     "value":"Make.Volvo Penta."
  },
  {
     "type":"value",
     "displayValue":"Voyager",
     "value":"Make.Voyager."
  },
  {
     "type":"value",
     "displayValue":"VSR",
     "value":"Make.VSR."
  },
  {
     "type":"value",
     "displayValue":"WAHOO",
     "value":"Make.WAHOO."
  },
  {
     "type":"value",
     "displayValue":"WAL SHIRT",
     "value":"Make.WAL SHIRT."
  },
  {
     "type":"value",
     "displayValue":"Warren",
     "value":"Make.Warren."
  },
  {
     "type":"value",
     "displayValue":"WARWICK",
     "value":"Make.WARWICK."
  },
  {
     "type":"value",
     "displayValue":"WATSON",
     "value":"Make.WATSON."
  },
  {
     "type":"value",
     "displayValue":"WAVEPIERCER",
     "value":"Make.WAVEPIERCER."
  },
  {
     "type":"value",
     "displayValue":"WEBSTERS TWINFISHER",
     "value":"Make.WEBSTERS TWINFISHER."
  },
  {
     "type":"value",
     "displayValue":"WELLCRAFT",
     "value":"Make.WELLCRAFT."
  },
  {
     "type":"value",
     "displayValue":"WESCRAFT",
     "value":"Make.WESCRAFT."
  },
  {
     "type":"value",
     "displayValue":"WESTCAT",
     "value":"Make.WESTCAT."
  },
  {
     "type":"value",
     "displayValue":"WESTCOASTER",
     "value":"Make.WESTCOASTER."
  },
  {
     "type":"value",
     "displayValue":"WESTERBERG",
     "value":"Make.WESTERBERG."
  },
  {
     "type":"value",
     "displayValue":"Western Boat Building Co",
     "value":"Make.Western Boat Building Co."
  },
  {
     "type":"value",
     "displayValue":"WESTERN CRAFT",
     "value":"Make.WESTERN CRAFT."
  },
  {
     "type":"value",
     "displayValue":"WESTPORT",
     "value":"Make.WESTPORT."
  },
  {
     "type":"value",
     "displayValue":"WESTSAIL",
     "value":"Make.WESTSAIL."
  },
  {
     "type":"value",
     "displayValue":"WHARRAM",
     "value":"Make.WHARRAM."
  },
  {
     "type":"value",
     "displayValue":"WHITEHAVEN",
     "value":"Make.WHITEHAVEN."
  },
  {
     "type":"value",
     "displayValue":"WHITELEY CRAFT",
     "value":"Make.WHITELEY CRAFT."
  },
  {
     "type":"value",
     "displayValue":"WHITSUNDAY",
     "value":"Make.WHITSUNDAY."
  },
  {
     "type":"value",
     "displayValue":"WHITTLEY",
     "value":"Make.WHITTLEY."
  },
  {
     "type":"value",
     "displayValue":"WIDER",
     "value":"Make.WIDER."
  },
  {
     "type":"value",
     "displayValue":"Wildsea",
     "value":"Make.Wildsea."
  },
  {
     "type":"value",
     "displayValue":"WILLIAM ATKINS",
     "value":"Make.WILLIAM ATKINS."
  },
  {
     "type":"value",
     "displayValue":"WILLIAM FIFE",
     "value":"Make.WILLIAM FIFE."
  },
  {
     "type":"value",
     "displayValue":"WILLIAM GARDEN",
     "value":"Make.WILLIAM GARDEN."
  },
  {
     "type":"value",
     "displayValue":"William Hand",
     "value":"Make.William Hand."
  },
  {
     "type":"value",
     "displayValue":"WILLIAMS",
     "value":"Make.WILLIAMS."
  },
  {
     "type":"value",
     "displayValue":"Windsor",
     "value":"Make.Windsor."
  },
  {
     "type":"value",
     "displayValue":"Windy",
     "value":"Make.Windy."
  },
  {
     "type":"value",
     "displayValue":"WINNINGYACHTS",
     "value":"Make.WINNINGYACHTS."
  },
  {
     "type":"value",
     "displayValue":"WITCH CRAFT",
     "value":"Make.WITCH CRAFT."
  },
  {
     "type":"value",
     "displayValue":"WOODNUTT",
     "value":"Make.WOODNUTT."
  },
  {
     "type":"value",
     "displayValue":"WOODWARD",
     "value":"Make.WOODWARD."
  },
  {
     "type":"value",
     "displayValue":"Woody Marine",
     "value":"Make.Woody Marine."
  },
  {
     "type":"value",
     "displayValue":"WOOLLACOTT",
     "value":"Make.WOOLLACOTT."
  },
  {
     "type":"value",
     "displayValue":"WORKBOAT",
     "value":"Make.WORKBOAT."
  },
  {
     "type":"value",
     "displayValue":"WORLD CAT",
     "value":"Make.WORLD CAT."
  },
  {
     "type":"value",
     "displayValue":"X-FACTOR",
     "value":"Make.X-FACTOR."
  },
  {
     "type":"value",
     "displayValue":"X-YACHTS",
     "value":"Make.X-YACHTS."
  },
  {
     "type":"value",
     "displayValue":"XFI",
     "value":"Make.XFI."
  },
  {
     "type":"value",
     "displayValue":"XPRESS BOATS",
     "value":"Make.XPRESS BOATS."
  },
  {
     "type":"value",
     "displayValue":"XXX Sports Boats",
     "value":"Make.XXX Sports Boats."
  },
  {
     "type":"value",
     "displayValue":"Yalta",
     "value":"Make.Yalta."
  },
  {
     "type":"value",
     "displayValue":"YALTA CRAFT",
     "value":"Make.YALTA CRAFT."
  },
  {
     "type":"value",
     "displayValue":"Yamaha",
     "value":"Make.Yamaha."
  },
  {
     "type":"value",
     "displayValue":"YELLOWFIN",
     "value":"Make.YELLOWFIN."
  },
  {
     "type":"value",
     "displayValue":"YNGLING",
     "value":"Make.YNGLING."
  },
  {
     "type":"value",
     "displayValue":"YORK",
     "value":"Make.YORK."
  },
  {
     "type":"value",
     "displayValue":"YOUNG",
     "value":"Make.YOUNG."
  },
  {
     "type":"value",
     "displayValue":"YOUNG CRAFT",
     "value":"Make.YOUNG CRAFT."
  },
  {
     "type":"value",
     "displayValue":"ZAR FORMENTI",
     "value":"Make.ZAR FORMENTI."
  },
  {
     "type":"value",
     "displayValue":"ZAR MINI",
     "value":"Make.ZAR MINI."
  },
  {
     "type":"value",
     "displayValue":"ZD BOATS",
     "value":"Make.ZD BOATS."
  },
  {
     "type":"value",
     "displayValue":"ZENITH",
     "value":"Make.ZENITH."
  },
  {
     "type":"value",
     "displayValue":"Zephyr",
     "value":"Make.Zephyr."
  },
  {
     "type":"value",
     "displayValue":"ZESTON",
     "value":"Make.ZESTON."
  },
  {
     "type":"value",
     "displayValue":"Zodiac",
     "value":"Make.Zodiac."
  },
  {
     "type":"value",
     "displayValue":"ZODIAC MILPRO",
     "value":"Make.ZODIAC MILPRO."
  }
]