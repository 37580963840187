import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import './style.sass'
import MapContainer from '../../common/MapContainer'

const FullScreenMap = ({ match }) => {

	const title = match.params ? match.params.title : null
	const lat = match.params ? match.params.lat : null
	const lng = match.params ? match.params.lng : null


	return (
		<MainLayout>
			<div className="full-screen-map-component">
				<h1>{title}</h1>
				<p><b>Note:</b> Pinch to zoom in or out!</p>
				<MapContainer 
					style={{ width: "100%", height: "100%", margin: 0 }} 
					markers={[{lat, lng}]}
					initialCenter={{lat, lng}}
					zoom={18}
				/>
			</div>
		</MainLayout>
	);
}

export default FullScreenMap;
