import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

// Check if browser is IE
export const isIE = () => {
  var ua = navigator.userAgent, tem, M = ua.match(/(msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    document.write("This Web Application is not supported in Internet Explorer! Please use a different browser!")
    return { name: 'IE', version: (tem[1] || '') };
  }
}

// To check if the owenr account is setup
export const isOwnerAccountSetup = (user) => {
  if (!user) return false;
  return user.description && user.phoneNumber && user.phoneNumberVerified && user.bankDetails;
}

// To upload a batch of files to S3
export const uploadToS3 = (files, path = '', type) => { 
  const getcontentType = () => {
    switch (type) {
      case 'image':
        return 'image/*';
      case 'pdf':
        return 'application/pdf';
      default:
        return '*';
    }
  }
  const storagePutRequests = files.map(file => (
    Storage.put(`${path + uuidv4()}-${file.name}`, file, { contentType: getcontentType })
  ))
  return Promise.all(storagePutRequests)
}

// To load external script
export const loadScript = (scriptUrl, scriptId, callback) => {
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.id = scriptId;
    document.body.appendChild(script);
    
    script.onload = () => {
      if (callback) callback();
    };
  }
  
  if (existingScript && callback) callback();
};


// Get overall rating value
export const getOverallRatingValue = (reviews) => {
  if(!reviews) return 0;

  const totalRatingValue = reviews.reduce((total, review) => {
    return total + getCombinedAverageOfRatings(review.ratings)
  }, 0)

  return Math.round((totalRatingValue / reviews.length) * 2) / 2 
}

// Get combined average of ratings 
export const getCombinedAverageOfRatings = (ratings) => {
  const totalRatingValue = Object.keys(ratings).reduce((total, key) => {
    return total + ratings[key]
  }, 0);
  
  return Math.round((totalRatingValue / Reflect.ownKeys(ratings).length) * 2) / 2 
}

// Get combined average of ratings of specific criteria
export const getCriteriaAverageRating = (reviews, criteria) => {
  if(!reviews) return 0;

  const totalRatingValueOfCriteria = reviews.reduce((total, review) => {
    return total + review.ratings[criteria]
  }, 0)

  return Math.round((totalRatingValueOfCriteria / reviews.length) * 2) / 2 
}
