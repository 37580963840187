import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { Input, InputNumber } from 'antd'

const OwnerBankDetailsForm = ({details, onChange}) => {
	const {accountName, accountNumber, bankName, bankBranch} = details

	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	return (
		<div className="owner-bank-details-form-component">
			<Container>
				<Row>
					<Col>
						<Input 
							placeholder="Account Name" 
							onChange={(e) => onChangeHandler("accountName", e.target.value)}
							value={accountName}
						/>
						<InputNumber 
							placeholder="Account Number"
							onChange={(val) => onChangeHandler("accountNumber", val)} 
							value={accountNumber}
						/>
						<Input 
							placeholder="Bank Name"
							onChange={(e) => onChangeHandler("bankName", e.target.value)} 
							value={bankName}
						/>
						<Input 
							placeholder="Bank Branch"
							onChange={(e) => onChangeHandler("bankBranch", e.target.value)} 
							value={bankBranch}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default OwnerBankDetailsForm;
