import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'

// Icons
import withCaptain from '../../../../../assets/icons/operatorOptionIcons/with-captain.png'
import withoutCaptain from '../../../../../assets/icons/operatorOptionIcons/without-captain.png'
import flexible from '../../../../../assets/icons/operatorOptionIcons/flexible.png'

const OperatorOptionsForm = ({operatorOption, onChange}) => {

	const onClickHandler = (selectedOperatorOption) => {
		onChange("operatorOption", selectedOperatorOption)
	}

	return (
		<div className="operator-options-form-component">
			<Container>
				<Row>
					<Col xs={4}>
						<OperatorOptionCard 
							title="With Captain"
							image={withCaptain}
							selected={operatorOption === "With Captain"}
							onClick={() => onClickHandler("With Captain")}
						/>
					</Col>
					<Col xs={4}>
						<OperatorOptionCard 
							title="Without Captain"
							image={withoutCaptain}
							selected={operatorOption === "Without Captain"}
							onClick={() => onClickHandler("Without Captain")}
						/>
					</Col>
					<Col xs={4}>
						<OperatorOptionCard 
							title="Flexible"
							image={flexible}
							selected={operatorOption === "Flexible"}
							onClick={() => onClickHandler("Flexible")}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

const OperatorOptionCard = ({title, image, selected, onClick}) => (
	<div 
		className={selected ? "operator-options-card-component operator-option-selected" : "operator-options-card-component" } 
		onClick={onClick}
	>
		<img src={image} alt={title} />
		<p>{title}</p>
	</div>
)

export default OperatorOptionsForm;
