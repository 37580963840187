import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { Input } from 'antd'

const OwnerAccountDetailsForm = ({details, onChange}) => {
	const {name, description} = details

	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	const isDescriptionNotValid = description && description.length < 80;

	return (
		<div className="owner-account-details-form-component">
			<Container>
				<Row>
					<Col>
						<Input 
							placeholder="Name" 
							value={name}
							disabled
						/>
						<p>Enter a short <b>description about yourself</b>. This will be shown in the experience listings you create!</p>
						<Input.TextArea
							autoSize={{ minRows: 3, maxRows: 4 }}
							placeholder="Description"
							onChange={(e) => onChangeHandler("description", e.target.value)} 
							value={description}
						/>
						{!isDescriptionNotValid || <p className="error-msg">Description should be at least <b>80</b> characters long! <b>{80 - description.length}</b> more!</p>}
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default OwnerAccountDetailsForm;
