import ApiBuilder from './ApiBuilder';

/**
 *
 * GET : getUser
 *
 */
const getUser = () => {
  return ApiBuilder.API.get('/user');
};

/**
 *
 * POST : updateOwnerDetailsAndVerifyPhoneNumber
 *
 */
const updateOwnerDetailsAndVerifyPhoneNumber = (payload) => {
  return ApiBuilder.API.post('/user/owner', {
    ...payload
  });
};

/**
 *
 * POST : updateVerifiedOwnerDetails - update details of a verified owner (Verified Phone number)
 *
 */
const updateVerifiedOwnerDetails = (payload) => {
  return ApiBuilder.API.post('/user/owner/verified', {
    ...payload
  });
};

/**
 *
 * POST : getGeneratedVerificationCode
 *
 */
const getGeneratedVerificationCode = (phoneNumber) => {
  return ApiBuilder.API.post('/user/owner/verify', {
    phoneNumber
  });
};

export default {
  getUser,
  updateOwnerDetailsAndVerifyPhoneNumber,
  updateVerifiedOwnerDetails,
  getGeneratedVerificationCode
};
