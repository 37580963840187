import React from 'react'
import './style.sass'
import { Modal, Tag, Button } from 'antd';
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const BookingDetailsModal = ({ booking, visible, setVisible, isOwnerBooking }) => {
  const { _id, departureDate, type, ownerQuotedPrice, group, experience, sharingRequest } = booking
  const history = useHistory()
  const { width } = useWindowDimensions();

  const style = {
    col: {
      textAlign: 'center',
      padding: 10,
    },
    p: {
      fontSize: 18
    },
    h3: {
      fontWeight: 400,
    },
  }

  const getModalWidth = () => {
    if (width < 700) return '100%';
    if (width < 1000) return '70%';;
    if (width < 1350) return '60%';;
    if (width >= 1350) return '40%';;
  }

  const getArivalDate = () => {
    const date = moment(departureDate).format('YYYY-MM-DD')
    const time = moment(type === "SHARING" ? sharingRequest.departureTime : experience.departureTime).format('hh-mm')
    return moment(date + ' ' + time).add({ h: type === "SHARING" ? sharingRequest.numberOfHours : experience.numberOfHours}).format('YYYY/MM/DD')
  }
  
  return (
    <div className="booking-details-modal-component" >
      <Modal
        visible={visible}
        footer={null}
        centered
        width={getModalWidth()}
        onCancel={() => setVisible(false)}
      >
        {!booking || (
          <div>
            <Container fluid={true}>
              <Row>
                <Col style={style.col}>
                  <h5 style={style.h5}>
                    <b>Ref:</b> #{_id.slice(0, 10)}{" "}
                    <Tag color="blue">{type}</Tag>
                    {moment(departureDate).add(type === "SHARING" ? sharingRequest.numberOfHours : experience.numberOfHours).isBefore(moment()) ? <Tag color="green">Completed</Tag>: null}
                  </h5>
                  <h3 style={style.h3}>{moment(departureDate).format('YYYY/MM/DD')}</h3>
                  <h4 style={style.h4}>
                    {type !== "CUSTOM" || <b>CUSTOM : </b>} 
                    {type === "SHARING" ? `Trip with ${sharingRequest.userId.firstName} ${sharingRequest.userId.lastName}` : experience.name}, 
                    <b> {type === "SHARING" ? sharingRequest.numberOfHours : experience.numberOfHours} Hours</b>
                  </h4> 
                  <Button
                    type='link'
                    onClick={() => {
                      if (type === "SHARING"){
                        history.push(`/sharing/detail/${sharingRequest._id}`)
                      } else {
                        history.push(`/experience/detail/${experience._id}`)
                      }
                    }}
                    style={style.btn}
                  >
                    {'View Listing  >'}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={style.p}>
                    <b>Departure Date:</b> {moment(departureDate).format('YYYY/MM/DD')}<b> | Departure Time:</b> {moment(type === "SHARING" ? sharingRequest.departureTime : experience.departureTime).format('h:mm a')}
                  </p>
                  <p style={style.p}><b>Arrival Date:</b> {getArivalDate()}<b> | Arrival Time:</b> {moment(type === "SHARING" ? sharingRequest.departureTime : experience.departureTime).add({ h: type === "SHARING" ? sharingRequest.numberOfHours : experience.numberOfHours}).format('h:mm a')}</p>
                  <p style={style.p}><b>Group:</b> { type === "SHARING" ? `${booking.group.adults} ${booking.group.adults === 1 ? 'Person' : 'People'}`: `Adults - ${booking.group.adults}, Kids - ${booking.group.kids}`} </p>
                  <p style={style.p}><b>Amount Paid:</b> ${type === "CUSTOM" ? ownerQuotedPrice : type === "SHARING" ? sharingRequest.pricePerSeat * group.adults : experience.price}</p>
                  <p style={style.p}><b>Other Requests: </b> {booking.otherRequests ? booking.otherRequests : 'N/A'}</p>
                  <p style={style.p}><b>{isOwnerBooking ? 'Renter' : 'Booking' } Name: </b> {booking.name}</p>
                  {isOwnerBooking ? (
                    <p style={style.p}><b>Renter Contact Number:</b> {booking.contactNumber}</p>
                  ) : (
                    <p style={style.p}><b>Owner Contact Number:</b> {type === "SHARING" ? sharingRequest.userId.phoneNumber : experience.userId.phoneNumber}</p>
                  )}

                  {isOwnerBooking || (
                    <p style={style.p}>
                      <b>Boat Location </b>
                      <Button 
                        type='primary' 
                        onClick={() => {
                            if (type === "SHARING"){
                              history.push(`/full/map/${'Boat Location'}/${sharingRequest.location.lat}/${sharingRequest.location.lng}`)
                            } else {
                              history.push(`/full/map/${'Boat Location'}/${experience.location.lat}/${experience.location.lng}`)
                            }
                          }
                        }
                      >
                        Open in Map
                      </Button>
                    </p>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default BookingDetailsModal