import React, { useState } from 'react'
import './style.sass'
import placeholder from '../../../assets/images/placeholder.png'
import { Button, Tag } from 'antd';
import { PUBLIC_S3_BUCKET_URL } from '../../../constants/AwsConstants'
import moment from 'moment'
import BookingRequestDetailsModal from '../BookingRequestDetailsModal'

const ProfileBookingRequestCard = ({bookingRequest, isOwnerBookingRequest}) => {
  const { _id, status, departureDate, departureTime, arrivalDate, arrivalTime, experience } = bookingRequest;
  const [bookingRequestDetailIsVisible, setBookingRequestDetailIsVisible] = useState(false)
  const requestIsExpired = moment(departureDate).add(experience.numberOfHours).isBefore(moment())

  const renderStatusTag = () => {
    if(requestIsExpired) {
      return <Tag color="red">EXPIRED</Tag>
    }
    if (status === "PENDING") {
      return <Tag color="orange">PENDING</Tag>
    }

    if (status === "REJECTED") {
      return <Tag color="red">REJECTED</Tag>
    }

    if (status === "APPROVED") {
      if(!isOwnerBookingRequest){
        return <Tag color="green">APPROVED</Tag>
      }
      return <Tag color="blue">QUOTED</Tag>
    }

    return null
  } 

  const renderButtons = () => {
    if (!requestIsExpired) {
      if (status === "PENDING"  && isOwnerBookingRequest){
        return (
          <Button type="primary" onClick={() => setBookingRequestDetailIsVisible(true)} style={{ marginBottom: 10, backgroundColor: '#fbbd33', borderColor: '#fbbd33' }}>
            QUOTE
          </Button>
        )
      }
      
      if (status === "APPROVED" && !requestIsExpired) {
        if(!isOwnerBookingRequest){
          return (
            <Button type="primary" onClick={() => setBookingRequestDetailIsVisible(true)} style={{ marginBottom: 10, backgroundColor: '#8bd627', borderColor: '#8bd627' }}>
              CONFIRM
            </Button>
          )
        }
      }
      
      return (
        <Button type="primary" onClick={() => setBookingRequestDetailIsVisible(true)} style={{ marginBottom: 10 }}>
          VIEW
        </Button>
      )
    }
    return null
  }

  const getNumberOfHours = () => {
    const dDate = moment(departureDate).format('YYYY-MM-DD')
    const dTime = moment(departureTime).format('HH:mm')

    const aDate = moment(arrivalDate).format('YYYY-MM-DD')
    const aTime = moment(arrivalTime).format('HH:mm')
  
    return moment(aDate + ' ' + aTime).diff(moment(dDate + ' ' + dTime), 'hours')
  }

  return ( 
    <div className="profile-cards-horizontal-component">
      <img 
        src={experience.images && experience.images.length > 0 ? PUBLIC_S3_BUCKET_URL + experience.images[0] : placeholder} 
        alt="logo"
      />
      <div className="right-container">
        <div className="content-container"> 
          <h3>Ref: #{_id.slice(0, 10)} {renderStatusTag()}</h3>
          
          <h4>{experience.name.slice(0, 70).trim()}{experience.name.length < 70 || '...'} | <b>{getNumberOfHours()} Hours</b></h4>      
          <h5>{moment(departureDate).format('YYYY/MM/DD')}</h5>
        </div>
        <div className="button-container"> 
          {renderButtons()}
        </div>
      </div>
      {!bookingRequestDetailIsVisible || (
        <BookingRequestDetailsModal 
          bookingRequest={bookingRequest} 
          visible={bookingRequestDetailIsVisible} 
          setVisible={setBookingRequestDetailIsVisible}
          isOwnerBookingRequest={isOwnerBookingRequest}
        />
      )}
    </div>
  );
}

export default ProfileBookingRequestCard