import {
  SET_BOATS,
  ADD_BOAT,
  EDIT_BOAT
} from '../actions/ActionTypes';

const defaultState = {
  boats: null,
};

const BoatReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_BOATS:
      return {
        ...state,
        boats: action.payload
      };
      case ADD_BOAT:
        return {
          ...state,
          boats: state.boats ? [action.payload, ...state.boats] : [action.payload]
        };
      case EDIT_BOAT:
        return {
          ...state,
          boats: state.boats ? [action.payload, ...state.boats.filter(b => b._id !== action.payload._id)] : [action.payload]
        };
    default:
      return state;
  }
};

export default BoatReducer;