import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { classnames } from './helpers';
import { GOOGLE_MAPS_API_KEY } from '../../../constants/ApiConstants';
import CustomButton from '../../common/CustomButton'
import mapMarker from '../../../assets/icons/map-marker.png'
import './style.sass'
import { loadScript } from '../../../helpers/common';

class LocationSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,
      isScriptLoaded: false
    };
  }

  componentDidMount() {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, 'googleMapsScript', () => {
      this.setState({ isScriptLoaded: true })
    })
  }

  handleChange = address => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    });
  };

  handleSelect = selected => {
    this.setState({ isGeocoding: true, address: selected });
    geocodeByAddress(selected)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        });
      })
      .catch(error => {
        this.setState({ isGeocoding: false });
        console.log('error', error);
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    });
  };

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  onSearchClicked = () => {
    const {address, latitude, longitude } = this.state;
    const {history} = this.props;

    if (address && latitude && longitude) {
      history.push(`/search/${address}/${latitude}/${longitude}`)
    } else {
      geocodeByAddress(address)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        history.push(`/search/${address}/${lat}/${lng}`)
      })
      .catch(error => {
        this.setState({ isGeocoding: false });
        console.log('error', error);
      });
    }
  }

  render() {
    const {address, isScriptLoaded} = this.state;

    if (isScriptLoaded) {
      return (
        <div className="floating-search-bar-component">
          <div className='search-map-marker-container'>
            <img src={mapMarker} className='search-map-marker' alt='marker'/>
          </div>
          <PlacesAutocomplete
            value={address}
            shouldFetchSuggestions={address.length > 2}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            onError={this.handleError}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
              return (
                <div>
                  {/* SUGESTIONS */}
                  <div className="floating-search-bar-sugestions">
                    {suggestions.length > 0 && (
                      <div className="autocomplete-container">
                        {suggestions.map(suggestion => {
                          const className = classnames('suggestion-item', {
                            'suggestion-item--active': suggestion.active,
                          });

                          return (
                            <div {...getSuggestionItemProps(suggestion, { className })}>
                              <strong>
                                {suggestion.formattedSuggestion.mainText}
                              </strong>{' '}
                              <small>
                                {suggestion.formattedSuggestion.secondaryText}
                              </small>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  {/* SEARCH TEXT FIELD */}
                  <div className="floating-search-bar-text-input">
                    <div className="search-input-container">
                      <input
                        {...getInputProps({
                          placeholder: 'Where are you?',
                          className: 'search-input',
                        })}
                      />
                    </div>
                  </div>
                </div>
              );
            }}
          </PlacesAutocomplete>
          <CustomButton
            text="SEARCH"
            onClick={this.onSearchClicked}
            style={{ borderRadius: "0px 5px 5px 0px", height: 60, border: "5px solid #fff" }}
          />
        </div>
      );
    } else {
      return null
    }
  }
}

export default LocationSearchBar;