import React, { useState } from 'react'
import './style.sass'
import ItemsCarousel from 'react-items-carousel'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import ReviewCard from '../../common/ReviewCard'
import { getCombinedAverageOfRatings } from '../../../helpers/common'

const TextReviews = ({reviews}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const { width } = useWindowDimensions();

  const getNumberOfCards = () => {
    if (width < 500) return 1;
    if (width < 800) return 1;
    if (width >= 800) return 2;
  }

  return ( 
    <div className="text-reviews-component">
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={getNumberOfCards()}
        gutter={12}
        chevronWidth={35}
        outsideChevron
        leftChevron={<button className='arrow-button'>{'<'}</button>}
        rightChevron={<button className='arrow-button'>{'>'}</button>}
      >
        {reviews ? reviews.map(review => (
            <ReviewCard 
              key={review._id}
              name={review.userId.firstName + " " + review.userId.lastName}
              image={review.userId.image} 
              date={review.createdDate}
              rating={getCombinedAverageOfRatings(review.ratings)}
              review={review.review}
            />
        )) : null}
      </ItemsCarousel>
    </div>
  );
}

export default TextReviews