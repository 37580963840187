/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import WizardCard from '../../common/WizardCard'
import BookingService from '../../../services/BookingService'
import ExperienceService from '../../../services/ExperienceService'
import { useSelector } from 'react-redux'

// Forms
import InstantBookingDatesForm from './Forms/InstantBookingDatesForm'
import InstantBookingGroupForm from './Forms/InstantBookingGroupForm'
import InstantBookingDetailsForm from './Forms/InstantBookingDetailsForm'
import PayPalPayment from '../../common/PayPalPayment'
import WizardCompletionCard from '../../common/WizardCompletionCard'


const InstantBookingWizard = ({ history }) => {
	const experienceId = history.location.state ?  history.location.state.experienceId : null

	const user = useSelector((state) => state.user.user);
	const [wizardIndex, setWizardIndex] = useState(0)
	const [experience, setExperience] = useState(null)
	const [experienceBookedDates, setExperienceBookedDates] = useState(null)
	const [processing, setProcessing] = useState(true)
	const [addedBooking, setAddedBooking] = useState(null)

	const [formData, setFormData] = useState({
		departureDate: null,
		adults: 0,
		kids: 0,
		name: null,
		contactNumber: null,
		otherRequests: null,
		paid: false,
		type: 'INSTANT',
	}) 

	useEffect(() => {
		setTimeout(() => {
			if (experienceId){
				ExperienceService.getExperienceById(experienceId).then((experienceRes) => {
					const fetchedExperience = experienceRes.data.result
					setExperience(fetchedExperience)

					BookingService.getExperienceBookedDates(experienceId).then((datesRes) => {
						const fetchedExperienceBookedDates = datesRes.data.result
						setExperienceBookedDates(fetchedExperienceBookedDates)
						setProcessing(false)
					}).catch((err) => {
						console.log(err)
					});
				}).catch((err) => {
					console.log(err)
				});
			}
		}, 200);
	}, [])

	const getRequestPayload = () => {
		const { kids, adults } = formData

		return {
			...formData,
			group: {
				kids,
				adults
			},
			experience
		}		
	}

	const onChangeHandler = (key, value) => {
		setFormData(formData => ({
			...formData,
			[key]: value
		}))
	}

	const onNextHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex + 1)
		}, 100);
	}

	const onBackHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex - 1)
		}, 200);
	}

	const onSubmitHandler = () => { 
		setProcessing(true)
		const payload = getRequestPayload()

		BookingService.addBooking(payload).then((res) => {
			const fetchedBooking = res.data.result;
			setAddedBooking(fetchedBooking)
			setWizardIndex(wizardIndex => wizardIndex + 1)
			setProcessing(false)
		}).catch((err) => {
			console.log(err)
			setProcessing(false) 
		});
	}

	const onPaymentSuccess = (confirmedBooking) => {
		// console.log(confirmedBooking) - confirmedBooking object could be used if needed

		setWizardIndex(wizardIndex => wizardIndex + 1)
  }

	const onCompleteHandler = () => {
		history.push('/profile/renter/bookings')
	}

	const renderWizardCards = () => {
		const { 
			departureDate,
			adults,
			kids,
			name,
			contactNumber,
			otherRequests,
		} = formData

		const mainText = "INSTANT BOOKING"
		const subText = experience.name

		switch (wizardIndex) {
			case 0:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 1 : Select Date"
						form={<InstantBookingDatesForm onChange={onChangeHandler} departureDate={departureDate} experience={experience} experienceBookedDates={experienceBookedDates} />}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						buttonDisabled={!departureDate}
						loading={processing}
					/>
				)	
			case 1:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 2 : Select Group"
						form={<InstantBookingGroupForm onChange={onChangeHandler} group={{adults, kids}} maxGroupSize={experience.groupSize}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(adults || kids)}
						loading={processing}
					/>
				)	
			case 2:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 3 : Booking Details"
						form={<InstantBookingDetailsForm onChange={onChangeHandler} details={{name, contactNumber, otherRequests}} />}
						buttonText="NEXT STEP"
						onClick={onSubmitHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(name && contactNumber && contactNumber.length === 12)}
						loading={processing}
					/>
				)
			case 3:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 4 : Make Payment to Complete Booking!"
						form={<PayPalPayment 
							bookingId={addedBooking ? addedBooking._id : null}
							amount={experience.price} 
							description={`${experience._id}-${user._id}`} 
							onSuccessCallback={onPaymentSuccess} 
						/>}
						loading={processing}
					/>
				)
			case 4:
				return (					
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Booking Complete"
						form={(
							<WizardCompletionCard
								mainText="Booking Confirmed!"
								subText="Check your email for the booking confirmation. You can also view your bookings in Your Profile Page!" 
							/>
						)}
						buttonText="GO TO MY PROFILE"
						onClick={onCompleteHandler}
					/>
				)	
			default:
				return null
		}
	}
	
	return (
		<MainLayout>
			<div className="instant-booking-wizard-component">
				<div className="center-container">
					{experience && user._id !== experience.userId._id ? renderWizardCards() : null}
				</div>
			</div>
		</MainLayout>
	);
}

export default InstantBookingWizard;
