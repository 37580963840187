import React, { useState } from 'react'
import './style.sass'
import { Modal, Button } from 'antd';
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import InputPhoneNumber from '../../common/InputPhoneNumber'
import SharingService from '../../../services/SharingService';
import WizardCompletionCard from '../../common/WizardCompletionCard'

const SharingRequestShareModal = ({ request, setVisible }) => {
  const { _id, departureDate, departureTime, userId, numberOfHours } = request
  const { width } = useWindowDimensions();
  const history = useHistory()
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [proccessing, setProccessing] = useState(false)
  const [isSharingDone, setIsSharingDone] = useState(false)


  const onShareClicked = () => {
    setProccessing(true)
    SharingService.shareWithFriendViaSMS(phoneNumber, _id).then((res) => {
      setProccessing(false)
      setPhoneNumber(null)
      setIsSharingDone(true)
    }).catch((err) => {
      setProccessing(false)
    });
  }

  const style = {
    col: {
      textAlign: 'center',
      padding: 10,
    },
    p: {
      fontSize: 18
    },
    h3: {
      fontWeight: 400,
    },
    tag: {
      marginBottom: 10
    }
  }

  const getModalWidth = () => {
    if (width < 700) return '90%';
    if (width < 1000) return '60%';;
    if (width < 1350) return '50%';;
    if (width >= 1350) return '35%';;
  }

  return (
    <div className="sharing-request-details-modal-component" >
      <Modal
        visible={true}
        footer={null}
        centered
        width={getModalWidth()}
        onCancel={proccessing ? () => {} : () => setVisible(false)}
      >
        {!request || (
          <div>
            <Container fluid={true}>
              <Row>
                <Col style={style.col}>
                  <h5 style={style.h5}>
                    <b>Ref:</b> #{_id.slice(0, 10)}
                  </h5>
                  <h3 style={style.h3}>{moment(departureDate).format('YYYY/MM/DD')} | {moment(departureTime).format('h:mm a')}</h3>
                  <h4 style={style.h4}>Trip with {userId.firstName}, <b>{numberOfHours} Hours</b></h4> 
                  <Button
                    type='link'
                    onClick={() => { history.push(`/sharing/detail/${_id}`)}}
                    style={style.btn}
                  >
                    {'View Listing  >'}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col style={style.col}>
                  {!isSharingDone ? (
                    <div>
                      <h3 style={style.h3}>Share Booking with friend</h3>
                      <p style={style.p}>Enter the <b>Phone Number</b> of a friend to share this booking!</p>
                      <InputPhoneNumber
                        placeholder="Phone Number (XXX XXX XXX)"
                        value={phoneNumber}
                        onChange={(val) => setPhoneNumber(val)} 
                        inputstyle={{ fontSize: 18 }}
                        countries={'all'}
                      />
                      <Button type="primary" size="large" onClick={onShareClicked} style={{ marginTop: 10 }} disabled={!phoneNumber || phoneNumber.length !== 12} loading={proccessing}>
                        SHARE BOOKING
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <WizardCompletionCard
                        mainText="SHARED SUCCESSFULLY"
                        subText="You shared the sharing booking successfully!" 
                      />
                      <Button type="primary" size="large" onClick={() => setIsSharingDone(false)} style={{ marginTop: 10 }}>
                        SHARE AGAIN
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default SharingRequestShareModal