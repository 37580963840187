import { combineReducers } from 'redux';

import UserReducer from './UserReducer'
import BoatReducer from './BoatReducer'
import ExperienceReducer from './ExperienceReducer'
import BookingReducer from './BookingReducer'
import SharingReducer from './SharingReducer'

export default combineReducers({
  user: UserReducer,
  boat: BoatReducer,
  experience: ExperienceReducer,
  booking: BookingReducer,
  sharing: SharingReducer
});
