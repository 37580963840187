import React, { useState, useEffect } from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout';
import { Switch } from 'antd';
import RenterSection from './RenterSection';
import OwnerSection from './OwnerSection';
import FooterSmall from '../../common/FooterSmall';
import { Route, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isOwnerAccountSetup } from '../../../helpers/common';

const Profile = ({ history, match }) => {
	const user = useSelector((state) => state.user.user);
	const [isChecked, setIsChecked] = useState(false)

	useEffect(() => {
		configureSwitchState()
	}, [history.location])

	const configureSwitchState = () => {
		if (history.location.pathname.includes('/owner')) {
			setIsChecked(true)
		} else {
			setIsChecked(false)
		}
	}

	const onSwitchChange = (checked) => {
		if (checked) {
			history.push(`${match.url}/owner/boats`)
		} else {
			history.push(`${match.url}/renter/bookings`)
		}
		setIsChecked(checked)
	}

	return (
		<MainLayout>
			<div className="profile-component">

				{(isChecked && !(isOwnerAccountSetup(user))) ? (
					<div className="account-warning-container">
						<p>
							Your Owner's Account needs to be configured.
							<Link
								to='/owner/setup'
								style={{ color: '#fff', fontWeight: '400', marginLeft: 3 }}
							> 
								Click here.
							</Link>
						</p>
					</div>
				) : null}
				
				<div className="welcome-container">
					<h4>
						Welcome To Your <span className='special-text'>{isChecked ? "Owner's" : "Renter's"}</span> Profile, <span className="bold">{`${user.firstName} ${user.lastName}`}</span>
					</h4>
				</div>
				<div className="switch-container">
					<label>RENTER</label>
					<Switch onChange={onSwitchChange} checked={isChecked}/>
					<label>OWNER</label>
				</div>

				{/* === Nested Routes === */}
				<Route
					path={`${match.url}/renter`}
					component={RenterSection}
				/>
				<Route
					path={`${match.url}/owner`}
					component={OwnerSection}
				/>
				{/* ===================== */}

			</div>
			<FooterSmall />
		</MainLayout>
	);
}

export default Profile;
