import React, { useState } from 'react'
import './style.sass'
import placeholder from '../../../assets/images/placeholder.png'
import { Rate, Button, Modal } from 'antd';
import moment from 'moment'

const ReviewCard = ({name, image, date, rating, review}) => {
  const [readmoreActive, setReadmoreActive] = useState(false)

  return ( 
    <div className="review-card-small-component">
        <div className="header-section">
            <img className="icon" src={image ? image : placeholder} alt="logo"/>
            <div>
              <p className="name"><b>{name}</b></p>
              <p className="date">{moment(date).format('YYYY/MM/DD')}</p>
              <div className="rating">
                <Rate 
                  style={{ color: '#fcc603', fontSize: 13, textDecorationColor: 'red' }} 
                  disabled 
                  defaultValue={rating}
                  allowHalf
                />
              </div>
            </div>
        </div>
        <hr />
        <div>
            <p className="review">
              {review.slice(0, 120)}
              {review.length > 120 ? (
                <Button type="link" onClick={() => setReadmoreActive(true)}>
                  {readmoreActive ? "Read Less >" : "Read More >"}
                </Button>
              ) : null}
            </p>
        </div>

        <Modal
          title={null}
          centered
          visible={readmoreActive}
          onCancel={() => setReadmoreActive(false)}
          footer={null}
        > 
          <h6>{name}</h6>
          <p style={{ marginBottom: 0}}>{review}</p>
        </Modal>
    </div>
  );
}

export default ReviewCard