import React from 'react'
import './style.sass'
import moment from 'moment'
import CustomButton from '../CustomButton';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SharingBookingCard = ({sharingRequest}) => {
  const user = useSelector((state) => state.user.user);
  const history = useHistory()
  const { _id, userId, departureDate, departureTime, pricePerSeat, seatsAvailable, status } = sharingRequest

  const renderBookButton = () => {
    if (moment(departureDate).isBefore(moment())) {
      return <p className="full-message">Sorry, the departure date of this ride has been passed!</p>
    } 

    if (status === "CANCELED") {
      return <p className="full-message">Sorry, this ride has been canceled!</p>
    }

    if (seatsAvailable <= 0) {
      return <p className="full-message">Sorry, this ride is full!</p>
    }

    return (
      <CustomButton 
        text="Instant Booking"
        style={{ width: "80%", marginTop: 10 }}
        onClick={() => { history.push('/sharing/booking', { sharingId: _id}) }}
        disabled={seatsAvailable <= 0}
      />
    )
  }


  return ( 
    <div className="sharing-booking-card-component">
      <div className="header">
        <p><span>${pricePerSeat}</span> | Per Person</p>
      </div>
      <p><b>Saling Date :</b> {moment(departureDate).format('YYYY/MM/DD')}</p>
      <p><b>Saling Time :</b> {moment(departureTime).format('h:mm a')}</p>
      <p><b>Available Seats :</b> {seatsAvailable}</p>

      {(user && (user._id === userId._id)) ? (
        <CustomButton 
          text="Manage"
          style={{ width: "80%", marginTop: 10 }}
          onClick={() => { history.push('/profile/owner/requests/sharing') }}
        />
      ) : (
        <div>
          {renderBookButton()}
        </div>
      )}
    </div>
  );
}

export default SharingBookingCard