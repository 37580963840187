import React, { useState } from 'react'
import './style.sass'
import { Modal, Tag, Button, Table } from 'antd';
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import SharingService from '../../../services/SharingService';
import SharingActions from '../../../redux/actions/SharingActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

const { confirm } = Modal;

const SharingRequestDetailsModal = ({ request, setVisible }) => {
  const { _id, departureDate, departureTime, userId, pricePerSeat, seatsAvailable, numberOfHours, bookings, boatId, status } = request
  const { width } = useWindowDimensions();
  const history = useHistory()
  const dispatch = useDispatch()
  const [cancelProcessing, setCancelProcessing] = useState(false)

  const tableColumns = [
    { title: 'Booking Id', dataIndex: '_id', render: _id => _id.slice(0, 10)},
    { title: 'Mates Name', dataIndex: 'name',  key: 'name' },
    { title: 'Contact Number', dataIndex: 'contactNumber',  key: 'contactNumber' },
    { title: 'Group Size', dataIndex: 'group', render: (group) => group.adults},
    { title: 'Payment Status', dataIndex: 'group', render: (group) => `Paid $${group.adults * pricePerSeat}`},
  ]

  const style = {
    col: {
      textAlign: 'center',
      padding: 10,
    },
    p: {
      fontSize: 18
    },
    h3: {
      fontWeight: 400,
    },
    tag: {
      marginBottom: 10
    }
  }

  const getModalWidth = () => {
    if (width < 700) return '100%';
    if (width < 1000) return '70%';;
    if (width < 1350) return '60%';;
    if (width >= 1350) return '40%';;
  }


  const renderMatesTag = () => {
    if (bookings.length === 0) {
      return <Tag style={style.tag} color="orange">NO MATES FOUND YET</Tag>
    }
    return <Tag style={style.tag} color="green">{bookings.length} {bookings.length === 1 ? "MATE" : "MATES"} FOUND</Tag>
  } 

  const renderStatusTag = () => {
    if (status === 'CANCELED') {
      return <Tag color="red">CANCELED</Tag>
    }
    return null
  } 

  const onCancelPressed = () => {
    confirm({
			title: 'Are you sure you want to cancel?',
			icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: 'Yes',
			onOk : async (close) => {
        close()
        setCancelProcessing(true)
        // =========================================================
        SharingService.cancel(_id).then((res) => {
          const updatedSharing= res.data.result;
          dispatch(SharingActions.updateOwnerSharingRequests(updatedSharing))
          setCancelProcessing(false)
          setVisible(false)
        }).catch((err) => {
          console.log(err)
        });
				// =========================================================
			}
    });
  }

  return (
    <div className="sharing-request-details-modal-component" >
      <Modal
        visible={true}
        footer={null}
        centered
        width={getModalWidth()}
        onCancel={cancelProcessing ? () => {} : () => setVisible(false)}
      >
        {!request || (
          <div>
            <Container fluid={true}>
              <Row>
                <Col style={style.col}>
                  <h5 style={style.h5}>
                    <b>Ref:</b> #{_id.slice(0, 10)}
                  </h5>
                  {renderMatesTag()}{renderStatusTag()} 
                  <br />
                  <h3 style={style.h3}>{moment(departureDate).format('YYYY/MM/DD')} | {moment(departureTime).format('h:mm a')}</h3>
                  <h4 style={style.h4}>Trip with {userId.firstName}, <b>{numberOfHours} Hours</b></h4> 
                  <Button
                    type='link'
                    onClick={() => { history.push(`/sharing/detail/${_id}`)}}
                    style={style.btn}
                  >
                    {'View Listing  >'}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col style={style.col}>
                  <p style={style.p}><b>Boat:</b> {boatId.name} - {boatId.brand} {boatId.model}</p>
                  <p style={style.p}><b>Price Per Seat:</b> ${pricePerSeat} | <b>Seats Available: </b> {seatsAvailable}</p>
                  <Table 
                    dataSource={request.bookings} 
                    columns={tableColumns} 
                    pagination={false} 
                    loading={!request}
                    bordered
                  />
                  <br />
                  {status === 'CANCELED' || (
                    <Button type="danger" size="large" onClick={onCancelPressed} style={{ marginLeft: 10 }} loading={cancelProcessing}>
                      CANCEL BOOKING
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default SharingRequestDetailsModal