import React, { useEffect, useState } from 'react'
import './style.sass'
import {Menu} from 'antd';
import {Container, Row, Col} from 'react-bootstrap'
import { Route, Link, useLocation } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

// Components
import Bookings from './Bookings'
import CompletedBookings from './CompletedBookings'
import RenterBookingRequests from './RenterBookingRequests'

const {SubMenu} = Menu;

const RenterSection = ({ match }) => {
  const location = useLocation()
  const menuItemStyle = {fontSize: 15}
  const menuSubItemStyle = {fontSize: 14}
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])

  useEffect(() => {
    setDefaultSelectedKeys([location.pathname])
  }, [location.pathname])

	return (
    <Fade delay={0} duration={1000}>
      <div className="renter-section-component">
        <Container>
          <Row>
            <Col md={3} className="left-col">
              <Menu
                mode="inline"
                selectedKeys={defaultSelectedKeys}
                defaultOpenKeys={["1"]}
                style={{ height: '100%', paddingTop: 10 }}
              >
                <SubMenu key="1" title="BOOKINGS" style={menuItemStyle}>
                  <Menu.Item key={`${match.url}/bookings`} style={menuSubItemStyle}>
                    <Link to={`${match.url}/bookings`}>CONFIRMED</Link>
                  </Menu.Item>
                  <Menu.Item key={`${match.url}/bookings/completed`} style={menuSubItemStyle}>
                    <Link to={`${match.url}/bookings/completed`}>COMPLETED</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key={`${match.url}/requests/booking`} style={menuItemStyle} >
                  <Link to={`${match.url}/requests/booking`}>BOOKING REQUESTS</Link>
                </Menu.Item>                
              </Menu>
            </Col>
            <Col md={9} className="right-col">
              {/* === Nested Routes === */}
              <Route
                exact
                path={`${match.url}/bookings`}
                component={Bookings}
              />
              <Route
                exact
                path={`${match.url}/bookings/completed`}
                component={CompletedBookings}
              />
              <Route
                exact
                path={`${match.url}/requests/booking`}
                component={RenterBookingRequests}
              />
              {/* ===================== */}
            </Col>
          </Row>
        </Container>
      </div>
    </Fade>
	);
}

export default RenterSection;
