import React, { useState } from 'react'
import './style.sass'
import placeholder from '../../../assets/images/placeholder.png'
import { PUBLIC_S3_BUCKET_URL } from '../../../constants/AwsConstants'
import CustomButton from '../../common/CustomButton';
import moment from 'moment'

const SharingRequestCard = ({request, onClick}) => {

  const { boatId, departureDate, departureTime, customMessage, userId, pricePerSeat } = request

  return ( 
    <div className="sharing-request-card-small-component">
      <div className="header-section">
        <img className="icon" src={boatId.images ? PUBLIC_S3_BUCKET_URL + boatId.images[0] : placeholder} alt="logo"/>
        <div className="header-text-section">
          <p className="name"><b>{boatId.name}</b></p>
          <p className="name-blue">{boatId.brand} {boatId.model}</p>
          <p className="name">{userId.firstName} {userId.lastName}</p>
          <p className="date">{moment(departureDate).format('YYYY/MM/DD')} | {moment(departureTime).format('hh:mm a')}</p>
        </div>
      </div>
      <hr />
      <div>
        <p className="custom-message">
          {customMessage.slice(0, 80).trim()}{customMessage.length < 80 || '...'}
        </p>
        <CustomButton 
          text={`View Trip ($${pricePerSeat} PP)`}
          onClick={onClick}
          style={{ width: '100%', marginTop: 15, padding: 10 }}
          outlined
        /> 
      </div>
    </div>
  );
}

export default SharingRequestCard