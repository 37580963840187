import React, { useState } from 'react'
import './style.sass'
import placeholder from '../../../assets/images/placeholder.png'
import { Button, Tag, DatePicker } from 'antd'
import moment from 'moment'
import { PUBLIC_S3_BUCKET_URL } from '../../../constants/AwsConstants'
import SharingRequestDetailsModal from '../SharingRequestDetailsModal'
import SharingRequestShareModal from '../SharingRequestShareModal'

const ProfileSharingRequestCard = ({ request, onViewClicked, onShareClicked }) => {
  const [viewIsVisible, setViewIsVisible] = useState(false)
  const [shareIsVisible, setShareIsVisible] = useState(false)

  const { images } = request.boatId
  const { _id, departureDate, boatId, pricePerSeat, seatsAvailable, numberOfHours, bookings, status } = request

  const renderMatesTag = () => {
    if (bookings.length === 0) {
      return <Tag color="orange">NO MATES FOUND YET</Tag>
    }
    return <Tag color="green">{bookings.length} {bookings.length === 1 ? "MATE" : "MATES"} FOUND</Tag>
  } 
  
  const renderStatusTag = () => {
    if (status === 'CANCELED') {
      return <Tag color="red">CANCELED</Tag>
    }
    if (moment(departureDate).isBefore(moment())){
      return <Tag color="green">COMPLETED</Tag>
    }
    return null
  } 

  return ( 
    <div className="profile-cards-horizontal-component">
      <img src={(images && images.length > 0) ? PUBLIC_S3_BUCKET_URL + images[0] : placeholder} alt="boat"/>
      <div className="right-container">
        <div className="content-container"> 
          <h3>Ref: #{_id.slice(0, 10)} {renderMatesTag()}{renderStatusTag()}</h3>
          <h2>{boatId.name} - {boatId.brand} {boatId.model} | <b>{numberOfHours} Hours</b></h2>
          <h5><b>Date:</b> {moment(departureDate).format('YYYY/MM/DD')} | <b>Price Per Seat:</b> ${pricePerSeat} | <b>Seats Available:</b> {seatsAvailable}</h5>      
        </div>
        <div className="button-container"> 
          <Button type="primary" onClick={() => setViewIsVisible(true)} style={{ marginBottom: 10, marginRight: 5 }}>
            VIEW
          </Button>
          {status !== 'CANCELED' && moment(departureDate).isAfter(moment()) ? (
            <Button onClick={() => setShareIsVisible(true)} style={{ marginRight: 5 }}>
              SHARE
            </Button> 
          ) : null}
        </div>
      </div>

      {!viewIsVisible || (
        <SharingRequestDetailsModal 
          request={request} 
          setVisible={setViewIsVisible}
        />
      )}
      {!shareIsVisible || (
        <SharingRequestShareModal 
          request={request} 
          setVisible={setShareIsVisible}
        />
      )}
    </div>
  );
}

export default ProfileSharingRequestCard