import React, { useState, useEffect } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import MainLayout from '../../layouts/MainLayout'
import Gallery from '../../common/Gallery'
import SharingBookingCard from '../../common/SharingBookingCard'
import MapContainer from '../../common/MapContainer'
import BoatOwnerDetailsCard from '../../common/BoatOwnerDetailsCard'
import BoatDetailsCard from '../../common/BoatDetailsCard'
import OperatorDetailCard from '../../common/OperatorDetailCard'
import Splash from '../Splash'
import FooterSmall from '../../common/FooterSmall'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import SharingService from '../../../services/SharingService'
import './style.sass'

const SharingRequestDetail = ({ match, history }) => {
	const sharingId = match.params ? match.params.sharingId : null

	const [loading, setLoading] = useState(true)
	const [sharingRequest, setSharingRequest] = useState(null)
	const { width } = useWindowDimensions()

	useEffect(() => {
		loadSharingRequest()
  }, [sharingId])

  const loadSharingRequest = () => {
		setLoading(true)
    SharingService.getSharingRequestById(sharingId).then((res) => {
			const fetchedSharingRequest = res.data.result
			setSharingRequest(fetchedSharingRequest)
			setLoading(false)			
    }).catch((err) => {
      console.log(err)
    });
	}

	return (
		<MainLayout>
			{loading || !sharingRequest ? (
				<Splash />
			) : (
				<div className="sharing-request-detail-component">
					<Gallery 
						images={sharingRequest.boatId.images}
					/>
					<Container>
						<Row>
							<Col 
								lg={{ span: 8, order: 'first' }}
								sm={{ span: 12, order: 'last' }} 
								xs={{ span: 12, order: 'last' }} 
							>
								{/* HEADING SECTION */}
								<h1>Trip with {sharingRequest.userId.firstName} {sharingRequest.userId.lastName} | <b>{sharingRequest.numberOfHours} Hours</b></h1>

								{/* DESCRIPTION SECTION */}
								<p className="description-text">
									{sharingRequest.customMessage}
								</p>

								{/* BOOKING CARD SECTION FOR MOBILE */}
								{ width < 992 ? <SharingBookingCard sharingRequest={sharingRequest} /> : null }

								{/* BOAT AND OWNER DETAILS SECTION */}
								<Row>
									<Col md={6}>
										<BoatDetailsCard 
											boat={sharingRequest.boatId}
										/>
									</Col>
									<Col md={6}>
										<OperatorDetailCard 
											operatorOption="With Captain"
										/>
									</Col>
								</Row>
								<br />
								<BoatOwnerDetailsCard 
									name={`${sharingRequest.userId.firstName} ${sharingRequest.userId.lastName}`}
									description={sharingRequest.userId.description}
									image={sharingRequest.userId.image}
								/>

								{/* LOCATION SECTION */}
								<h4 className="sub-heading">Approximate Location</h4>
								<p>You will get the exact location once the booking is confirmed</p>
								<div className="experience-detail-map-container">
									<MapContainer 
										style={{
											height: 300,
											width: "100%"
										}}
										circles={[{radius: 800, center : {lat: sharingRequest.location.lat, lng: sharingRequest.location.lng}}]}
										initialCenter={{lat: sharingRequest.location.lat, lng: sharingRequest.location.lng}}
									/>
								</div>
		
								{/* CANCELLATION POLICY SECTION */}
								<h4 className="sub-heading">Cancellation Policy</h4>
								<p className="description-text">Full refund up to <b>5 days </b>prior to depature date.</p>
							</Col>

							<Col 
								lg={{ span: 4,order: 'last' }}
								sm={{ span: 12, order: 'first' }} 
								xs={{ span: 12, order: 'first' }} 
							>
								{/* BOOKING CARD SECTION - NOTE THAT IS COMPONENT IS REPEATED TO CHANGE POSITION IN MOBILE*/}
								{ width > 992 ? <SharingBookingCard sharingRequest={sharingRequest} /> : null }
							</Col>
						</Row>
					</Container>
					<br />
					<br />
					<FooterSmall />
			</div>
			)}
		</MainLayout>
	);
}

export default SharingRequestDetail;
