import React from 'react'
import './style.sass'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const InputPhoneNumber = (props) => {
  const isPhoneNumberNotValid = props.value && props.value.slice(3, props.value.length).length !== 9;
  const countries = props.countries === 'all' ? null : ['AU', 'LK'];

  return ( 
    <div className="input-phone-number-component">
      <PhoneInput
        {...props}
        defaultCountry='LK'
        countries={countries}
        addInternationalOption={false}
        style={props.inputstyle}
      />
      {!isPhoneNumberNotValid || <p>Phone number should be 9 characters long!</p>}
    </div>
  );
}

export default InputPhoneNumber