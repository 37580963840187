import React from 'react'
import './style.sass'
import { CheckCircleTwoTone } from '@ant-design/icons';

const WizardCompletionCard = ({mainText, subText}) => {
  return ( 
    <div className="wizard-completion-card-component">
      <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 90 }}/>
      <h4>{mainText}</h4>
      <p>{subText}</p>
    </div>
  );
}

export default WizardCompletionCard