import React from 'react'
import './style.sass'
import flexible from '../../../assets/icons/operatorOptionIcons/flexible.png'
import withcaptain from '../../../assets/icons/operatorOptionIcons/with-captain.png'
import withOutCaptain from '../../../assets/icons/operatorOptionIcons/without-captain.png'

const OperatorDetailCard = ({operatorOption}) => {

  const getOperatorImage = () => {
    switch (operatorOption) {
      case 'Flexible':
        return flexible
      case 'With Captain':
        return withcaptain
      case 'Without Captain':
        return withOutCaptain
      default:
        return flexible // DEFAULT
    }
  }

  const getOperatorDescription = () => {
    switch (operatorOption) {
      case 'Flexible':
        return 'Available with both Captain & Without Captain'
      case 'With Captain':
        return 'Available with a captain'
      case 'Without Captain':
        return 'Available without a captain'
      default:
        return "Available with both Captain & Without Captain" // DEFAULT
    }
  }

  return ( 
    <div className="operator-details-card-component">
      <h4>Operator Details</h4>
      <hr/>
      <div className="flex-container">
        <div className="right">
          <img src={getOperatorImage()} alt="logo"/>
        </div>
        <div className="left">
          <h5>{operatorOption}</h5>
          <p>{getOperatorDescription()}</p>
        </div>
      </div>
    </div>
  );
}

export default OperatorDetailCard