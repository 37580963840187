import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { InputNumber } from 'antd'
import CustomButton from '../../../../common/CustomButton'


const SharingInstantBookingGroupForm = ({numberOfPeople, sharingRequest, onChange}) => {

	const onChangeHandler = (key, value, action) => {
		if ((numberOfPeople >= sharingRequest.seatsAvailable) && action === 'INCREASE') {
			return;
		} 

		if (key === "numberOfPeople" & value > sharingRequest.seatsAvailable) {
			onChange(key, sharingRequest.seatsAvailable)
			return;
		}

		onChange(key, value)		
	}

	return (
		<div className="sharing-instant-booking-group-form-component">
			<Container>
				<Row>
					<Col>
						<p><b>Select Number of seats:</b> Max {sharingRequest.seatsAvailable}</p>
						<p><b>Per Seat Cost:</b> ${sharingRequest.pricePerSeat}</p>

						<p>Persons</p>
						<div className='instant-booking-group-input-container'>
							<CustomButton
								text="-"
								onClick={() => onChangeHandler("numberOfPeople", numberOfPeople ? numberOfPeople - 1 : 0, 'REDUCE')}
							/>  
							<InputNumber 
								placeholder="Persons" 
								value={numberOfPeople}
								maxLength={4}
								min={0} 
							/>
							<CustomButton
								text="+"
								onClick={() => onChangeHandler("numberOfPeople", numberOfPeople ? numberOfPeople + 1 : 1, 'INCREASE')}
							/>  
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default SharingInstantBookingGroupForm;
