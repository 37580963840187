import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import { Rate } from 'antd';
import './style.sass'
import { getOverallRatingValue, getCriteriaAverageRating } from '../../../helpers/common';

const StarReviews = ({ reviews = [] }) => {
  return ( 
    <div className="star-reviews-component">
      <Container fluid={true}>
        <Row>
          <Col style={{ padding: 0 }}>
            <div className="star-review-item">
              <label className="main-rating-text">Total rating of {getOverallRatingValue(reviews)}/5 | {reviews.length} Reviews</label>
                <Rate 
                  style={{ color: '#fcc603' }}
                  value={getOverallRatingValue(reviews)}
                  allowHalf
                  disabled
                />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12} style={{ padding: 0 }}>
            <div className="star-review-item">
              <label>Value For Money :</label>
              <Rate 
                style={{ color: '#fcc603' }}
                value={getCriteriaAverageRating(reviews, 'valueForMoney')}
                allowHalf
                disabled
              />
            </div>
            <div className="star-review-item">
              <label>Boat Quality :</label>
              <Rate 
                style={{ color: '#fcc603' }}
                value={getCriteriaAverageRating(reviews, 'boatQuality')}
                allowHalf
                disabled
              />
            </div>
            <div className="star-review-item">
              <label>Friendliness :</label>
              <Rate 
                style={{ color: '#fcc603' }}
                value={getCriteriaAverageRating(reviews, 'friendliness')}
                allowHalf
                disabled
              />
            </div>
          </Col>
          <Col md={6} sm={12} style={{ padding: 0 }}>
          <div className="star-review-item">
              <label>Handling :</label>
              <Rate 
                style={{ color: '#fcc603' }}
                value={getCriteriaAverageRating(reviews, 'handling')}
                allowHalf
                disabled
              />
            </div>
            <div className="star-review-item">
              <label>Punctuality :</label>
              <Rate 
                style={{ color: '#fcc603' }}
                value={getCriteriaAverageRating(reviews, 'punctuality')}
                allowHalf
                disabled
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default StarReviews