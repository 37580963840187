import React from 'react'
import './style.sass'

const CustomButton = ({text, onClick, style, disabled, outlined}) => {
  return ( 
    <div className={outlined ? "custom-button-outlined-component" : "custom-button-component"}>
      <button 
        style={style} 
        className="button" 
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
}

export default CustomButton