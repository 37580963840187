import React, { Component } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Fade from 'react-reveal/Fade';
import sailor from '../../../../assets/images/sailor.jpg'
import CustomLinkButton from '../../../common/CustomLinkButton';
import './style.sass'


class HomeSection4 extends Component {
    render() {
        return (
            <div className="home-section-4-component">
                <Container>
                        <Row>
                            <Col className="left-col" lg={5}>
                                <Fade delay={100} duration={1000}>
                                    <label className="main-text">Are You a Boat Owner?</label>
                                    <label className="sub-text">List your boat onto The BoatersClub and Make the most out of it!</label>
                                    <CustomLinkButton
                                        text="Add My Boat"
                                        to="/boat/add"
                                    />
                                </Fade>
                            </Col>
                            <Col className="right-col" lg={7}>
                                <Fade delay={100} duration={1000}>
                                    <Row>
                                        <img className="image" src={sailor} alt="logo"/>
                                    </Row>
                                </Fade>
                            </Col>
                        </Row>
                </Container>
            </div>
        );
    }
}

export default HomeSection4;
