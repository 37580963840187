const ENV = "prod"

const API_URL_DEV = "http://localhost:4500"
const API_URL_PROD = "https://api.theboatersclub.com"

const PAY_PAL_CLIENT_ID_DEV = "AczEIgEk8OiMtVxRLAudJf_RMuaMyfKVaRlIX8Rlg-9SrDlKGKe9-82E49SpuBLEmwF_qeAL302Q41oJ" // Sandbox
const PAY_PAL_CLIENT_ID_PROD ="AUcwAQO1jTLD1_euuCzB2MrczrM0TwPlWJVPZDfCHlaCDacI_TojyT1rgbnm6olIBafZEZoVe6Ol2t0b" // LIVE

export const API_URL = ENV === 'prod' ? API_URL_PROD : API_URL_DEV
export const PAY_PAL_CLIENT_ID = ENV === 'prod' ? PAY_PAL_CLIENT_ID_PROD : PAY_PAL_CLIENT_ID_DEV
export const PAY_PAL_CURRENCY = 'USD'
export const GOOGLE_MAPS_API_KEY = "AIzaSyCYMV_vfA8o-6S_AI2fsmp0D070b8Nwy3Q"