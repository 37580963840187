import React, { useState } from 'react'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import './style.sass'
import { Modal, Button } from 'antd';
import { PUBLIC_S3_BUCKET_URL } from '../../../constants/AwsConstants'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const Gallery = ({images}) => {
  const { width } = useWindowDimensions();
  const [currentImageIndex, setCurrentImageIndex] = useState(null)

  const onImageClicked = (image) => {
    setCurrentImageIndex(image)
  }

  const onNextClicked = () => {
    if (currentImageIndex === images.length - 1) {
      setCurrentImageIndex(0)
    } else {
      setCurrentImageIndex(prevIndex => prevIndex + 1)
    }
  }

  const onBackClicked = () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(images.length - 1)
    } else {
      setCurrentImageIndex(prevIndex => prevIndex - 1)
    }
  }

  return ( 
    <div className="gallery-component" >
      <Carousel
        centered
        infinite
        slidesPerPage={2}
        offset={1}
        itemWidth={400}
      >
        {!images || images.map((image, index) => (
          <img 
            key={index + image}
            className="image" 
            src={PUBLIC_S3_BUCKET_URL + image} 
            onClick={width > 550 ? () => onImageClicked(index) : null}
            alt="img"
          />
        ))}
      </Carousel> 
      {(!!currentImageIndex || currentImageIndex === 0) ?  (
        <Modal
          visible={!!currentImageIndex || currentImageIndex === 0}
          footer={null}
          centered
          onCancel={() => setCurrentImageIndex(null)}
          width={"75%"}
          bodyStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Button onClick={onBackClicked} type='primary'>
            <LeftOutlined />
          </Button>
          <img style={{ width: '90%', margin: '0px 10px 0px 10px' }} src={PUBLIC_S3_BUCKET_URL + images[currentImageIndex]} alt="img"/>
          <Button onClick={onNextClicked} type='primary'>
            <RightOutlined />
          </Button>
        </Modal>
      ) : null}
    </div>
  );
}

export default Gallery