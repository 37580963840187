import {
  SET_RENTER_BOOKINGS,
  SET_OWNER_BOOKINGS,
  SET_RENTER_CUSTOM_BOOKING_REQUESTS,
  SET_OWNER_CUSTOM_BOOKING_REQUESTS,
  UPDATE_OWNER_CUSTOM_BOOKING_REQUEST,
  UPDATE_RENTER_CUSTOM_BOOKING_REQUEST
} from '../actions/ActionTypes';

const defaultState = {
  renterBookings: null,
  ownerBookings: null,
  renterCustomBookingRequests: null,
  ownerCustomBookingRequests: null,
};

const BookingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_RENTER_BOOKINGS:
      return {
        ...state,
        renterBookings: action.payload
      };
      case SET_OWNER_BOOKINGS:
        return {
          ...state,
          ownerBookings: action.payload
        };
      case SET_RENTER_CUSTOM_BOOKING_REQUESTS:
        return {
          ...state,
          renterCustomBookingRequests: action.payload
        };
      case SET_OWNER_CUSTOM_BOOKING_REQUESTS:
        return {
          ...state,
          ownerCustomBookingRequests: action.payload
        };
      case UPDATE_OWNER_CUSTOM_BOOKING_REQUEST:
        return {
          ...state,
          ownerCustomBookingRequests: state.ownerCustomBookingRequests ? [action.payload, ...state.ownerCustomBookingRequests.filter(item => item._id !== action.payload._id)] : [action.payload]
        };
      case UPDATE_RENTER_CUSTOM_BOOKING_REQUEST:
        return {
          ...state,
          renterCustomBookingRequests: state.renterCustomBookingRequests ? [action.payload, ...state.renterCustomBookingRequests.filter(item => item._id !== action.payload._id)] : [action.payload]
        };
    default:
      return state;
  }
};

export default BookingReducer;