import {
  SET_BOATS,
  ADD_BOAT,
  EDIT_BOAT
} from './ActionTypes';

export const setBoats = (payload) => ({  
  type: SET_BOATS,
  payload
});

export const addBoat = (payload) => ({  
  type: ADD_BOAT,
  payload
});

export const editBoat = (payload) => ({  
  type: EDIT_BOAT,
  payload
});

export default {
  setBoats,
  addBoat,
  editBoat
};