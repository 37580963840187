import React, { useState, useEffect } from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import WizardCard from '../../common/WizardCard'
import {useSelector} from 'react-redux'
import BookingService from '../../../services/BookingService'
import ExperienceService from '../../../services/ExperienceService'

// Forms
import CustomBookingDatesForm from './Forms/CustomBookingDatesForm'
import CustomBookingGroupForm from './Forms/CustomBookingGroupForm'
import CustomBookingDetailsForm from './Forms/CustomBookingDetailsForm'
import WizardCompletionCard from '../../common/WizardCompletionCard'

const CustomBookingWizard = ({ history }) => {
	const experienceId = history.location.state ?  history.location.state.experienceId : null

	const user = useSelector((state) => state.user.user);
	const [experience, setExperience] = useState(null)
	const [experienceBookedDates, setExperienceBookedDates] = useState(null)
	const [wizardIndex, setWizardIndex] = useState(0)
	const [processing, setProcessing] = useState(true)

	const [formData, setFormData] = useState({
		departureDate: null,
		departureTime: null,
		arrivalDate: null,
		arrivalTime: null,
		adults: 0,
		kids: 0,
		name: null,
		contactNumber: null,
		otherRequests: null,

		paid: null,
		type: null
	}) 

	useEffect(() => {
		if (experienceId){
			ExperienceService.getExperienceById(experienceId).then((experienceRes) => {
				const fetchedExperience = experienceRes.data.result
				setExperience(fetchedExperience)

				BookingService.getExperienceBookedDates(experienceId).then((datesRes) => {
					const fetchedExperienceBookedDates = datesRes.data.result
					setExperienceBookedDates(fetchedExperienceBookedDates)
					setProcessing(false)
				}).catch((err) => {
					console.log(err)
				});
			}).catch((err) => {
				console.log(err)
			});
		}
	}, [experienceId])

	const getRequestPayload = () => {
		const { kids, adults } = formData

		return {
			...formData,
			paid: false,
			type: 'CUSTOM',
			group: {
				kids,
				adults
			},
			experience
		}		
	}

	const onChangeHandler = (key, value) => {
		setFormData(formData => ({
			...formData,
			[key]: value
		}))
	}

	const onNextHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex + 1)
		}, 200);
	}

	const onBackHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex - 1)
		}, 200);
	}

	const onSubmitHandler = () => {
		setProcessing(true)
		const payload = getRequestPayload()

		BookingService.addBooking(payload).then((res) => {
			setWizardIndex(wizardIndex => wizardIndex + 1)
			setProcessing(false)
			
		}).catch((err) => {
			console.log(err)
			setProcessing(false)
		});
	}

	const onCompleteHandler = () => {
		history.push('/profile/renter/requests/booking')
	}

	const renderWizardCards = () => {
		const { 
			departureDate,
			departureTime,
			arrivalDate,
			arrivalTime,
			adults,
			kids,
			name,
			contactNumber,
			otherRequests,
		} = formData

		const mainText = "CUSTOMIZED BOOKING"
		const subText = experience.name

		switch (wizardIndex) {
			case 0:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 1 : Select Your Dates"
						form={<CustomBookingDatesForm 
							onChange={onChangeHandler} 
							dates={{ departureDate, departureTime, arrivalDate, arrivalTime }} 
							experience={experience} 
							experienceBookedDates={experienceBookedDates} 
						/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						buttonDisabled={!(departureDate && departureTime && arrivalDate && arrivalTime)}
						loading={processing}
					/>
				)	
			case 1:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 2 : Select Group"
						form={<CustomBookingGroupForm onChange={onChangeHandler} group={{adults, kids}} maxGroupSize={experience.groupSize}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(adults || kids)}
						loading={processing}
					/>
				)	
			case 2:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 3 : Booking Details"
						form={<CustomBookingDetailsForm onChange={onChangeHandler} details={{name, contactNumber, otherRequests}} />}
						buttonText="SUBMIT"
						onClick={onSubmitHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(name && contactNumber && contactNumber.length === 12)}
						loading={processing}
					/>
				)
			case 3:
				return (					
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Request Complete"
						form={(
							<WizardCompletionCard
								mainText="Request Submitted!"
								subText="Request is now processing. You view your booking requests in Your Profile Page!" 
							/>
						)}
						buttonText="GO TO MY PROFILE"
						onClick={onCompleteHandler}
					/>
				)	
			default:
				return null
		}
	}
	
	return (
		<MainLayout>
			<div className="custom-booking-wizard-component">
				<div className="center-container">
					{experience && user._id !== experience.userId._id && experience.customBookingsEnabled ? renderWizardCards() : null}
				</div>
			</div>
		</MainLayout>
	);
}

export default CustomBookingWizard;
