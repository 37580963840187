import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import logo from '../../../assets/logos/logoWhite.png'
import './style.sass'
import { Link } from 'react-router-dom'

const FooterSmall = () => {
  return ( 
    <div className="footer-small-component">
      <Container fluid={true}>
        <Row>
          <Col sm={4}>
            <div className="logo-container">
              <Link to="/">
                <img className="logo" src={logo} alt="logo"/>
              </Link>
            </div>
          </Col>
          <Col sm={8}>
            <p>PO Box 7053, Cranbourne VIC 3977, Australia | info@theboatersclub.com | Copyright 2020 | <Link to='/privacy' style={{ color: '#20b7da' }}>Privacy Policy</Link></p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FooterSmall