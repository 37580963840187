import {
  SET_OWNER_SHARING_REQUESTS, 
  UPDATE_OWNER_SHARING_REQUESTS,
} from './ActionTypes';

export const setOwnerSharingRequests = (payload) => ({  
  type: SET_OWNER_SHARING_REQUESTS,
  payload
});

export const updateOwnerSharingRequests = (payload) => ({  
  type: UPDATE_OWNER_SHARING_REQUESTS,
  payload
});

export default {
  setOwnerSharingRequests,
  updateOwnerSharingRequests
};