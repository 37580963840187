import React, { useState } from 'react'
import './style.sass'
import placeholder from '../../../assets/images/placeholder.png'
import { Button, Tag } from 'antd';
import { PUBLIC_S3_BUCKET_URL } from '../../../constants/AwsConstants'
import moment from 'moment'
import BookingDetailsModal from '../BookingDetailsModal'

const ProfileBookingsCard = ({booking, isOwnerBooking}) => {
  const { _id, departureDate, experience, sharingRequest, type } = booking;

  const [bookingDetailIsVisible, setBookingDetailIsVisible] = useState(false)
  const isComplete = moment(departureDate).add(type === "SHARING" ? sharingRequest.numberOfHours : experience.numberOfHours).isBefore(moment())

  const renderStatusTag = () => {
    if(isComplete) {
      return <Tag color="green">COMPLETED</Tag>
    }
    return null
  } 

  return ( 
    <div className="profile-cards-horizontal-component">
      {type === "SHARING" ? (
        <img src={sharingRequest.boatId.images && sharingRequest.boatId.images.length > 0 ? PUBLIC_S3_BUCKET_URL + sharingRequest.boatId.images[0] : placeholder} alt="logo" />
      ) : (
        <img src={experience.images && experience.images.length > 0 ? PUBLIC_S3_BUCKET_URL + experience.images[0] : placeholder} alt="logo" />
      )}
      <div className="right-container">
        <div className="content-container"> 
          <h3>Ref: #{_id.slice(0, 10)}  <Tag color="blue">{type}</Tag>{renderStatusTag()}</h3>

          {type === "SHARING" ? (
            <h4>Trip with {sharingRequest.userId.firstName} {sharingRequest.userId.lastName} | <b>{sharingRequest.numberOfHours} Hours</b></h4> 
          ) : (
            <h4>{experience.name.slice(0, 70).trim()}{experience.name.length < 70 || '...'} | <b>{experience.numberOfHours} Hours</b></h4>  
          )}

          <h5>{moment(departureDate).format('YYYY/MM/DD')}</h5>
        </div>
        <div className="button-container"> 
          {!isComplete || isOwnerBooking ? (
            <Button type="primary" onClick={() => setBookingDetailIsVisible(true)} style={{ marginBottom: 10 }}>
              VIEW
            </Button>
          ) : null}
        </div>
      </div>
      {!bookingDetailIsVisible || (
        <BookingDetailsModal 
          booking={booking} 
          visible={bookingDetailIsVisible} 
          setVisible={setBookingDetailIsVisible}
          isOwnerBooking={isOwnerBooking}
        />
      )}
    </div>
  );
}

export default ProfileBookingsCard