import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { Switch } from 'antd';

const ExperienceSettingsForm = ({customBookingsEnabled, onChange}) => {

	return (
		<div className="experience-settings-form-component">
			<Container>
				<Row>
					<Col>
						<h4>Enable Custom Booking?</h4>
						<p>With this feature, users can also request for custom bookings with their own criteria.</p>
						<Switch 
							onChange={(value) => onChange("customBookingsEnabled", value)} 
							checked={customBookingsEnabled} 
						/>
						<p className="switch-label-text">{customBookingsEnabled ? "Custom Bookings Enabled" : "Custom Bookings NOT Enabled"}</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default ExperienceSettingsForm;
