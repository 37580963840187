/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, createRef} from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { Input, InputNumber, DatePicker, Select, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment'
import { BOAT_BRANDS } from '../../../../../constants/BoatConstants'

const { Option } = Select;

const BoatDetailsForm = ({details, onChange}) => {
	const [newBrand, setNewBrand] = useState(null)
	const {name, registrationNumber, brand, model, yearOfManufacture, length, maxCapacity} = details
	const brandSelect = createRef()

	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	const disabledDate = (current) => {
		// Block Dates after current date and current date 
		if (current.isAfter(moment())) {
			return true;
		}
		// Return FLASE if any of the above conditions are not true
    return false;
	}

	const onAddNewBrandClicked = () => {
		if (newBrand) {
			onChange('brand', newBrand)
			setNewBrand(null)
			brandSelect.current.blur()
		}
	}

	return (
		<div className="boat-details-form-component">
			<Container>
				<Row>
					<Col>
						<Input 
							placeholder="Boat Name" 
							onChange={(e) => onChangeHandler("name", e.target.value)}
							value={name}
						/>
						<Input 
							placeholder="Registration Number" 
							onChange={(e) => onChangeHandler("registrationNumber", e.target.value)}
							value={registrationNumber}
						/>
						<Select 
							ref={brandSelect}
							placeholder="Brand"
							onChange={(val) => onChangeHandler("brand", val)} 
							value={brand ? brand : undefined}
							showSearch
							dropdownRender={menu => (
								<div>
									{menu}
									<Divider style={{ margin: '4px 0' }} />
									<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
										<Input 
											placeholder={"Add New Brand"}
											style={{ flex: 'auto' }} 
											value={newBrand} onChange={(e) => setNewBrand(e.target.value)} 
										/>
										<a
											style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
											onClick={onAddNewBrandClicked}
										>
											<PlusOutlined /> Add 
										</a>
									</div>
								</div>
							)}
						>
							{BOAT_BRANDS.map(brand => <Option key={brand.value} value={brand.displayValue}>{brand.displayValue}</Option>)}
						</Select>
						<Input 
							placeholder="Model" 
							onChange={(e) => onChangeHandler("model", e.target.value)} 
							value={model}
						/>
						<DatePicker 
							placeholder="Year of Manufacture (YYYY)" 
							onChange={(date, dateString) => onChangeHandler("yearOfManufacture", dateString)} 
							value={yearOfManufacture ? moment(yearOfManufacture) : null}
							disabledDate={disabledDate}
							picker="year" 
						/>
						<InputNumber 
							placeholder="Length (m)"
							onChange={(val) => onChangeHandler("length", val)} 
							value={length}
							maxLength={3}
						/>
						<InputNumber 
							placeholder="Max Capacity"
							onChange={(val) => onChangeHandler("maxCapacity", val)} 
							value={maxCapacity}
							maxLength={4}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default BoatDetailsForm;
