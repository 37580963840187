import React from 'react'
import {Card} from 'react-bootstrap'
import './style.sass'
import placeholder from '../../../assets/images/placeholder.png'
import { PUBLIC_S3_BUCKET_URL } from '../../../constants/AwsConstants'
import { Rate } from 'antd'
import { getOverallRatingValue } from '../../../helpers/common'
import { FieldTimeOutlined } from '@ant-design/icons'

const ExperienceCardSmall = ({experience, onClick}) => {
  const { name, price, numberOfHours, reviews, groupSize, images, address } = experience
  return ( 
    <div className="experience-card-small-component" onClick={onClick}> 
      <Card className="experience-card">
        <img className="icon" src={images ? PUBLIC_S3_BUCKET_URL + images[0] : placeholder} alt="logo"/>
        <div className="priceContainer">
          <p className="priceText"><b>Rs.{Math.ceil(price * 185)}</b></p>
        </div>
        {!reviews.length > 0 || (
          <div className="ratingContainer">
            <Rate 
              style={{ color: '#fcc603', fontSize: 15, marginTop: 5}} 
              disabled 
              value={getOverallRatingValue(reviews)}
            />
          </div>
        )}
        <Card.Body style={{ padding: 14 }}>
          <Card.Text className="main-text">
            {name.slice(0, 39).trim()}
            {name.length < 39 || '...'} | <b><FieldTimeOutlined /> {numberOfHours >= 1 ? `${numberOfHours} Hour(s)` : `${Math.floor(numberOfHours * 60)} Miniutes`}</b>
          </Card.Text>
          <Card.Text className="group-size-text"><b>{groupSize} {groupSize === 1 ? "Guest" : "Guests"}</b></Card.Text>
          <Card.Subtitle className="sub-text">{address}</Card.Subtitle>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ExperienceCardSmall