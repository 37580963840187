import React, { useState } from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import WizardCard from '../../common/WizardCard'
import { isOwnerAccountSetup } from '../../../helpers/common'
import OwnerAccountNotSetupCard from '../../common/OwnerAccountNotSetupCard'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import SharingService from '../../../services/SharingService'

// Forms
import SharingRequestDetailsForm from './Forms/SharingRequestDetailsForm'
import SharingRequestPriceForm from './Forms/SharingRequestPriceForm'
import SharingRequestMessageForm from './Forms/SharingRequestMessageForm'
import LocationForm from '../../common/LocationForm'
import WizardCompletionCard from '../../common/WizardCompletionCard'

const { confirm } = Modal;

const SharingRequestWizard = ({ history }) => {
	const user = useSelector((state) => state.user.user);

	const [wizardIndex, setWizardIndex] = useState(0)
	const [processing, setProcessing] = useState(false)
	const [formData, setFormData] = useState({
		boatId: null,
		boat: null, 
		departureDate: null,
		departureTime: null,
		numberOfHours: null,
		address: null,
		location: null,
		seatsAvailable: null, 
		pricePerSeat: null,
		customMessage: null
	}) 

	const onChangeHandler = (key, value) => {
		setFormData(formData => ({
			...formData,
			[key]: value
		}))
	}

	const onNextHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex + 1)
		}, 200);
	}

	const onBackHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex - 1)
		}, 200);
	}

	const getRequestPayload = async () => {
		return { 
			...formData
		}		
	}

	const onSubmitHandler = async () => {
		confirm({
			title: 'Do you want to submit the details?',
			icon: <ExclamationCircleOutlined />,
			centered: true,
			onOk : async (close) => {
				close()
				// =========================================================
				setProcessing(true)
				const payload = await getRequestPayload() 

				SharingService.addSharingBooking(payload).then((res) => {
					setProcessing(false)
					setWizardIndex(wizardIndex => wizardIndex + 1)
				}).catch((err) => {
					setProcessing(false)
				});
				// =========================================================
			}
		});
	}

	const onCompleteHandler = () => {
		history.push('/profile/owner/requests/sharing')
	}

	const renderWizardCards = () => {
		const { 
			boatId, 
			boat,
			departureDate,
			departureTime,
			numberOfHours,
			seatsAvailable, 
			pricePerSeat,
			customMessage,
			address,
			location,
		} = formData

		const mainText = "CREATE SHARING BOOKING"
		const subText = "Get the most out of your boat with sharing bookings!"

		switch (wizardIndex) {
			case 0:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 1 : Enter Your Sharing Booking Details"
						form={<SharingRequestDetailsForm onChange={onChangeHandler} details={{boatId, departureDate, departureTime, numberOfHours}} />}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						buttonDisabled={!(boatId && departureDate && departureTime && numberOfHours)}
						loading={processing}
					/>
				)				
			case 1:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 2 : Enter Pier Location"
						form={<LocationForm address={address} location={location} onChange={onChangeHandler}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(location && address)}
						loading={processing}
					/>
				) 	
			case 2:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}	
						stepText="Step 3 : Enter Boat Capacity & Budget"
						form={<SharingRequestPriceForm onChange={onChangeHandler} details={{seatsAvailable, pricePerSeat, boat}} />}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(seatsAvailable && pricePerSeat)}
						loading={processing}
					/>
				) 
			case 3:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}	
						stepText="Step 4 : Enter Custom Message"
						form={<SharingRequestMessageForm onChange={onChangeHandler} details={{customMessage}} />}
						buttonText="SUBMIT"
						onClick={onSubmitHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(customMessage)}
						loading={processing}
					/>
				) 
			case 4:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText={"Sharing Booking Added"}
						form={(
							<WizardCompletionCard
								mainText={"You are now Ready to Boat!"}
								subText={"Your sharing booking has now been added. Go to your profile to view it!"}
							/>
						)}
						buttonText="GO TO MY PROFILE"
						onClick={onCompleteHandler}
					/>
				)	
			default:
				return null
		}
	}
	
	return (
		<MainLayout>
			<div className="sharing-request-wizard-component">
				<div className="center-container">
					{isOwnerAccountSetup(user) ? renderWizardCards() : <OwnerAccountNotSetupCard />}
				</div>
			</div>
		</MainLayout>
	);
}

export default SharingRequestWizard;
