import ApiBuilder from './ApiBuilder';

/**
 *
 * POST : addExperience
 *
 */
const addExperience = (payload) => {
  return ApiBuilder.API.post('/experience/add', {
    ...payload,
  });
};

/**
 *
 * POST : editExperience
 *
 */
const editExperience = (experienceId, payload) => {
  return ApiBuilder.API.post('/experience/edit', {
    experienceId,
    updatedExperience: payload
  });
};

/**
 *
 * GET : getCreatedExperiences
 *
 */
const getCreatedExperiences = () => {
  return ApiBuilder.API.get('/experience/owner/created');
};

/**
 *
 * GET : getExperienceById
 *
 */
const getExperienceById = (experienceId) => {
  return ApiBuilder.API.get(`/experience/one/${experienceId}`);
};

/**
 *
 * GET : getExperiencesSearchResult
 *
 */
const getExperiencesSearchResult = (address, lat, lng) => {
  return ApiBuilder.API.get(`/experience/search/${address}/${lat}/${lng}`);
};

/**
 *
 * GET : getPromotedExperiences
 *
 */
const getPromotedExperiences = () => {
  return ApiBuilder.API.get(`/experience/promoted`);
};

export default {
  addExperience,
  editExperience,
  getCreatedExperiences,
  getExperiencesSearchResult,
  getExperienceById,
  getPromotedExperiences
};
