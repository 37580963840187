import React, { useState, useEffect } from 'react'
import { Input } from 'antd';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import MapContainer from '../MapContainer';
import { loadScript } from '../../../helpers/common'
import { GOOGLE_MAPS_API_KEY } from '../../../constants/ApiConstants';
import './style.sass';


const LocationForm = ({ address, location, onChange }) => {
  const [google, setGoogle] = useState(null)
  const initialCenter = location ? location :  {lat: -37.8342, lng: 144.9125}
  const markers = [{ name: 'Selected', ...initialCenter }]

  useEffect(() => {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, 'googleMapsScript', () => {
      const googleChecker = setInterval(() => {
        if(window.google){
          setGoogle(window.google)
          clearInterval(googleChecker)
        }
      }, 500);
    })

  }, [])

  const onMapPositionClicked = (t, map, c) => {
    const lat = c.latLng.lat();
    const lng = c.latLng.lng()

    onChange('location', { lat, lng })
    // Converts the lat lng into a address and sets it
    geocodeAndSetAddress({ lat, lng })
  }

  const onAddressChange = (value) => {
    onChange("address", value)
    // Converts the address into lat lng and sets location
    geocodeByAddressAndSetLocation(value)
  }

  // This method Converts the lat lng into a adress and sets it
  const geocodeAndSetAddress = (latlng) => {
    if (google){
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          onChange("address", results[0].formatted_address)
        }
      });
    }
  }
  
  // This method Converts the address into lat lng and sets location
  const geocodeByAddressAndSetLocation = (address) => {
    if (google){
      geocodeByAddress(address).then(res => getLatLng(res[0])).then(({ lat, lng }) => {
        onChange('location', { lat, lng })
      }).catch(() => {});
    }
  }
  
  return ( 
    <div className="location-form-component">
      <Input 
        placeholder="Address (Eg: Melbourne VIC, Australia)" 
        onChange={(e) => onAddressChange(e.target.value)}
        value={address}
      />
      <p className="label-text">Choose on Map</p>
      <div className="location-form-map" >
        <MapContainer 
          style={{ width: "100%", height: "250px", margin: 0 }} 
          markers={markers}
          onMapPositionClicked={onMapPositionClicked}
          initialCenter={initialCenter}
          zoom={12}
        />
      </div>
    </div>
  );
}

export default LocationForm