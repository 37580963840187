import React, { useState, useEffect } from 'react'
import './style.sass'
import MainLayout from '../../layouts/MainLayout'
import WizardCard from '../../common/WizardCard'
import { isOwnerAccountSetup, uploadToS3 } from '../../../helpers/common'
import OwnerAccountNotSetupCard from '../../common/OwnerAccountNotSetupCard'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ExperienceService from '../../../services/ExperienceService'
import ExperienceActions from '../../../redux/actions/ExperienceActions'
import moment from 'moment'

// Forms
import ExperienceDetailsForm from './Forms/ExperienceDetailsForm'
import OperatorOptionsForm from './Forms/OperatorOptionsForm'
import ExperiencePriceForm from './Forms/ExperiencePriceForm'
import ExperienceSettingsForm from './Forms/ExperienceSettingsForm'
import LocationForm from '../../common/LocationForm'
import FileUploadForm from '../../common/FileUploadForm'
import WizardCompletionCard from '../../common/WizardCompletionCard'

const { confirm } = Modal;

const AddExperienceWizard = ({ history }) => {

	const experience = history.location.state ?  history.location.state.experience : null // FOR EDIT MODE
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user);

	const [wizardIndex, setWizardIndex] = useState(0)
	const [processing, setProcessing] = useState(false)
	const [formData, setFormData] = useState({
		name: null,
		description: null,
		boatId: null,
		groupSize: null,
		sailingDays: null,
		departureTime: null,
		numberOfHours: null,
		operatorOption: null,
		price: null,
		customBookingsEnabled: null,
		address: null,
		location: null,
		images: null,
	}) 

	useEffect(() => {
		// FOR EDIT MODE
		setFormatedFromData()
	}, [])

	// FOR EDIT MODE
	const setFormatedFromData = () => {
		if(experience) {
			setFormData({
				...experience,
				images: null,
				price: experience.price * 185,
				departureTime: moment(experience.departureTime)
			})
		}
	}

	const onChangeHandler = (key, value) => {
		setFormData(formData => ({
			...formData,
			[key]: value
		}))
	}

	const onNextHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex + 1)
		}, 200);
	}

	const onBackHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex - 1)
		}, 200);
	}

	const getRequestPayload = async () => {
		const { images, price } = formData

		const imagesUploadResult = (experience && !images) ? experience.images : await uploadToS3(images, 'owner/experience/images/', 'image'); // NOTE (experience && !images) condition is to for checking EDIT mode and if images are available
		const imagesUrls = (experience && !images) ? imagesUploadResult : imagesUploadResult.map(item => item.key)

		return {
			...formData,
			price: price / 185,
			images: imagesUrls,
		}		
	}


	const onSubmitHandler = async () => {
		confirm({
			title: 'Do you want to submit the details?',
			icon: <ExclamationCircleOutlined />,
			centered: true,
			onOk : async (close) => {
				close()
				// =========================================================
				setProcessing(true)
				const payload = await getRequestPayload() 

				if (!experience) {
					ExperienceService.addExperience(payload).then((res) => {
						const fetchedExperience = res.data.result
						dispatch(ExperienceActions.addExperience(fetchedExperience))
						setProcessing(false)
						setWizardIndex(wizardIndex => wizardIndex + 1)
					}).catch((err) => {
						setProcessing(false)
					});
				} else {
					ExperienceService.editExperience(experience._id, payload).then((res) => {
						const fetchedExperience = res.data.result
						dispatch(ExperienceActions.editExperience(fetchedExperience))
						setProcessing(false)
						setWizardIndex(wizardIndex => wizardIndex + 1)
					}).catch((err) => {
						setProcessing(false)
					});
				}
				// =========================================================
			}
		});
	}

	const onCompleteHandler = () => {
		history.push('/profile/owner/experiences')
	}

	const renderWizardCards = () => {
		const { 
			name,
			description,
			boatId,
			groupSize,
			sailingDays,
			departureTime,
			numberOfHours,
			operatorOption,
			price,
			customBookingsEnabled,
			address,
			location,
			images,
		} = formData

		const mainText = !experience ? "CREATE EXPERIENCES" : "EDIT EXPERIENCES" 
		const subText = !experience ?  "Get the most out of your boat with amazing Experiences!" : "Please note that the changes will reflect in the publicly listed experience!"

		switch (wizardIndex) {
			case 0:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 1 : Enter Your Experience Details"
						form={<ExperienceDetailsForm onChange={onChangeHandler} details={{name, description, boatId, groupSize, sailingDays, departureTime, numberOfHours}} />}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						buttonDisabled={!(name && description && boatId && groupSize && sailingDays && departureTime && numberOfHours && sailingDays.length > 0)}
						loading={processing}
					/>
				)				
			case 1:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 2 : Enter Your Operator Information"
						form={<OperatorOptionsForm onChange={onChangeHandler} operatorOption={operatorOption}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!operatorOption}
						loading={processing}
					/>
				)	
			case 2:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}	
						stepText="Step 3 : Enter Your Price"
						form={<ExperiencePriceForm onChange={onChangeHandler} price={price}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(price)}
						loading={processing}
					/>
				) 
			case 3:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}	
						stepText="Step 4 : Experience Settings"
						form={<ExperienceSettingsForm onChange={onChangeHandler} customBookingsEnabled={customBookingsEnabled}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						loading={processing}
					/>
				) 
			case 4:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 5 : Enter Pier Location"
						form={<LocationForm address={address} location={location} onChange={onChangeHandler}/>}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(location && address)}
						loading={processing}
					/>
				) 
			case 5:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 6 : Enter Photos of Your Experience"
						form={<FileUploadForm 
							files={images}
							infoText="Tip : Make sure to have a good selected of high quality photos!"
							type='image'
							onChange={onChangeHandler}
							filesKey='images'
							multiple={true}
							isEditMode={experience ? true : null}
						/>}
						buttonDisabled={!images && !experience} 
						buttonText="SUBMIT"
						onClick={onSubmitHandler}
						onBackClick={onBackHandler}
						loading={processing}
					/>
				)			
			case 6:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText={experience ? "Experience Edited" : "Experience Added"}
						form={(
							<WizardCompletionCard
								mainText={experience ? "Successfully Edited Experience!" : "You are now Ready to Boat!"}
								subText={experience ? "Your experience has been edited successfully. Go to your profile to view it." : "Your Experience has now been added. Go to your profile to view it!"}
							/>
						)}
						buttonText="GO TO MY PROFILE"
						onClick={onCompleteHandler}
					/>
				)	
			default:
				return null
		}
	}
	
	return (
		<MainLayout>
			<div className="add-experience-wizard-component">
				<div className="center-container">
					{isOwnerAccountSetup(user) ? renderWizardCards() : <OwnerAccountNotSetupCard />}
				</div>
			</div>
		</MainLayout>
	);
}

export default AddExperienceWizard;
