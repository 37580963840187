import React from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { InputNumber, Input } from 'antd'
import CustomButton from '../../../../common/CustomButton'

const SharingRequestPriceForm = ({details, onChange}) => {

	const { boat, seatsAvailable, pricePerSeat } = details
	
	const onChangeHandler = (key, value, action) => {
		if ((seatsAvailable >= boat.maxCapacity) && action === 'INCREASE') {
			return;
		} 

		if (key === "seatsAvailable" & value > boat.maxCapacity) {
			onChange(key, boat.maxCapacity)
			return;
		}

		onChange(key, value)
	}

	return (
		<div className="sharing-request-price-form-component">
			<Container>
				<Row>
					<Col>
						<p><b>Max Capacity</b> of {boat.name} ({boat.category}) : <b>{boat.maxCapacity} People</b></p>
						<br />
						<p>Seats Available</p>
						<div className='sharing-request-seats-input-container'>
							<CustomButton
								text="-"
								onClick={() => onChangeHandler("seatsAvailable", seatsAvailable ? seatsAvailable - 1 : 0, 'REDUCE')}
							/>  
							<InputNumber 
								placeholder="Seats" 
								value={seatsAvailable}
								maxLength={4}
								onChange={(val) => onChangeHandler("seatsAvailable", val)} 
								min={0} 
							/>
							<CustomButton
								text="+"
								onClick={() => onChangeHandler("seatsAvailable", seatsAvailable ? seatsAvailable + 1 : 1, 'INCREASE')}
							/>  
						</div>
						<p>Price Per Seat</p>
						<InputNumber 
							onChange={(val) => onChangeHandler("pricePerSeat", val)} 
							value={pricePerSeat}
							maxLength={10}
							formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
							parser={value => value.replace(/\$\s?|(,*)/g, '')}
						/>
						<p>Note that "TheBoatersClub" will charge <b>10%</b> of your total price as the transaction + payment processing fee.</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default SharingRequestPriceForm;
