import React, { useEffect, useState } from 'react'
import './style.sass'
import {Menu} from 'antd';
import {Container, Row, Col} from 'react-bootstrap'
import { Route, Link, useLocation } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

// Components
import Boats from './Boats'
import Experiences from './Experiences'
import OwnerBookings from './OwnerBookings';
import OwnerCompletedBookings from './OwnerCompletedBookings';
import OwnerBookingRequests from './OwnerBookingRequests';
import OwnerSharingRequests from './OwnerSharingRequests';
import OwnerProfileSettings from './OwnerProfileSettings';

const {SubMenu} = Menu;

const OwnerSection = ({ match }) => {
  const location = useLocation()
  const menuItemStyle = {fontSize: 15}
  const menuSubItemStyle = {fontSize: 14}
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])

  useEffect(() => {
    setDefaultSelectedKeys([location.pathname])
  }, [location.pathname])

	return (
    <Fade delay={0} duration={1000}>
      <div className="owner-section-component">
        <Container>
          <Row>
            <Col md={3} className="left-col">
              <Menu
                mode="inline"
                selectedKeys={defaultSelectedKeys}
                defaultOpenKeys={["1"]}
                style={{ height: '100%', paddingTop: 10 }}
              >
                <Menu.Item key={`${match.url}/boats`} style={menuItemStyle} >
                  <Link to={`${match.url}/boats`}>BOATS</Link>
                </Menu.Item>
                <Menu.Item key={`${match.url}/experiences`} style={menuItemStyle}>
                  <Link to={`${match.url}/experiences`}>EXPERIENCES</Link>
                </Menu.Item>
                <Menu.Item key={`${match.url}/requests/sharing`} style={menuItemStyle}>
                  <Link to={`${match.url}/requests/sharing`}>SHARING BOOKINGS</Link>
                </Menu.Item>
                <SubMenu key="1" title="BOOKINGS" style={menuItemStyle}>
                  <Menu.Item key={`${match.url}/bookings`} style={menuSubItemStyle}>
                    <Link to={`${match.url}/bookings`}>CONFIRMED</Link>
                  </Menu.Item>
                  <Menu.Item key={`${match.url}/bookings/completed`} style={menuSubItemStyle}>
                    <Link to={`${match.url}/bookings/completed`}>COMPLETED</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key={`${match.url}/requests/booking`} style={menuItemStyle} >
                  <Link to={`${match.url}/requests/booking`}>BOOKING REQUESTS</Link>
                </Menu.Item>   
                <Menu.Item key={`${match.url}/settings`} style={menuItemStyle} >
                  <Link to={`${match.url}/settings`}>PROFILE SETTINGS</Link>
                </Menu.Item>
              </Menu>
            </Col>
            <Col md={9} className="right-col">
              {/* === Nested Routes === */}
              <Route
                exact
                path={`${match.url}/boats`}
                component={Boats}
              />
              <Route
                exact
                path={`${match.url}/experiences`}
                component={Experiences}
              />
              <Route
                exact
                path={`${match.url}/requests/sharing`}
                component={OwnerSharingRequests}
              />
              <Route
                exact
                path={`${match.url}/bookings`}
                component={OwnerBookings}
              />
              <Route
                exact
                path={`${match.url}/bookings/completed`}
                component={OwnerCompletedBookings}
              />
              <Route
                exact
                path={`${match.url}/requests/booking`}
                component={OwnerBookingRequests}
              />
              <Route
                exact
                path={`${match.url}/settings`}
                component={OwnerProfileSettings}
              />
              {/* ===================== */}
            </Col>
          </Row>
        </Container>
      </div>
    </Fade>
	);
}

export default OwnerSection;
