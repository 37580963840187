import React from 'react'
import './style.sass'
import CustomButton from '../CustomButton';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const BookingCard = ({experience}) => {
  const user = useSelector((state) => state.user.user);
  const history = useHistory()
  const { userId, price, numberOfHours, groupSize, sailingDays, verified, customBookingsEnabled } = experience

  const getSailingDaySelectedClass = (day) => {
    if (sailingDays.includes(day)) {
      return 'selected'
    }
    return null
  }

  return ( 
    <div className="booking-card-component">
      <div className="header">
        <p><span>Rs.{Math.ceil(price * 185)}</span> | For {numberOfHours >= 1 ? `${numberOfHours} Hour(s)` : `${Math.floor(numberOfHours * 60)} Miniutes`} </p>
      </div>
      <p><b>Group Size:</b> {groupSize} People</p>
      <p><b>Sailing Days</b></p>
      <div className="days-container">
        <span className={getSailingDaySelectedClass('Monday')}>M</span>
        <span className={getSailingDaySelectedClass('Tuesday')}>T</span>
        <span className={getSailingDaySelectedClass('Wednesday')}>W</span>
        <span className={getSailingDaySelectedClass('Thursday')}>T</span>
        <span className={getSailingDaySelectedClass('Friday')}>F</span>
        <span className={getSailingDaySelectedClass('Saturday')}>S</span>
        <span className={getSailingDaySelectedClass('Sunday')}>S</span>
      </div>

      {(user && (user._id === userId._id)) ? (
        <CustomButton 
          text="Manage"
          style={{ width: "80%", marginTop: 10 }}
          onClick={() => { history.push('/profile/owner/experiences') }}
        />
      ) : (
        verified ? (
          <div>
            <CustomButton 
              text="Instant Booking"
              style={{ width: "80%", marginTop: 10 }}
              onClick={() => { history.push('/experience/booking', { experienceId:  experience._id }) }}
            />
            {!customBookingsEnabled || (
              <CustomButton 
                text="Customized Booking"
                style={{ width: "80%", marginTop: 10 }}
                onClick={() => {history.push('/experience/booking/custom', { experienceId:  experience._id })}}
              />
            )}
          </div>
        ) : (
          <p className="not-verified-message">This experience is not yet verified by The Boaters Club!</p>
        )
      )}
    </div>
  );
}

export default BookingCard