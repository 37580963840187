import React, { useState, useEffect } from 'react'
import moment from 'moment'
import MainLayout from '../../layouts/MainLayout'
import SharingService from '../../../services/SharingService'
import WizardCard from '../../common/WizardCard'
import BookingService from '../../../services/BookingService'
import { useSelector } from 'react-redux'
import './style.sass'

// Forms
import SharingInstantBookingGroupForm from './Forms/SharingInstantBookingGroupForm'
import SharingInstantBookingDetailsForm from './Forms/SharingInstantBookingDetailsForm'
import PayPalPayment from '../../common/PayPalPayment'
import WizardCompletionCard from '../../common/WizardCompletionCard'

const SharingInstantBookingWizard = ({ history }) => {
	const sharingId = history.location.state ?  history.location.state.sharingId : null
	const user = useSelector((state) => state.user.user)

	const [wizardIndex, setWizardIndex] = useState(0)
	const [sharingRequest, setSharingRequest] = useState(null)
	const [processing, setProcessing] = useState(true)
	const [addedBooking, setAddedBooking] = useState(null)
	const [formData, setFormData] = useState({
		numberOfPeople: null,
		group: null,
		name: null,
		contactNumber: null,
		otherRequests: null,
		paid: false,
		type: 'SHARING',
	}) 

	useEffect(() => {
		if (sharingId) {
			setProcessing(true)
			SharingService.getSharingRequestById(sharingId).then((res) => {
				const fetchedSharingRequest = res.data.result
				setSharingRequest(fetchedSharingRequest)				
				setProcessing(false)
			}).catch((err) => {
				console.log(err)
			});
		}
	}, [sharingId])

	const getRequestPayload = () => {
		const { numberOfPeople } = formData

		return {
			...formData,
			group: {
				kids: 0,
				adults: numberOfPeople
			},
			departureDate: sharingRequest.departureDate,
			sharingRequest
		}			
	}

	const onChangeHandler = (key, value) => {
		setFormData(formData => ({
			...formData,
			[key]: value
		}))
	}

	const onNextHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex + 1)
		}, 200);
	}

	const onBackHandler = () => {
		setProcessing(true)
		setTimeout(() => {
			setProcessing(false)
			setWizardIndex(wizardIndex => wizardIndex - 1)
		}, 200);
	}

	const onSubmitHandler = () => { 
		setProcessing(true)
		const payload = getRequestPayload()
			
		BookingService.addBooking(payload).then((res) => {
			const fetchedBooking = res.data.result;
			setAddedBooking(fetchedBooking)
			setWizardIndex(wizardIndex => wizardIndex + 1)
			setProcessing(false)
		}).catch((err) => {
			console.log(err)
			setProcessing(false) 
		});
	}

	const onPaymentSuccess = (confirmedBooking) => {
		// confirmedBooking object could be used if needed
		setWizardIndex(wizardIndex => wizardIndex + 1)
  }

	const onCompleteHandler = () => {
		history.push('/profile/renter/bookings')
	}

	const renderWizardCards = () => {
		const { 
			numberOfPeople,
			name,
			contactNumber, 
			otherRequests
		} = formData

		const mainText = `Trip with ${sharingRequest.userId.firstName} ${sharingRequest.userId.lastName}`
		const subText = `${moment(sharingRequest.departureDate).format('YYYY-MM-DD')} | ${moment(sharingRequest.departureTime).format('hh:mm a')} | ${sharingRequest.numberOfHours} Hours`

		switch (wizardIndex) {
			case 0:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 1 : Select Number of Seats"
						form={<SharingInstantBookingGroupForm onChange={onChangeHandler} numberOfPeople={numberOfPeople} sharingRequest={sharingRequest} />}
						buttonText="NEXT STEP"
						onClick={onNextHandler}
						buttonDisabled={!(numberOfPeople)}
						loading={processing}
					/>
				)	
			case 1:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 2 : Booking Details"
						form={<SharingInstantBookingDetailsForm onChange={onChangeHandler} details={{name, contactNumber, otherRequests}} />}
						buttonText="NEXT STEP"
						onClick={onSubmitHandler}
						onBackClick={onBackHandler}
						buttonDisabled={!(name && contactNumber && contactNumber.length === 12)}
						loading={processing}
					/>
				)
			case 2:
				return (
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Step 3 : Make Payment to Complete Booking!"
						form={<PayPalPayment 
							bookingId={addedBooking ? addedBooking._id : null}
							amount={sharingRequest.pricePerSeat * numberOfPeople} 
							description={`${sharingRequest._id}-${user._id}`} 
							onSuccessCallback={onPaymentSuccess} 
						/>}
						loading={processing}
					/>
				)
			case 3:
				return (					
					<WizardCard 
						mainText={mainText}
						subText={subText}
						stepText="Booking Complete"
						form={(
							<WizardCompletionCard
								mainText="Booking Confirmed!"
								subText="Check your email for the booking confirmation. You can also view your bookings in Your Profile Page!" 
							/>
						)}
						buttonText="GO TO MY PROFILE"
						onClick={onCompleteHandler}
					/>
				)	
			default:
				return null
		}
	}
	
	return (
		<MainLayout>
			<div className="sharing-instant-booking-wizard-component">
				<div className="center-container">
					{sharingRequest && sharingRequest.seatsAvailable > 0 && user._id !== sharingRequest.userId._id ? renderWizardCards() : null}
				</div>
			</div>
		</MainLayout>
	);
}

export default SharingInstantBookingWizard;
