import React from 'react'
import './style.sass'
import { FacebookFilled } from '@ant-design/icons';

const FacebookButton = ({ url }) => (
  <div className="facebook-button-component  ">
    <a href={url} className="button">
      <FacebookFilled /><span className="button-label"> Sign in with Facebook</span>
    </a>
  </div>
)
export default FacebookButton;