import React, { useEffect, useState } from 'react'
import './style.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { InputNumber, TimePicker, Select, DatePicker} from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux';
import BoatService from '../../../../../services/BoatService';
import BoatActions from '../../../../../redux/actions/BoatActions';

const { Option } = Select;

const SharingRequestDetailsForm = ({details, onChange}) => {
	const [loadingBoats, setLoadingBoats] = useState(false)
	const boats = useSelector((state) => state.boat.boats);
	const dispatch = useDispatch();

	const {boatId, departureDate, departureTime, numberOfHours} = details

	useEffect(() => {
		loadBoats()
	}, [])

	const loadBoats = () => {
		if (!boats) {
			setLoadingBoats(true)
			BoatService.getBoats().then((res) => {
				const userBoats = res.data.result
				dispatch(BoatActions.setBoats(userBoats))
				setLoadingBoats(false)  
			}).catch((err) => {
				console.log(err)
			});
		}
	}
	
	const onChangeHandler = (key, value) => {
		onChange(key, value)
	}

	const disabledDate = (current) => {
		// Block Dates before current date and current date 
		if (current.isBefore(moment()) || current.isSame(moment())) {
			return true;
		}
		// Return FLASE if any of the above conditions are not true
    return false;
	}

	return (
		<div className="sharing-request-details-form-component">
			<Container>
				<Row>
					<Col>
						<Select 
							placeholder="Select Boat"
							onChange={(boatId) => {
								onChangeHandler("boatId", boatId)
								onChangeHandler("boat", boats.filter(b => b._id === boatId)[0])
							}} 
							loading={loadingBoats}
							disabled={loadingBoats || !boats}
							value={boatId ? boatId : undefined}
						>
							{!boats || boats.map(boat => <Option key={boat._id} value={boat._id}>{boat.name} - {boat.category}</Option>)}
						</Select>
						<DatePicker
							placeholder="Departure Date" 
							onChange={(date) => onChangeHandler("departureDate", date)}
							value={departureDate}
							showToday={false}
							disabledDate={disabledDate}
						/>
						<TimePicker 
							placeholder="Departure Time" 
							use12Hours 
							format="h:mm a" 
							onChange={(val) => onChangeHandler("departureTime", val)} 
							value={departureTime}
							showNow={false}
							minuteStep={10}
						/>
						<InputNumber 
							placeholder="Number of Hours"
							onChange={(val) => onChangeHandler("numberOfHours", val)} 
							value={numberOfHours}
							maxLength={3}
							min={0}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default SharingRequestDetailsForm;
