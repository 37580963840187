import React from 'react'
import './style.sass'
import Fade from 'react-reveal/Fade';
import { useSelector } from 'react-redux';
import CustomLinkButton from '../../../../common/CustomLinkButton';
import CustomButton from '../../../../common/CustomButton';
import { isOwnerAccountSetup } from '../../../../../helpers/common';
import { PUBLIC_S3_BUCKET_URL } from '../../../../../constants/AwsConstants';
import { DownloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const OwnerProfileSettings = () => {
  const user = useSelector((state) => state.user.user);
  const history = useHistory();

  return (
    <Fade delay={0} duration={1000}>
      <div className="owner-profile-settings-component">
        <div className="owner-profile-settings-header-container">
          <h3>Owner Profile Settings</h3>
          {isOwnerAccountSetup(user) ? (
            <CustomButton 
              text="Edit"
              onClick={()=>{ history.push('/owner/setup', { userFromNav: user })}}
            />
          ) : null}
        </div>
        <hr />
        {isOwnerAccountSetup(user) ? (
            <div className="owner-content-container">
              <h6>Full Name:</h6>
              <p>{user.firstName} {user.lastName}</p>
            
              <h6>Description:</h6>
              <p>{user.description}</p>

              <h6>Phone Number:</h6>
              <p>{user.phoneNumber}</p>

              <h6>Boating Licence</h6>
              {user.boatingLicence ? (
                <span >
                  <DownloadOutlined style={{ color: '#20b7da' }}/>
                  <a href={PUBLIC_S3_BUCKET_URL + user.boatingLicence[0]}> Download</a>
                </span>
              ) : <p>N/A</p>}
      
              <h6 style={{marginTop: 20}}>Bank Details:</h6>
              <p><span>Account Name:</span> {user.bankDetails.accountName}</p>
              <p><span>Account Number:</span> {user.bankDetails.accountNumber}</p>
              <p><span>Bank Name:</span> {user.bankDetails.bankName}</p>
              <p><span>Bank Branch:</span> {user.bankDetails.bankBranch}</p>
            </div>
          ) : (
            <div className="empty-message-container">
              <h4>Your Owner's Account needs to be configured!</h4>
              <CustomLinkButton
                text="Configure"
                to='/owner/setup'
              /> 
            </div>
          )}
      </div>
    </Fade>
  )
}

export default OwnerProfileSettings;
