import React, { useState, useEffect } from 'react'
import './style.sass'
import Fade from 'react-reveal/Fade';
import { useSelector, useDispatch } from 'react-redux';
import CustomLinkButton from '../../../../common/CustomLinkButton';
import ProfileSharingRequestCard from '../../../../common/ProfileCardsHorizontal/ProfileSharingRequestCard';
import { Spin } from 'antd';
import SharingActions from '../../../../../redux/actions//SharingActions'
import SharingService from '../../../../../services/SharingService';

const OwnerSharingRequests = () => {
  const [loading, setLoading] = useState(true)
  const ownerSharingRequests = useSelector((state) => state.sharing.ownerSharingRequests);
  const dispatch = useDispatch();

  useEffect(() => {
    loadOwnerSharingRequests()
  }, [])

  const loadOwnerSharingRequests = () => {
    setLoading(true)
    SharingService.getCreatedSharingsOfOwner().then((res) => {
      const fetchedSharingRequests = res.data.result
      dispatch(SharingActions.setOwnerSharingRequests(fetchedSharingRequests))
      setLoading(false)  
    }).catch((err) => {
      console.log(err)
    });
  }

  return (
    <Fade delay={0} duration={1000}>
      <div className="owner-sharing-requests-component">
        <div className="owner-sharing-requests-header-container">
          <h3>Sharing Bookings</h3>
          <CustomLinkButton
            text="Create Sharing Booking"
            to='/sharing/booking/add'
          /> 
        </div>
        <hr />
        {loading ? <Spin style={{padding: 10}} size='large' /> : (
          (ownerSharingRequests && ownerSharingRequests.length > 0) ? (
            <div>
              {ownerSharingRequests.map(request => (
                <ProfileSharingRequestCard 
                  key={request._id}
                  request={request}
                />
              ))}
            </div>
          ) : (
            <div className="empty-message-container">
              <h4>You have not created any sharing bookings yet!</h4>
            </div>
          )
        )}
      </div>
    </Fade>
  )
}

export default OwnerSharingRequests;
