import ApiBuilder from './ApiBuilder';

/**
 *
 * POST : addBooking
 *
 */
const addBooking = (payload) => {
  return ApiBuilder.API.post('/booking/add', {
    ...payload,
  });
};

/**
 *
 * GET : getExperienceBookedDates
 *
 */
const getExperienceBookedDates = (experienceId) => {
  return ApiBuilder.API.get(`/booking/dates/${experienceId}`);
};

/**
 *
 * GET : getRenterBookings
 *
 */
const getRenterBookings = () => {
  return ApiBuilder.API.get('/booking/renter');
};

/**
 *
 * GET : getOwnerBookings
 *
 */
const getOwnerBookings = () => {
  return ApiBuilder.API.get('/booking/owner');
};

/**
 *
 * GET : getRenterBookingRequests
 *
 */
const getRenterBookingRequests = () => {
  return ApiBuilder.API.get('/booking/renter/requests');
};

/**
 *
 * GET : getOwnerBookingRequests
 *
 */
const getOwnerBookingRequests = () => {
  return ApiBuilder.API.get('/booking/owner/requests');
};

/**
 *
 * POST : sendCustomBookingQuotation
 *
 */
const sendCustomBookingQuotation = (bookingRequestId, ownerQuotedPrice, ownerComments) => {
  return ApiBuilder.API.post('/booking/owner/requests/qoute', {
    bookingRequestId,
    ownerQuotedPrice,
    ownerComments
  });
};

/**
 *
 * POST : rejectBookingRequest
 *
 */
const rejectBookingRequest = (bookingRequestId, rejectedBy) => {
  return ApiBuilder.API.post('/booking/owner/requests/reject', {
    bookingRequestId,
    rejectedBy // OWNER or RENTER
  });
};

/**
 *
 * POST : capturePayment
 *
 */
const capturePayment = (orderId, bookingId) => {
  return ApiBuilder.API.post('/booking/update/payment/capture', {
    orderId,
    bookingId,
  });
};

export default {
  addBooking,
  getExperienceBookedDates,
  getRenterBookings,
  getOwnerBookings,
  getRenterBookingRequests,
  getOwnerBookingRequests,
  sendCustomBookingQuotation,
  rejectBookingRequest,
  capturePayment,
};
