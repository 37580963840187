import React from 'react'
import Fade from 'react-reveal/Fade';
import { Container, Row, Col} from 'react-bootstrap'
import CustomLinkButton from '../../../common/CustomLinkButton';
import './style.sass'
import { useSelector } from 'react-redux';

const HomeSection5 = () => {
    const user = useSelector((state) => state.user.user);
    return (
        <div className="home-section-5-component">
            <div className="imageContainer">
                <Container>
                    <Row>
                        <Col>
                            <Fade delay={100} duration={1000}>
                                <h1>The Best App for Boaters Worldwide!</h1>
                            </Fade>
                            {!user ? (
                                <Fade delay={100} duration={1000}>
                                    <CustomLinkButton
                                        text="Join Today!"
                                        to="/signin"
                                    />
                                </Fade>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default HomeSection5;
