import ApiBuilder from './ApiBuilder';

/**
 *
 * POST : addReview
 *
 */
const addReview  = (experienceId, payload) => {
  return ApiBuilder.API.post('/review/add', {
    experienceId,
    ...payload,
  });
};

/**
 *
 * GET : getExperienceReviews
 *
 */
const getExperienceReviews = (experienceId) => {
  return ApiBuilder.API.get(`/review/experience/all/${experienceId}`);
};

export default {
  addReview,
  getExperienceReviews,
};
