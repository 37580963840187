import React, { Component } from 'react'
import Fade from 'react-reveal/Fade';
import { Container, Row, Col} from 'react-bootstrap'

import fish from '../../../../assets/icons/categoryIcons/fish.png'
import jetski from '../../../../assets/icons/categoryIcons/jetski.png'
import paddle from '../../../../assets/icons/categoryIcons/paddle.png'
import saling from '../../../../assets/icons/categoryIcons/saling.png'
import scuba from '../../../../assets/icons/categoryIcons/scuba.png'
import yatch from '../../../../assets/icons/categoryIcons/yatch.png'
import watersports from '../../../../assets/icons/categoryIcons/watersports.png'
import house from '../../../../assets/icons/categoryIcons/house.png'
import ferry from '../../../../assets/icons/categoryIcons/ferry.png'

import './style.sass'

class HomeSection2 extends Component {
    render() {
        return (
            <div className="home-section-2-component">
                <Container>
                    <Fade delay={100} duration={1000}>
                        <Row className="row">
                            <Col>
                                <h1>Any Type of Boating Experience for Anyone!</h1>
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col md={1}></Col>
                            <Col md={2}>
                                <img className="icon" src={fish} alt="logo"/>
                                <p className="label">Fishing</p>
                            </Col>
                            <Col md={2}>
                                <img className="icon" src={jetski} alt="logo"/>
                                <p className="label">Jet Ski</p>
                            </Col>
                            <Col md={2}>
                                <img className="icon" src={paddle} alt="logo"/>
                                <p className="label">Paddle Boats</p>
                            </Col>
                            <Col md={2}>
                                <img className="icon" src={saling} alt="logo"/>
                                <p className="label">Sailing</p>
                            </Col>
                            <Col md={2}>
                                <img className="icon" src={ferry} alt="logo"/>
                                <p className="label">Ferry</p>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row className="row">
                            <Col md={2}></Col>
                            <Col md={2}>
                                <img className="icon" src={scuba} alt="logo"/>
                                <p className="label">Scuba Diving</p>
                            </Col>
                            <Col md={2}>
                                <img className="icon" src={yatch} alt="logo"/>
                                <p className="label">Yacht</p>
                            </Col>
                            <Col md={2}>
                                <img className="icon" src={watersports} alt="logo"/>
                                <p className="label">Water Sports</p>
                            </Col>
                            <Col md={2}>
                                <img className="icon" src={house} alt="logo"/>
                                <p className="label">House Boats</p>
                            </Col>
                            <Col md={2}></Col>
                        </Row>
                    </Fade>
                </Container>
            </div>
        );
    }
}

export default HomeSection2;
