import {
  SET_EXPERIENCE,
  ADD_EXPERIENCE,
  EDIT_EXPERIENCE
} from '../actions/ActionTypes';

const defaultState = {
  experiences: null
};

const ExperienceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_EXPERIENCE:
      return {
        ...state,
        experiences: action.payload
      };
      case ADD_EXPERIENCE:
        return {
          ...state,
          experiences: state.experiences ? [...state.experiences, action.payload] : [action.payload]
        };
      case EDIT_EXPERIENCE:
        return {
          ...state,
          experiences: state.experiences ? [...state.experiences.filter(e => e._id !== action.payload._id), action.payload] : [action.payload]
        };
    default:
      return state;
  }
};

export default ExperienceReducer;