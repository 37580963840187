import React from 'react'
import './style.sass'
import ReactDOM from "react-dom"
import { PAY_PAL_CLIENT_ID, PAY_PAL_CURRENCY } from '../../../constants/ApiConstants'
import { Spin } from 'antd';
import { loadScript } from '../../../helpers/common';
import BookingService from '../../../services/BookingService'

let PayPalButton = null;

/**
 * 
 * @prop bookingId
 * @prop amount
 * @prop description
 * @prop onSuccessCallback
 *
 */
class PayPalPayment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isScriptLoaded: false,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    loadScript(`https://www.paypal.com/sdk/js?client-id=${PAY_PAL_CLIENT_ID}&currency=${PAY_PAL_CURRENCY}`, 'paypalScript', () => {
      this.setState({ isScriptLoaded: true })

      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false });
    })
  }

  createOrder = (data, actions) => {
    const { bookingId, amount, description } = this.props

    if(!bookingId) return null // NOTE: Preventing the payment if booking id is missing
    
    return actions.order.create({
      purchase_units: [
        {
          description: description,
          amount: {
            value: Math.round(amount * 100) / 100 // Amount rounded to 2dp
          }
        }
      ]
    });
  };

  onApprove = (data, actions) => {
    const {bookingId, onSuccessCallback} = this.props
    const orderId = data.orderID

    this.setState({ loading: true });
    BookingService.capturePayment(orderId, bookingId).then((res) => {
      const confirmedBooking = res.data.result
      onSuccessCallback(confirmedBooking)
    }).catch((err) => {
      console.log(err)
    });

    // Note: This can be used to capture the payment from the frontend
    // actions.order.capture().then(details => {
    //   console.log("details===>", details)
    // });
  };

  render() {
    const {loading} = this.state;
    const {amount} = this.props

    return (
      <div className="paypal-payment-component">
        {loading ? (
          <Spin size='large' />
        ) : (
          <div>
            <div>
              <h5 className="amount-text"><b>Payment Amount</b></h5>
              <h3 className="amount-text">Rs.{Math.ceil(amount * 185)}  |  ${Math.round(amount * 100) / 100}</h3>
            </div>
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}
      </div>
    );
  }
}

 export default PayPalPayment;