import React, { useState, useEffect } from 'react'
import './style.sass'
import { Modal, Tag, Button, Input, InputNumber } from 'antd';
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import PayPalPayment from '../PayPalPayment'
import BookingService from '../../../services/BookingService';
import BookingActions from '../../../redux/actions/BookingActions';
import WizardCompletionCard from '../WizardCompletionCard'
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal;

const style = {
  col: {
    textAlign: 'center',
    padding: 10,
  },
  p: {
    fontSize: 18
  },
  label: {
    fontSize: 20,
    marginBottom: 0,
    fontWeight: 400,
    marginTop: 20,
  },
  labelWarning: {
    fontSize: 15,
    fontWeight: 500,
    color: '#db2222'
  },
  h3: {
    fontWeight: 400,
  },
}

const BookingRequestDetailsModal = ({ bookingRequest, visible, setVisible, isOwnerBookingRequest }) => {
  const { 
    _id,
    name, 
    departureDate, 
    departureTime, 
    arrivalDate, 
    arrivalTime, 
    group, 
    otherRequests, 
    status,
    ownerQuotedPrice,
    ownerComments,
    experience 
  } = bookingRequest
  const history = useHistory()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions();
  const [formData, setFormData] = useState({
    quotedPrice: null,
    comments: null
  })
  
  const user = useSelector((state) => state.user.user);

  const [processing, setProcessing] = useState(false)
  const [processingReject, setProcessingReject] = useState(false)
  const [isPaymentMode, setIsPaymentMode] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false)
  const [experienceBookedDates, setExperienceBookedDates] = useState(null)

  useEffect(() => {
    BookingService.getExperienceBookedDates(experience._id).then((datesRes) => {
      const fetchedExperienceBookedDates = datesRes.data.result
      setExperienceBookedDates(fetchedExperienceBookedDates)
    }).catch((err) => {
      console.log(err)
    });
  }, [])

  // This fuction checks if Date Range of a custom booking is conflicting with confirmed instant bookings!
  const isDateRangeConflicting = () => {
    let isConflicting = false
    const confirmedBookedDays = []

		experienceBookedDates.forEach(item => {
			if (item.status === "CONFIRMED") {
				const startDateTime = moment(item.departureDate) 
				const endDateTime = moment(startDateTime).add(24, 'h')

				while (startDateTime.isSameOrBefore(endDateTime)) {
					confirmedBookedDays.push(startDateTime.format('YYYY MM DD'))
					startDateTime.add(1, 'days');
				}
			}
    })	
    confirmedBookedDays.forEach(confirmedBookedDay => {
      if (moment(confirmedBookedDay).isBetween(departureDate, arrivalDate)) {
        isConflicting = true
      }
    });
    return isConflicting;
  }

  const onChangeHandler = (key, value) => {
		setFormData(formData => ({
			...formData,
			[key]: value
		}))
  }
  
  const getModalWidth = () => {
    if (width < 700) return '100%';
    if (width < 1000) return '70%';;
    if (width < 1350) return '60%';;
    if (width >= 1350) return '40%';;
  }

  const renderStatusTag = () => {
    if (status === "PENDING") {
      return <Tag color="orange">PENDING</Tag>
    }

    if (status === "REJECTED") {
      return <Tag color="red">REJECTED</Tag>
    }

    if (status === "APPROVED") {
      if(!isOwnerBookingRequest){
        return <Tag color="green">APPROVED</Tag>
      }
      return <Tag color="blue">QUOTED</Tag>
    }
    return null
  } 
  
  const getNumberOfHours = () => {
    const dDate = moment(departureDate).format('YYYY-MM-DD')
    const dTime = moment(departureTime).format('HH:mm')

    const aDate = moment(arrivalDate).format('YYYY-MM-DD')
    const aTime = moment(arrivalTime).format('HH:mm')
  
    return moment(aDate + ' ' + aTime).diff(moment(dDate + ' ' + dTime), 'hours')
  }

  const onSendQuotationClicked = () => {
    const { quotedPrice, comments } = formData

    confirm({
			title: 'Are you sure you want to send quotation?',
			icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: 'Yes',
			onOk : async (close) => {
				close()
				// =========================================================
        setProcessing(true)
        BookingService.sendCustomBookingQuotation(_id, quotedPrice, comments).then((res) => {
          const updatedBookingRequest = res.data.result;
          dispatch(BookingActions.updateOwnerCustomBookingRequest(updatedBookingRequest))
          setFormData({ quotedPrice: null, comments: null})
          setProcessing(false)
          setVisible(false)
        }).catch((err) => {
          console.log(err)
        });
				// =========================================================
			}
    });
  }

  const onRejectClicked = (rejectedBy) => {
    confirm({
			title: 'Are you sure you want to reject this custom booking?',
			icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: 'Yes',
			onOk : async (close) => {
				close()
				// =========================================================
        setProcessingReject(true)
        BookingService.rejectBookingRequest(_id, rejectedBy).then((res) => {
          const updatedBookingRequest = res.data.result;

          if (rejectedBy == "OWNER") {
            dispatch(BookingActions.updateOwnerCustomBookingRequest(updatedBookingRequest))
          } else if (rejectedBy == "RENTER") {
            dispatch(BookingActions.updateRenterCustomBookingRequest(updatedBookingRequest))
          }

          setFormData({ quotedPrice: null, comments: null})
          setProcessingReject(false)
          setVisible(false)
        }).catch((err) => {
          console.log(err)
        });
				// =========================================================
			}
    });
  }

  const onPayAndConfirmClicked = () => {
    setIsPaymentMode(true)
  }

  const onPaymentSuccess = () => { 
    setTimeout(() => { setIsPaymentDone(true) }, 500);
  }
  
  return (
    <div className="booking-requests-details-modal-component" >
      <Modal
        visible={visible}
        footer={null}
        centered
        width={getModalWidth()}
        closable={false}
        onCancel={(processing || isPaymentDone || isPaymentMode) ? () => {} : () => setVisible(false)}
      >
      {!bookingRequest || (
          <div>
            <Container fluid={true}>
              <Row>
                <Col style={style.col}>
                  <h5 style={style.h5}>
                    <b>Ref:</b> #{_id.slice(0, 10)} {renderStatusTag()}
                  </h5>
                  <h3 style={style.h3}>{moment(departureDate).format('YYYY/MM/DD')}</h3>
                  <h4 style={style.h4}><b>CUSTOM</b> : {experience.name}, <b>{getNumberOfHours()} Hours</b></h4> 
                  {!isPaymentMode ? (
                    <Button
                      type='link'
                      onClick={() => { history.push(`/experience/detail/${experience._id}`)}}
                      style={style.btn}
                    >
                      {'View Listing  >'}
                    </Button>
                  ) : null}
                </Col>
              </Row>

              {(status === "APPROVED" && isPaymentMode)? (
                <div style={{ maxWidth: 400, textAlign: 'center', margin: 'auto' }}>
                  {!isPaymentDone ? (
                    <div>
                      <PayPalPayment 
                        bookingId={_id}
                        amount={ownerQuotedPrice}
                        description={`${experience._id}-${user._id}`} 
                        onSuccessCallback={onPaymentSuccess}
                      />
                      <Button onClick={() => setVisible(false)}>Cancel</Button>
                    </div>
                  ): (
                    <div>
                      <WizardCompletionCard
                        mainText="Booking Confirmed!"
                        subText="Check your email for the booking confirmation. You can also view your bookings in Your Profile Page!" 
                      />
                      <Button 
                        type="primary" 
                        size="large" 
                        onClick={() => { history.push(`/profile/renter/bookings`)}}
                      >
                        GO TO BOOKINGS
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <Row>
                  <Col>
                    <p style={style.p}>
                      <b>Departure Date:</b> {moment(departureDate).format('YYYY/MM/DD')}<b> | Departure Time:</b> {moment(departureTime).format('h:mm a')}
                    </p>
                    <p style={style.p}>
                      <b>Arrival Date:</b> {moment(arrivalDate).format('YYYY/MM/DD')}<b> | Arrival Time:</b> {moment(arrivalTime).format('h:mm a')}
                    </p>
                    <p style={style.p}><b>Group:</b> Adults - {group.adults}, Kids - {group.kids}</p>
                    <p style={style.p}><b>{isOwnerBookingRequest ? 'Renter' : 'Booking' } Name: </b> {name}</p>
                    <p style={style.p}><b>Special Requests: </b> {otherRequests ? otherRequests : 'N/A'}</p>

                    {status === "APPROVED" ? (  
                      <div>
                        <p style={style.p}><b>Quoted Price by Owner:</b> ${ownerQuotedPrice}</p>
                        <p style={style.p}><b>Owners Comments:</b> {ownerComments ? ownerComments : 'N/A'}</p>
                        {!isOwnerBookingRequest ? (
                          <div>
                            <Button type="primary" size="large" onClick={onPayAndConfirmClicked} style={{ marginBottom: 10 }}>
                              PAY AND CONFIRM
                            </Button>
                            <Button 
                              type="danger" 
                              size="large" 
                              onClick={() => onRejectClicked("RENTER")} 
                              style={{ marginLeft: 10 }}
                              loading={processingReject}
                            >
                              REJECT
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {status === "PENDING" && isOwnerBookingRequest ? (  
                      <div className="price-qoute-form">
                        {experienceBookedDates && isDateRangeConflicting() ? (
                          <p style={style.labelWarning}><ExclamationCircleOutlined /> The date range of this CUSTOM booking conflicts with 1 or more CONFIRMED bookings. Please check your confirmed bookings before quoting a price!</p>
                        ) : null}
                        <p style={style.label}>Enter Quoted Price</p>
                        <InputNumber 
                          placeholder="Quoted Price" 
                          onChange={(val) => onChangeHandler("quotedPrice", val)}
                          value={formData.quotedPrice}
                          maxLength={10}
                          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          style={{ width: "100%", padding: 10, paddingLeft: 0, fontSize: 16 }}
                          disabled={processing}
                        />
                        <p style={style.label}>Enter Comments</p>
                        <Input.TextArea
                          autoSize={{ minRows: 3, maxRows: 4 }}
                          placeholder="Comments"
                          onChange={(e) => onChangeHandler("comments", e.target.value)} 
                          value={formData.comments}
                          style={{ fontSize: 16 }}
                          disabled={processing}
                        />
                        <Button 
                          type="primary" 
                          size="large" 
                          onClick={onSendQuotationClicked} 
                          style={{ marginTop: 20 }}
                          disabled={!formData.quotedPrice || processingReject}
                          loading={processing}
                        >
                          SEND QUOTATION
                        </Button>
                        <Button 
                          type="danger" 
                          size="large" 
                          onClick={() => onRejectClicked("OWNER")} 
                          style={{ marginLeft: 10 }}
                          loading={processingReject}
                        >
                          REJECT
                        </Button>
                      </div>
                    ) : null}

                  </Col>
                </Row>
              )}
            </Container>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default BookingRequestDetailsModal